import { decodeHTML } from 'entities';
export function decodeEntities(encodedString) {
  /* converts encoded HTML Entities into unicode string */
  var translateRegex = /&(nbsp|amp|quot|lt|gt|reg|trade|copy|mdash|apos|ldquo|rdquo|eacute);/g;
  var htmlSymbolRegex = /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/g;
  var translateMap = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
    reg: '®',
    trade: '™',
    copy: '©',
    mdash: '-',
    apos: "'",
    ldquo: '"',
    rdquo: '"',
    eacute: 'é'
  };
  var numericalEntitiesRegex = /&#(x?[0-9a-f]+);/gi; // matches digits and HEX-based entities

  return encodedString.replace(translateRegex, function (match, entity) {
    return translateMap[entity];
  }).replace(numericalEntitiesRegex, function (match, entity) {
    return String.fromCharCode(parseInt("0".concat(entity)));
  }).replace(htmlSymbolRegex, '');
}
export function stripAwayTags(potentialHtml) {
  /* strips out HTML tags out of text */
  return potentialHtml.replace(/<[^>]*>/g, '');
}
export function decodePageTitle(encodedString) {
  return stripAwayTags(decodeHTML(encodedString)).trim();
}
export default function decodeTitle(encodedString) {
  return stripAwayTags(decodeEntities(encodedString)).trim();
}