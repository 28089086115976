import * as ApolloReactHooks from '@apollo/react-hooks';
import { AddUserActivityDocument } from '@src/mutations/AddUserActivityMutation.generated';
import { UserActivityDocument } from '@src/queries/UserActivityQuery.generated';
export var isFeatureType = function isFeatureType(k) {
  return function (t) {
    return t.featureType === k;
  };
};
export function useTypedUserActivityQuery(options) {
  return ApolloReactHooks.useQuery(UserActivityDocument, options);
}
export function useTypedAddUserActivityMutation(options) {
  return ApolloReactHooks.useMutation(AddUserActivityDocument, options);
}