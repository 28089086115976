import _defineProperty from "/builds/PDteTNAh/3/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ApolloClient, InMemoryCache, IntrospectionFragmentMatcher, from, defaultDataIdFromObject } from 'apollo-boost';
import { AppCookies } from '@src/utils/cookies';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { pickBy } from 'lodash';
import getConfig from 'next/config';
import { logErrorsInDev, hasSessionExpired, hasRememberMeSessionExpired } from '@utils/errorHandler';
import introspectionResult from '@src/types/introspection-result';
import { createUploadLink } from 'apollo-upload-client';
import createGraphQLOperationName from '@src/utils/graphQLOperationNameMapper';
import { Router, loginRoute, homeRoute } from '@src/routes';
import { getLocalizedUrl } from '@utils/regional';
import { userSession } from '@src/utils/userSession';
import { isServer } from '@src/utils/isServer';
export var createApolloClient = function createApolloClient(cookies, ctx) {
  var initialState = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var cache = new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: introspectionResult
    }),
    // Changes unique identifier. https://github.com/apollographql/apollo-client/tree/master/packages/apollo-cache-inmemory#normalization
    dataIdFromObject: function dataIdFromObject(object) {
      switch (object.__typename) {
        case 'Substance':
          return object._id;

        case 'Cart':
          /**
           * Use the cart alias (i.e. 'active' or 'savedcart') to identify the
           * cart object. If the active cart ID changes (e.g. due to a delete
           * cart mutation), we want to make sure that future cart updates are
           * still used to update the old active cart object.
           */
          var cartType = object.cartType || 'SIAL';
          return "".concat(object.cartIdAlias, "-").concat(cartType) || object.id;

        case 'ProductComponent':
          {
            return object.value;
          }

        case 'Material':
          {
            /**
             * Custom Oligo products have identical IDs and numbers so name is
             * used in conjunction with ID to provide a unique identifier.
             */
            return object.name ? "".concat(object.id, "-").concat(object.name) : object.id;
          }

        case 'ProductNumberSuggestions':
          {
            /**
             * Prevent Barcode results cache replacing product id of current page if on a product.
             */
            return "".concat(object.id, "-barcode");
          }

        case 'SharedListMember':
          return defaultDataIdFromObject(object);

        case 'Product':
          var product = object;
          return product.gaProductCode ? "".concat(product.id, "-").concat(product.gaProductCode) : product.id;

        case 'UserActivityEntry':
          var entry = object;
          return entry.featureType;

        default:
          return object.id;
      }
    }
  }).restore(initialState);
  var link = from([createHeadersLink(cookies), createErrorLink(cookies, cache, ctx), createHttpLink()]);
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: link,
    cache: cache
  });
};

function createHttpLink() {
  var _getConfig = getConfig(),
      _getConfig$publicRunt = _getConfig.publicRuntimeConfig,
      graphQlApiClientUrl = _getConfig$publicRunt.graphQlApiClientUrl,
      graphQlApiServerUrl = _getConfig$publicRunt.graphQlApiServerUrl;

  var uri;
  var fetchOptions;

  if (!isServer()) {
    uri = graphQlApiClientUrl;
  } else {
    uri = graphQlApiServerUrl; // We need to dynamically require 'url' and 'https' here since it can only be
    // included with the server build
    // eslint-disable-next-line @typescript-eslint/no-var-requires

    var url = require('url');

    if (url.parse(uri).protocol === 'https:') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      var https = require('https');

      fetchOptions = {
        agent: new https.Agent({
          rejectUnauthorized: false
        })
      };
    }
  }

  var customFetch = function customFetch(uri, options) {
    if (typeof options.body === 'string') {
      var _JSON$parse = JSON.parse(options.body),
          operationName = _JSON$parse.operationName;

      return fetch("".concat(uri, "?operation=").concat(operationName), options);
    }

    return fetch(uri, options);
  };

  return new createUploadLink({
    uri: uri,
    fetchOptions: fetchOptions,
    fetch: customFetch
  });
}

function createErrorLink(cookies, cache, ctx) {
  return onError(function (error) {
    logErrorsInDev(error);

    if (hasSessionExpired(error) && cookies.get(AppCookies.AccessToken)) {
      // `path` is required below to prevent issues with dynamic routes
      cookies.remove(AppCookies.AccessToken, {
        path: '/'
      }); //remove store since there could be b2b store for b2b users

      cookies.remove(AppCookies.Store, {
        path: '/'
      });
      cache.reset();

      if (!isServer()) {
        // Force a window reload, let routes determine if they are protected
        window.location.reload();
      }
    }

    if (hasRememberMeSessionExpired(error) && cookies.get(AppCookies.AccessToken)) {
      var currentAsPath = (ctx === null || ctx === void 0 ? void 0 : ctx.asPath) || Router.asPath;

      var _getLocalizedUrl = getLocalizedUrl(userSession(cookies.getAll()), "".concat(loginRoute.index(), "?redirect=").concat(encodeURIComponent(currentAsPath || homeRoute.index()), "&sessionExpired=true")),
          as = _getLocalizedUrl.as,
          href = _getLocalizedUrl.href;

      if (!isServer()) {
        Router.push(href, as);
      } else if (ctx !== null && ctx !== void 0 && ctx.res) {
        if (!ctx.res.headersSent) {
          ctx.res.writeHead(302, {
            Location: as
          });
        }

        ctx.res.end();
      }
    }
  });
} // Define cacheable queries dictionary


var cacheableQueriesMap = {
  AemHeaderFooter: true,
  ProductDetail: true,
  SdsCertificateSearch: true,
  EmproveProductDocs: true
};

function createHeadersLink(cookies) {
  var _getConfig2 = getConfig(),
      brandIdentity = _getConfig2.publicRuntimeConfig.brandIdentity;

  return setContext(function (graphQLRequest, _ref) {
    var _ref$headers = _ref.headers,
        headers = _ref$headers === void 0 ? {} : _ref$headers;

    // We use `pickBy` here to filter out falsy values since the apollo client
    // will coerce things like `undefined` to a string value ("undefined") otherwise.
    var _ref2 = cookies.getAll ? cookies.getAll() : cookies,
        country = _ref2.country,
        accessToken = _ref2.accessToken,
        _ga = _ref2._ga,
        store = _ref2.store,
        language = _ref2.language,
        isMarketplaceCatalogEnabled = _ref2.isMarketplaceCatalogEnabled,
        isBlueErpIntegrationEnabled = _ref2.isBlueErpIntegrationEnabled,
        userErpType = _ref2.userErpType,
        hasOnlyBlueERP = _ref2.hasOnlyBlueERP,
        isDarmstadtUser = _ref2.isDarmstadtUser;

    var authHeaders = pickBy({
      'x-gql-country': country,
      'x-gql-access-token': accessToken,
      'x-gql-guid': _ga,
      'x-gql-store': store,
      'x-gql-language': language,
      'x-gql-mp-enabled': isMarketplaceCatalogEnabled,
      'x-gql-blue-erp-enabled': isBlueErpIntegrationEnabled,
      'x-gql-user-erp-type': userErpType,
      'x-gql-has-only-blue-erp': hasOnlyBlueERP,
      'x-gql-is-darmstadt-user': isDarmstadtUser,
      'x-gql-requesting-website': brandIdentity
    }, Boolean);
    var graphQLOperationHeader = createGraphQLOperationName(graphQLRequest);
    return {
      headers: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, headers), authHeaders), graphQLOperationHeader), graphQLRequest['operationName'] ? cacheableQueriesMap[graphQLRequest.operationName] ? {
        'x-gql-cacheable': 'true'
      } : {} : {})
    };
  });
}