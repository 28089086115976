import getConfig from 'next/config';
import { OurBrands } from '@sial/common-design';
export var getBrandIdentity = function getBrandIdentity() {
  var _getConfig = getConfig(),
      configBrandIdentity = _getConfig.publicRuntimeConfig.brandIdentity;

  var isValidBrandIdentity = (configBrandIdentity in OurBrands);
  var brandIdentity = isValidBrandIdentity ? configBrandIdentity : 'SigmaAldrich';

  if (!isValidBrandIdentity) {
    console.error("INVALID BRAND IDENTITY VARIABLE SUPPLIED! App received '".concat(configBrandIdentity, "' as the 'brandIdentity' config variable."));
    console.error("App will default to '".concat(brandIdentity, "' because 'brandIdentity' was invalid."));
  }

  return brandIdentity;
};