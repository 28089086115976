import pino from 'pino';
import getConfig from 'next/config';
var isDevelopment = false;

var _getConfig = getConfig(),
    _getConfig$publicRunt = _getConfig.publicRuntimeConfig.logConfig,
    name = _getConfig$publicRunt.name,
    level = _getConfig$publicRunt.level;

export var log = pino({
  name: name,
  level: level,
  prettyPrint: isDevelopment ? {
    ignore: 'pid,hostname',
    translateTime: true,
    levelFirst: true
  } : false
});