import { useCallback, useMemo } from 'react';
import * as countriesMap from '@localization/countries.json';
export var useCountriesData = function useCountriesData() {
  var getCountries = useMemo(function () {
    var countriesDropDown = [];

    for (var countryCode in countriesMap) {
      var _countriesMap$country;

      if ((_countriesMap$country = countriesMap[countryCode]) !== null && _countriesMap$country !== void 0 && _countriesMap$country.displayName) {
        var _countriesMap$country2;

        countriesDropDown.push({
          label: ((_countriesMap$country2 = countriesMap[countryCode]) === null || _countriesMap$country2 === void 0 ? void 0 : _countriesMap$country2.displayName) || '',
          value: countryCode
        });
      }
    }

    return countriesDropDown;
  }, [countriesMap]);
  var getCountryDetails = useCallback(function (country) {
    return countriesMap[country];
  }, [countriesMap]);
  var getCountryProp = useCallback(function (country, prop) {
    var _countriesMap$country3;

    return countriesMap === null || countriesMap === void 0 ? void 0 : (_countriesMap$country3 = countriesMap[country]) === null || _countriesMap$country3 === void 0 ? void 0 : _countriesMap$country3[prop];
  }, [countriesMap]);
  return useMemo(function () {
    return {
      getCountries: getCountries,
      getCountryDetails: getCountryDetails,
      getCountryProp: getCountryProp
    };
  }, [getCountries, getCountryDetails, getCountryProp]);
};