export var createPersistentStorage = function createPersistentStorage(subscribableStorage, key, cacheBuster) {
  if (cacheBuster) {
    var cacheBusterKey = "".concat(key, ".CACHE_BUSTER");
    var previousCacheBuster = subscribableStorage.getItem(cacheBusterKey);

    if (previousCacheBuster !== cacheBuster) {
      subscribableStorage.removeItem(key);
    }

    subscribableStorage.setItem(cacheBusterKey, cacheBuster);
  }

  return {
    get: function get() {
      return subscribableStorage.getItem(key);
    },
    set: function set(newValue) {
      return subscribableStorage.setItem(key, newValue);
    },
    listen: function listen(callback) {
      return subscribableStorage.subscribe(key, callback);
    }
  };
};