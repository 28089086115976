/**
 * Checks if the code is running on the server-side.
 *
 * This function determines if the code is being executed in a server environment
 * by checking if the `window` object is undefined. In a server environment like Node.js,
 * `window` is not defined, whereas in a browser environment, it is.
 *
 * @returns {boolean} - Returns `true` if the code is running on the server-side, `false` otherwise.
 */
export var isServer = function isServer() {
  return false;
};