export default function createGraphQLOperationName(graphQLRequest) {
  var operationName = graphQLRequest.operationName ? graphQLRequest.operationName : '';

  if (operationName !== '') {
    var variables = graphQLRequest.variables ? graphQLRequest.variables : {};

    if (variables.id) {
      return {
        'x-gql-operation-name': "".concat(operationName, "-").concat(variables.id)
      };
    } else if (variables.action) {
      return {
        'x-gql-operation-name': "".concat(operationName, "-").concat(variables.action)
      };
    } else {
      return {
        'x-gql-operation-name': operationName
      };
    }
  } else {
    return {};
  }
}