import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  APP_TITLE: {
    id: 'APP_TITLE',
    defaultMessage: 'Sigma-Aldrich'
  },
  NUMBERS_ONLY: {
    id: 'NUMBERS_ONLY',
    defaultMessage: 'Must be numbers only'
  },
  ALPHANUMERIC_ONLY: {
    id: 'ALPHANUMERIC_ONLY',
    defaultMessage: 'Alphanumeric characters only.'
  },
  SHIPPED_POSTAL_CODE: {
    id: 'SHIPPED_POSTAL_CODE',
    defaultMessage: 'Shipped to Postal Code'
  },
  EMAIL_VERIFY: {
    id: 'EMAIL_VERIFY',
    defaultMessage: 'Verify your email address'
  },
  EMAIL_VERIFY_SUB: {
    id: 'EMAIL_VERIFY_SUB',
    defaultMessage: 'Thank you for using our website. We are cleaning up our data. To ensure our information is correct and up to date, please verify the email address associated with your profile. You will receive an email with a verification link. The link is valid for 24 hours.'
  },
  REMIND_ME_LATER: {
    id: 'REMIND_ME_LATER',
    defaultMessage: 'Remind Me Later'
  },
  VERIFY_MAIL: {
    id: 'VERIFY_MAIL',
    defaultMessage: 'Verify Email'
  },
  SOLD_TO_NUMBER: {
    id: 'SOLD_TO_NUMBER',
    defaultMessage: 'Sold to Number'
  },
  REQUIRED_FIELDS: {
    id: 'REQUIRED_FIELDS',
    defaultMessage: 'Required Fields'
  },
  ORDER_NUMBER_REQUIRED: {
    id: 'ORDER_NUMBER_REQUIRED',
    defaultMessage: 'Please enter an Order number.'
  },
  SHIPPING_POSTAL_CODE_REQUIRED: {
    id: 'SHIPPING_POSTAL_CODE_REQUIRED',
    defaultMessage: 'Please enter a Shipped to postal code.'
  },
  SOLD_TO_NUMBER_REQUIRED: {
    id: 'SOLD_TO_NUMBER_REQUIRED',
    defaultMessage: 'Please enter a Sold to number.'
  },
  ORDER_POPUP_MESSAGE: {
    id: 'ORDER_POPUP_MESSAGE',
    defaultMessage: ' You can find the order number in the order confirmation email.'
  },
  SOLD_TO_POPUP_MESSAGE: {
    id: 'SOLD_TO_POPUP_MESSAGE',
    defaultMessage: 'You can find the Sold to Number listed under Customer Number in your order confirmation.'
  },
  ORDER_TITLE: {
    id: 'ORDER_TITLE',
    defaultMessage: 'Please complete the fields below to check the status of your order.'
  },
  SIGN_IN_ORDER_LOOKUP: {
    id: 'SIGN_IN_ORDER_LOOKUP',
    defaultMessage: 'Sign in to access status, tracking and history of all your orders.'
  },
  ALREADY_HAVE_AN_ACCOUNT: {
    id: 'ALREADY_HAVE_AN_ACCOUNT',
    defaultMessage: 'Already have an account?'
  },
  NO_MATCHING_ORDERS: {
    id: 'NO_MATCHING_ORDERS',
    defaultMessage: 'No matching order was found. Please check the information you entered and try again.'
  },
  NUMERIC_MOBILE: {
    id: 'NUMERIC_MOBILE',
    defaultMessage: 'Invalid character entered. Allowed Characters are: Numbers - ( )'
  },
  NUMERIC_FAX: {
    id: 'NUMERIC_FAX',
    defaultMessage: 'Invalid character entered. Allowed Characters are: Numbers - ( )'
  },
  NUMERIC_PHONE: {
    id: 'NUMERIC_PHONE',
    defaultMessage: 'Invalid character entered. Allowed Characters are: Numbers - ( )'
  },
  NUMERIC_MOBILE_WITH_SPACE: {
    id: 'NUMERIC_MOBILE_WITH_SPACE',
    defaultMessage: 'Invalid character entered. Allowed Characters are: Numbers Space - ( )'
  },
  NUMERIC_FAX_WITH_SPACE: {
    id: 'NUMERIC_FAX_WITH_SPACE',
    defaultMessage: 'Invalid character entered. Allowed Characters are: Numbers Space - ( )'
  },
  NUMERIC_PHONE_WITH_SPACE: {
    id: 'NUMERIC_PHONE_WITH_SPACE',
    defaultMessage: 'Invalid character entered. Allowed Characters are: Numbers Space - ( )'
  },
  MAX_N_CHARACTERS: {
    id: 'MAX_N_CHARACTERS',
    defaultMessage: 'Must be {max} characters max'
  },
  MAX_241_CHARACTERS: {
    id: 'MAX_241_CHARACTERS',
    defaultMessage: 'Must be 241 characters max'
  },
  MAX_10_CHARACTERS: {
    id: 'MAX_10_CHARACTERS',
    defaultMessage: 'Must be 10 characters max'
  },
  MAX_16_CHARACTERS: {
    id: 'MAX_16_CHARACTERS',
    defaultMessage: 'Must be 16 characters max'
  },
  MAX_20_CHARACTERS: {
    id: 'MAX_20_CHARACTERS',
    defaultMessage: 'Must be 20 characters max'
  },
  EXP_DATE_CANNOT_BE_IN_PAST: {
    id: 'EXP_DATE_CANNOT_BE_IN_PAST',
    defaultMessage: 'Expiration Date cannot be in past'
  },
  EMAIL_MUST_BE_VALID: {
    id: 'EMAIL_MUST_BE_VALID',
    defaultMessage: 'Must be valid email address'
  },
  USERNAME_REQUIRED: {
    id: 'USERNAME_REQUIRED',
    defaultMessage: 'Username required'
  },
  //Can be returned as error message key from endpoint.
  EMAIL_CART_DOES_NOT_EXIST: {
    id: 'EMAIL_CART_DOES_NOT_EXIST',
    defaultMessage: 'Email cart does not exist'
  },
  EMOJI_NOT_SUPPORTED: {
    id: 'EMOJI_NOT_SUPPORTED',
    defaultMessage: ' Emojis are not supported'
  },
  PIN_CODE_INVALID: {
    id: 'PIN_CODE_INVALID',
    defaultMessage: 'Pincode is invalid for the selected location'
  },
  TECHNICAL_DOCUMENTS: {
    id: 'TECHNICAL_DOCUMENTS',
    defaultMessage: 'Technical Documents'
  },
  SITE_CONTENT: {
    id: 'SITE_CONTENT',
    defaultMessage: 'Site Content'
  },
  USER_LOGONID_AND_PASSWORD_CANNOT_BE_SAME: {
    id: 'USER_LOGONID_AND_PASSWORD_CANNOT_BE_SAME',
    defaultMessage: 'Password cannot be the same as username'
  },
  USER_INVALID_PASSWORD_PATTERN: {
    id: 'USER_INVALID_PASSWORD_PATTERN',
    defaultMessage: 'Must have at minimum 6 characters, maximum 51 characters, 1 number, 1 letter and maximum repeat/ instances of any character is 50.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  USER_NOT_AUTHORIZED_OR_TOKEN_EXPIRED: {
    id: 'USER_NOT_AUTHORIZED_OR_TOKEN_EXPIRED',
    defaultMessage: 'You are not authorized or Your user session has expired. Sign in to continue.'
  },
  EMAIL_AND_PASSWORD_CANNOT_BE_SAME: {
    id: 'EMAIL_AND_PASSWORD_CANNOT_BE_SAME',
    defaultMessage: 'Email and password cannot be same'
  },
  MUST_ACCEPT_TERMS: {
    id: 'MUST_ACCEPT_TERMS',
    defaultMessage: 'Must accept Terms and Conditions'
  },
  MUST_ACCEPT_PRIVACY_POLICY: {
    id: 'MUST_ACCEPT_PRIVACY_POLICY',
    defaultMessage: 'Must accept Privacy Policy'
  },
  MUST_ACCEPT_TERMS_OR_CONTINUE_AS_GUEST: {
    id: 'MUST_ACCEPT_TERMS_OR_CONTINUE_AS_GUEST',
    defaultMessage: 'Must accept Terms and Conditions or continue as guest'
  },
  EMAIL_EXISTS: {
    id: 'EMAIL_EXISTS',
    defaultMessage: 'An account with this email address already exists'
  },
  ACCOUNT_EXIST: {
    id: 'ACCOUNT_EXIST',
    defaultMessage: 'Account already registered. {link}'
  },
  MIN_N_CHARACTERS: {
    id: 'MIN_N_CHARACTERS',
    defaultMessage: 'Must be {min} characters min'
  },
  MIN_N_QUANTITY: {
    id: 'MIN_N_QUANTITY',
    defaultMessage: 'Minimum {min}'
  },
  EXACT_N_CHARACTERS: {
    id: 'EXACT_N_CHARACTERS',
    defaultMessage: 'Must be {quantity} characters'
  },
  EXP_YEAR_MUST_BE_4_DIGITS: {
    id: 'EXP_YEAR_MUST_BE_4_DIGITS',
    defaultMessage: 'Expiration year must be 4 digits'
  },
  PLEASE_ENTER_VALID_AMOUNT: {
    id: 'PLEASE_ENTER_VALID_AMOUNT',
    defaultMessage: 'Please enter valid amount'
  },
  PHONE_FAX_NO_HELP_MSG: {
    id: 'PHONE_FAX_NO_HELP_MSG',
    defaultMessage: 'Invalid character entered. Allowed Characters are: Numbers - + ( )'
  },
  MAX_40_CHARACTERS: {
    id: 'MAX_40_CHARACTERS',
    defaultMessage: 'Must be 40 characters max'
  },
  INVALID_ENTRY: {
    id: 'INVALID_ENTRY',
    defaultMessage: 'Invalid entry'
  },
  PROFILE_UPDATE_SUCCESS: {
    id: 'PROFILE_UPDATE_SUCCESS',
    defaultMessage: 'Your profile has been successfully updated.'
  },
  PASSWORD_PATTERN_CLIENT: {
    id: 'PASSWORD_PATTERN_CLIENT',
    defaultMessage: 'Passwords require a minimum of 8 characters. Password must contain a minimum combination of 3 of 4 of the following: Uppercase, Lowercase, Number, Special Character.'
  },
  PASSWORD_PATTERN: {
    id: 'PASSWORD_PATTERN',
    defaultMessage: 'Must have at minimum 6 characters, maximum 51 characters, 1 number, 1 letter and maximum repeat instances of any character is 50.'
  },
  PASSWORD_CANT_BE_USERNAME: {
    id: 'PASSWORD_CANT_BE_USERNAME',
    defaultMessage: 'Password cannot be the same as username'
  },
  PASSWORDS_MUST_MATCH: {
    id: 'PASSWORDS_MUST_MATCH',
    defaultMessage: 'Passwords must match'
  },
  PASSWORD_LETTER_RULE: {
    id: 'PASSWORD_LETTER_RULE',
    defaultMessage: 'Must include at least one letter'
  },
  PASSWORD_NUMBER_RULE: {
    id: 'PASSWORD_NUMBER_RULE',
    defaultMessage: 'Must include at least one number'
  },
  PASSWORD_LENGTH_RULE: {
    id: 'PASSWORD_LENGTH_RULE',
    defaultMessage: 'Must be at least 8 characters'
  },
  INVALID_PASSWORD: {
    id: 'INVALID_PASSWORD',
    defaultMessage: 'Invalid Password'
  },
  PASSWORD_SUCCESSFULLY_UPDATED: {
    id: 'PASSWORD_SUCCESSFULLY_UPDATED',
    defaultMessage: 'Password Successfully Updated'
  },
  MUST_ENTER_LOT_NUMBER: {
    id: 'MUST_ENTER_LOT_NUMBER',
    defaultMessage: 'Must enter a lot number.'
  },
  MUST_ENTER_PRODUCT_NUMBER: {
    id: 'MUST_ENTER_PRODUCT_NUMBER',
    defaultMessage: 'Must enter a product number.'
  },
  PLEASE_ENTER_PRODUCT_NUMBER: {
    id: 'PLEASE_ENTER_PRODUCT_NUMBER',
    defaultMessage: 'Please enter a Product Number'
  },
  PLEASE_ENTER_LOT_BATCH_NUMBER: {
    id: 'PLEASE_ENTER_LOT_BATCH_NUMBER',
    defaultMessage: 'Please enter a Lot or Batch Number'
  },
  CERTIFICATE_NOT_FOUND: {
    id: 'CERTIFICATE_NOT_FOUND',
    defaultMessage: 'Sorry, unable to find certificate. If both product number and lot number are entered correctly, please contact us to request a paper copy.'
  },
  DOCUMENT_SEARCH_SUBTITLE: {
    id: 'DOCUMENT_SEARCH_SUBTITLE',
    defaultMessage: 'What type of document can we help you find?'
  },
  ENTER_AS: {
    id: 'ENTER_AS',
    defaultMessage: 'enter as '
  },
  QUOTE_FULLY_CONVERTED_TO_ORDER: {
    id: 'QUOTE_FULLY_CONVERTED_TO_ORDER',
    defaultMessage: 'All item quantities have been fully converted to orders. To create a new quote, click on the "Request New Quote" button.'
  },
  ALL_QUOTE_ITEMS_REJECTED: {
    id: 'ALL_QUOTE_ITEMS_REJECTED',
    defaultMessage: 'This quote is no longer valid and cannot be converted to an order. To create a new quote, click on the "Request New Quote" button.'
  },
  QUOTE_EXPIRED: {
    id: 'QUOTE_EXPIRED',
    defaultMessage: 'This quote has expired. To request a new quote, click the "Request New Quote" button.'
  },
  QUOTE_DEFAULT_MESSAGE: {
    id: 'QUOTE_DEFAULT_MESSAGE',
    defaultMessage: 'Your Quote is displayed below. Modify the quantity box for each product and then click the "Convert to Order" button to convert the Quote into an Order.'
  },
  QUOTE_CONVERT_ERROR: {
    id: 'QUOTE_CONVERT_ERROR',
    defaultMessage: 'An error occurred converting the quote to an order. {errorMessage}'
  },
  LIST_TO_QUOTE_CONVERT_ERROR: {
    id: 'LIST_TO_QUOTE_CONVERT_ERROR',
    defaultMessage: 'An error occurred converting the list to a quote. {errorMessage}'
  },
  QUICK_ORDER_TO_QUOTE_CONVERT_ERROR: {
    id: 'QUICK_ORDER_TO_QUOTE_CONVERT_ERROR',
    defaultMessage: 'An error occurred converting the quick order to a quote. {errorMessage}'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  QUOTE_ALREADY_EXISTS_IN_CART: {
    id: 'QUOTE_ALREADY_EXISTS_IN_CART',
    defaultMessage: 'You cannot add two quotes to the same cart.'
  },
  NO_INVOICE_ERROR: {
    id: 'NO_INVOICE_ERROR',
    defaultMessage: 'The requested invoice is not currently available.'
  },
  PAY_INVOICES: {
    id: 'PAY_INVOICES',
    defaultMessage: 'Pay Invoices'
  },
  INVOICES: {
    id: 'INVOICES',
    defaultMessage: 'Invoices'
  },
  NO_PDF_ERROR: {
    id: 'NO_PDF_ERROR',
    defaultMessage: 'The requested PDF is not currently available.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  USER_PASSWORD_EXPIRED: {
    id: 'USER_PASSWORD_EXPIRED',
    defaultMessage: 'Your password has expired please reset your password to continue.'
  },
  USER_NO_SESSION_ID_PASSED: {
    id: 'USER_NO_SESSION_ID_PASSED',
    defaultMessage: 'Either the username or password is incorrect. Please try again.'
  },
  USER_MARKED_FOR_DELETE: {
    id: 'USER_MARKED_FOR_DELETE',
    defaultMessage: 'Sorry we are unable to access this account. Please try again. If the issue persists, please contact webhelp@sial.com for assistance.'
  },
  USER_INVALID_SESSION_ID_PASSED: {
    id: 'USER_INVALID_SESSION_ID_PASSED',
    defaultMessage: 'Either the username or password is incorrect. Please try again.'
  },
  USER_ACCOUNT_INACTIVE: {
    id: 'USER_ACCOUNT_INACTIVE',
    defaultMessage: 'Sorry we are unable to access this account. Please try again. If the issue persists, please contact webhelp@sial.com for assistance.'
  },
  USER_ACCOUNT_NOT_ACTIVATED: {
    id: 'USER_ACCOUNT_NOT_ACTIVATED',
    defaultMessage: 'Please activate your Profile. Before you can login, you must active your Profile with the code sent to your email address.'
  },
  USER_ACCOUNT_LOCKED: {
    id: 'USER_ACCOUNT_LOCKED',
    defaultMessage: 'Sorry we are unable to access this account. Please try again. If the issue persists, please contact webhelp@sial.com for assistance.'
  },
  USER_NOT_ALLOWED_TO_LOGIN_BEFORE: {
    id: 'USER_NOT_ALLOWED_TO_LOGIN_BEFORE',
    defaultMessage: 'Too many failed login attempts. Please try again in 15 minutes.'
  },
  USER_UNABLE_TO_LOGIN: {
    id: 'USER_UNABLE_TO_LOGIN',
    defaultMessage: 'An unexpected error occurred. Please try again later.'
  },
  USER_INVALID_LOGIN_DETAILS: {
    id: 'USER_INVALID_LOGIN_DETAILS',
    defaultMessage: 'Sorry, username or password is incorrect.'
  },
  CUSTOMER_NOT_ALLOWED_TO_LINK_PROFILE_BEFORE: {
    id: 'CUSTOMER_NOT_ALLOWED_TO_LINK_PROFILE_BEFORE',
    defaultMessage: 'You have provided invalid details. Please try again after {mins} mins {secs} secs'
  },
  ERROR_NO_RECORD_FOUND_FOR_GIVEN_COMBINATION_OF_CUSTOMER_DETAILS: {
    id: 'ERROR_NO_RECORD_FOUND_FOR_GIVEN_COMBINATION_OF_CUSTOMER_DETAILS',
    defaultMessage: "Sorry, your organization wasn't found."
  },
  RESET_PASSWORD_LINK_SENT: {
    id: 'RESET_PASSWORD_LINK_SENT',
    defaultMessage: 'Reset Password Link Sent'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  PASSWORD_RESET_SUCCESSFUL: {
    id: 'PASSWORD_RESET_SUCCESSFUL',
    defaultMessage: 'We have sent a password reset link to {email}. {linebreak}{linebreak} If you do not receive the email after few minutes, please check your spam or junk email folders. After the password is reset, you will be prompted to login again. The link is valid for 24 hours.'
  },
  PASSWORD_RESET_MAIL_SEND: {
    id: 'PASSWORD_RESET_MAIL_SEND',
    defaultMessage: 'If there is an account associated with {email} you will receive an email with a link to reset your password. {linebreak}{linebreak} If you do not receive the email after a few minutes, please check your spam or click Resend Email to have us resend the email. Once you have activated the link and reset your password, you will be prompted to login again. The link is valid for 24 hours. If you continue to have problems logging into your account please contact {customer_service}'
  },
  ERROR_INVALID_CLIENT: {
    id: 'ERROR_INVALID_CLIENT',
    defaultMessage: 'Sorry, but the client is invalid.'
  },
  INVALID_REQUEST_REDIRECT_URI_MISMATCH: {
    id: 'INVALID_REQUEST_REDIRECT_URI_MISMATCH',
    defaultMessage: 'Sorry, but authentication cannot be completed because the reply URL does not match the reply URL configured for the account.'
  },
  ERROR_THIRD_PARTY_OAUTH2: {
    id: 'ERROR_THIRD_PARTY_OAUTH2',
    defaultMessage: 'Sorry. There is a technical issue with this request and authentication cannot be completed.  Please contact the referring site for assistance with this issue.'
  },
  CUSTOMER_SUPPORT: {
    id: 'CUSTOMER_SUPPORT',
    defaultMessage: 'Customer Support.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  USER_EMAIL_TIED_TO_MULTIPLE_PROFILES: {
    id: 'USER_EMAIL_TIED_TO_MULTIPLE_PROFILES',
    defaultMessage: 'Multiple usernames are associated with the provided email address. Please enter the username associated with your profile to continue.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  USER_INVALID_USERNAME_OR_EMAIL: {
    id: 'USER_INVALID_USERNAME_OR_EMAIL',
    defaultMessage: 'Email address/user name not found in our database, please try again with valid email/user name'
  },
  ERROR_404_MESSAGE: {
    id: 'ERROR_404_MESSAGE',
    defaultMessage: "We can't find the page you were looking for."
  },
  ERROR_GENERIC_MESSAGE: {
    id: 'ERROR_GENERIC_MESSAGE',
    defaultMessage: 'Something has gone wrong and we are working to fix it.'
  },
  ERROR_GENERIC_MESSAGE_STATUS: {
    id: 'ERROR_GENERIC_MESSAGE_STATUS',
    defaultMessage: 'Error status: {statusCode}'
  },
  CUSTOMER_CONFIRMATION_DIALOG_TITLE: {
    id: 'CUSTOMER_CONFIRMATION_DIALOG_TITLE',
    defaultMessage: 'customer number'
  },
  TRANSACTION_ORDER_PRP_NUMBER_DEFINITION: {
    id: 'TRANSACTION_ORDER_PRP_NUMBER_DEFINITION',
    defaultMessage: 'Order Number is typically ten digits starting with a 3 and can be found in the upper portion of an order confirmation or invoice but may also be called the internal order number or sales order number.'
  },
  TRANSACTION_QUOTE_PRP_NUMBER_DEFINITION: {
    id: 'TRANSACTION_QUOTE_PRP_NUMBER_DEFINITION',
    defaultMessage: 'If you received a quote from a sales representative, the quote reference is typically eleven characters and begins with an R. If you received it directly from our customer service or a colleague who created it online, the quote reference is typically ten digits starting with 20.'
  },
  TRANSACTION_INVOICE_PRP_NUMBER_DEFINITION: {
    id: 'TRANSACTION_INVOICE_PRP_NUMBER_DEFINITION',
    defaultMessage: 'Invoice Number is typically eight digits starting with 8 and is only shown on the invoice document.'
  },
  TRANSACTION_ORDER_PRE_NUMBER_DEFINITION: {
    id: 'TRANSACTION_ORDER_PRE_NUMBER_DEFINITION',
    defaultMessage: 'Order number is typically ten digits starting with an 8 and can be found in the upper portion of an order confirmation or invoice.'
  },
  TRANSACTION_QUOTE_PRE_NUMBER_DEFINITION: {
    id: 'TRANSACTION_QUOTE_PRE_NUMBER_DEFINITION',
    defaultMessage: 'If you received a quote from a sales representative, the quote reference is typically eleven characters and begins with an R. If you received it directly from our customer service or a colleague who created it online, the quote reference is typically ten digits starting with 82.'
  },
  TRANSACTION_INVOICE_PRE_NUMBER_DEFINITION: {
    id: 'TRANSACTION_INVOICE_PRE_NUMBER_DEFINITION',
    defaultMessage: 'Invoice number is typically ten digits starting with 80 and is only shown on the invoice document.'
  },
  TRANSACTION_ORDER_PRD_NUMBER_DEFINITION: {
    id: 'TRANSACTION_ORDER_PRD_NUMBER_DEFINITION',
    defaultMessage: 'Order number is typically ten digits starting with a 3 and can be found in the upper portion of an order confirmation or invoice but may also be called the internal order number or sales order number.'
  },
  TRANSACTION_QUOTE_PRD_NUMBER_DEFINITION: {
    id: 'TRANSACTION_QUOTE_PRD_NUMBER_DEFINITION',
    defaultMessage: 'If you received a quote from a sales representative, the quote reference is typically eleven characters and begins with an R. If you received it directly from our customer service or a colleague who created it online, the quote reference is typically eight digits starting with 22.'
  },
  TRANSACTION_INVOICE_PRD_NUMBER_DEFINITION: {
    id: 'TRANSACTION_INVOICE_PRD_NUMBER_DEFINITION',
    defaultMessage: 'Invoice number is typically nine digits starting with 55 and is only shown on the invoice document.'
  },
  TRANSACTION_CONFIRMATION_DIALOG_TITLE: {
    id: 'TRANSACTION_CONFIRMATION_DIALOG_TITLE',
    defaultMessage: 'Transaction Information'
  },
  CUSTOMER_NUMBER_PRP_DEFINITION: {
    id: 'CUSTOMER_NUMBER_PRP_NUMBER_DEFINITION',
    defaultMessage: 'Your customer number is typically ten digits starting with a 2 and can be found in the upper portion of an order confirmation, quote or invoice.'
  },
  CUSTOMER_NUMBER_PRE_DEFINITION: {
    id: 'CUSTOMER_NUMBER_PRE_NUMBER_DEFINITION',
    defaultMessage: 'Your customer number is typically eight digits and can be found in the upper portion of an order confirmation, quote or invoice.'
  },
  CUSTOMER_NUMBER_PRD_DEFINITION: {
    id: 'CUSTOMER_NUMBER_PRD_NUMBER_DEFINITION',
    defaultMessage: 'Your customer number is typically eight digits starting with a 4 or 5 and can be found in the upper portion of an order confirmation, quote or invoice.'
  },
  ORDERS_APPROVED: {
    id: 'ORDERS_APPROVED',
    defaultMessage: 'Your order(s) were approved successfully.'
  },
  ORDERS_APPROVED_ERROR: {
    id: 'ORDERS_APPROVED_ERROR',
    defaultMessage: 'Sorry, an error has occurred. Please select your order(s) and try again.'
  },
  SHP_CUST_FEE: {
    id: 'SHP_CUST_FEE',
    defaultMessage: 'This product incurs additional customs and shipping fees. For further information on price and availability, please contact your Account Manager, or the Technical Support Team on technical-aus@merckgroup.com'
  },
  SHIP_TO: {
    id: 'SHIP_TO',
    defaultMessage: 'Ship To'
  },
  SHIP_DIRECTLY_FROM: {
    id: 'SHIP_DIRECTLY_FROM',
    defaultMessage: 'Ship directly from {shipFrom}'
  },
  BILL_TO: {
    id: 'BILL_TO',
    defaultMessage: 'Bill To'
  },
  MOLECULAR_WEIGHT: {
    id: 'MOLECULAR_WEIGHT',
    defaultMessage: 'Molecular Weight'
  },
  PRODUCT_BRAND: {
    id: 'PRODUCT_BRAND',
    defaultMessage: 'Product Brand'
  },
  PRODUCT_DESCRIPTION: {
    id: 'PRODUCT_DESCRIPTION',
    defaultMessage: 'Product Description'
  },
  CONDITIONS_OF_SALE: {
    id: 'CONDITIONS_OF_SALE',
    defaultMessage: 'Conditions of Sale'
  },
  UPDATING: {
    id: 'UPDATING',
    defaultMessage: 'Updating...'
  },
  PRIVACY_POLICY: {
    id: 'PRIVACY_POLICY',
    defaultMessage: 'Privacy Policy'
  },
  DATA_PRIVACY_POLAND: {
    id: 'DATA_PRIVACY_POLAND',
    defaultMessage: 'Data Privacy notice for customers in Poland'
  },
  POLAND_HERE: {
    id: 'POLAND_HERE',
    defaultMessage: 'HERE'
  },
  DATA_PRIVACY_POLAND_CONTENT: {
    id: 'DATA_PRIVACY_POLAND_CONTENT',
    defaultMessage: 'For customers and their representatives in Poland (customers) the data controller is also Merck Life Science sp. z o.o. (MLS) with its seat in Poznań (affiliate of Merck KGaA, Darmstadt, Germany). MLS processes personal data of customers, in particular in order to support the registration and order process in the online shop and their settlement. Customers have the right to access their personal data, to rectify, erasure them or restrict the processing, right to object and to file a complaint with the Polish Data Protection Authority (Prezes Urzędu Ochrony Danych Osobowych). All questions or requests related to the personal data protection, you can send to the address: {mailTo}. More information on personal data processing by MLS you can find {HERE} (part: Merck Life Science sp. z o.o.).'
  },
  PRIVACY_POLICY_TITLE_KOREA: {
    id: 'PRIVACY_POLICY_TITLE_KOREA',
    defaultMessage: 'Notes on joining as an associate member'
  },
  PRIVACY_POLICY_KOREA_LIST_ONE: {
    id: 'PRIVACY_POLICY_KOREA_LIST_ONE',
    defaultMessage: 'Normal ordering is possible only if the ID (e-mail form) created through the associate member registration is linked to the account.'
  },
  PRIVACY_POLICY_KOREA_LIST_TWO: {
    id: 'PRIVACY_POLICY_KOREA_LIST_TWO',
    defaultMessage: 'If a customer account is not registered, or if a customer account is registered but need to be linked, please refer to the link below. \nPlease submit your application or request.'
  },
  PRIVACY_POLICY_KOREA_LINK: {
    id: 'PRIVACY_POLICY_KOREA_LINK',
    defaultMessage: '*New customer registration application / ID linkage request'
  },
  UPDATE_TERMS_CONDITIONS: {
    id: 'UPDATE_TERMS_CONDITIONS',
    defaultMessage: 'Updated terms & conditions'
  },
  UPDATE_TERMS_CONDITIONS_DESCRIPTION: {
    id: 'UPDATE_TERMS_CONDITIONS_DESCRIPTION',
    defaultMessage: 'We have updated our terms and conditions. Please read and agree to the updated terms and conditions to continue using this site.'
  },
  SITE_USE_TERMS: {
    id: 'SITE_USE_TERMS',
    defaultMessage: 'Site Use Terms'
  },
  MERCKS_PRIVACY_POLICY_LINK: {
    id: 'MERCKS_PRIVACY_POLICY_LINK',
    defaultMessage: 'Merck’s Privacy Policy'
  },
  CONSENT_FORM_FOR_PERSONAL_INFORMATION_COLLECTION: {
    id: 'CONSENT_FORM_FOR_PERSONAL_INFORMATION_COLLECTION',
    defaultMessage: 'Consent Form for Personal Information Collection'
  },
  GENERAL_TERMS_AND_CONDITIONS_OF_SALE: {
    id: 'GENERAL_TERMS_AND_CONDITIONS_OF_SALE',
    defaultMessage: 'General Terms and Conditions of Sale'
  },
  AND_TERMS_AND_CONDITIONS_OF_SALES_OF_SELECTED_DEALERS: {
    id: 'AND_TERMS_AND_CONDITIONS_OF_SALES_OF_SELECTED_DEALERS',
    defaultMessage: 'and Terms and Conditions of Sales of selected dealer(s)'
  },
  TERMS_AND_CONDITIONS_OF_SALES: {
    id: 'TERMS_AND_CONDITIONS_OF_SALES',
    defaultMessage: 'Terms and Conditions of Sales'
  },
  OF_SELECTED_DEALERS: {
    id: 'OF_SELECTED_DEALERS',
    defaultMessage: 'of selected dealer(s)'
  },
  EMAIL_CART: {
    id: 'EMAIL_CART',
    defaultMessage: 'Email Cart'
  },
  PRINT_CART: {
    id: 'PRINT_CART',
    defaultMessage: 'Print Cart'
  },
  UPDATE_CART: {
    id: 'UPDATE_CART',
    defaultMessage: 'Update Cart'
  },
  RESEND_EMAIL_NOTIFICATION_SNACBAR: {
    id: 'RESEND_EMAIL_NOTIFICATION_SNACBAR',
    defaultMessage: 'Email verification link has been sent.'
  },
  RESEND_EMAIL_NOTIFICATION_SNACKBAR: {
    id: 'RESEND_EMAIL_NOTIFICATION_SNACKBAR',
    defaultMessage: 'Verification email has been sent to your new email account. Please verify your email address.'
  },
  CANCEL_CART: {
    id: 'CANCEL_CART',
    defaultMessage: 'Cancel Cart'
  },
  TOTAL_QTY: {
    id: 'TOTAL_QTY',
    defaultMessage: 'Total Quote Qty: {totalReserveQuantity}'
  },
  REMAINING_QTY: {
    id: 'REMAINING_QTY',
    defaultMessage: 'Remaining Qty: {remainingReserveQuantity}'
  },
  QUANTITY_REMAINING: {
    id: 'QUANTITY_REMAINING',
    defaultMessage: 'Quantity Remaining'
  },
  QUOTE_NUMBER_CART: {
    id: 'QUOTE_NUMBER_CART',
    defaultMessage: 'Quote Number: {quoteNumber}'
  },
  ADD_PROMO_CODE: {
    id: 'ADD_PROMO_CODE',
    defaultMessage: 'Add Promo Code'
  },
  CLEAR_PROMO_CODE: {
    id: 'CLEAR_PROMO_CODE',
    defaultMessage: 'Clear Promo Code'
  },
  PROMO_CODE_NOT_APPLIED: {
    id: 'PROMO_CODE_NOT_APPLIED',
    defaultMessage: 'Promo code cannot be applied to this item'
  },
  PROMO_CODE_NOT_YET_VALID: {
    id: 'PROMO_CODE_NOT_YET_VALID',
    defaultMessage: 'Promo code not yet valid'
  },
  PROMO_CODE_EXPIRED: {
    id: 'PROMO_CODE_EXPIRED',
    defaultMessage: 'Promo code expired'
  },
  PROMO_CODE_INVALID: {
    id: 'PROMO_CODE_INVALID',
    defaultMessage: 'Invalid promo code'
  },
  PROMO_CODE: {
    id: 'PROMO_CODE',
    defaultMessage: 'Promo Code'
  },
  PROMO_SAVE_ONLY_CODE: {
    id: 'PROMO_SAVE_ONLY_CODE',
    defaultMessage: 'Use promotion code {messageVariable2} in the cart to apply a discount to this product.'
  },
  REMOVE: {
    id: 'REMOVE',
    defaultMessage: 'Remove'
  },
  REMOVE_ITEM_FROM_CART: {
    id: 'REMOVE_ITEM_FROM_CART',
    defaultMessage: 'Remove from cart'
  },
  RESTORE_ITEM_TO_CART: {
    id: 'RESTORE_ITEM_TO_CART',
    defaultMessage: 'Restore to cart'
  },
  REQUEST_QUOTE: {
    id: 'REQUEST_QUOTE',
    defaultMessage: 'Request Quote'
  },
  REQUEST_QUOTATION: {
    id: 'REQUEST_QUOTATION',
    defaultMessage: 'Request Quotation'
  },
  REQUEST_QUOTATION_TEXT: {
    id: 'REQUEST_QUOTATION_TEXT',
    defaultMessage: 'Online ordering is currently unavailable for China.  Please contact Customer Service (siRNAorder@merckgroup.com) with your contact information and the SASI ID number of interest.  You can email the summary on this screen with email text or excel to the email provided.'
  },
  CURRENTLY_UNAVAILABLE: {
    id: 'CURRENTLY_UNAVAILABLE',
    defaultMessage: 'Currently Unavailable'
  },
  CHECKOUT: {
    id: 'CHECKOUT',
    defaultMessage: 'Checkout'
  },
  CHECKOUT_DETAILS: {
    id: 'CHECKOUT_DETAILS',
    defaultMessage: 'Checkout Details'
  },
  AGR: {
    id: 'AGR',
    defaultMessage: 'Agriculture'
  },
  BIOPH: {
    id: 'BIOPH',
    defaultMessage: 'Biopharmaceutical'
  },
  CHMFG: {
    id: 'CHMFG',
    defaultMessage: 'Chemical Manufacturer'
  },
  COMPY: {
    id: 'COMPY',
    defaultMessage: 'Industrial Manufacturer'
  },
  DERE: {
    id: 'DERE',
    defaultMessage: 'Dealer/Reseller'
  },
  DIAGC: {
    id: 'DIAGC',
    defaultMessage: 'Diagnostic Company'
  },
  GOVT: {
    id: 'GOVT',
    defaultMessage: 'Government, Other'
  },
  GPO: {
    id: 'GPO',
    defaultMessage: 'Group Purchasing'
  },
  GSAE: {
    id: 'GSAE',
    defaultMessage: 'Government, Federal, GSA Eligible'
  },
  HOSP: {
    id: 'HOSP',
    defaultMessage: 'Hospital'
  },
  IHN: {
    id: 'IHN',
    defaultMessage: 'Integrated Health Network'
  },
  OTHR: {
    id: 'OTHR',
    defaultMessage: 'Other'
  },
  PHARM: {
    id: 'PHARM',
    defaultMessage: 'Pharmaceutical'
  },
  POL: {
    id: 'POL',
    defaultMessage: 'Physician Office Lab'
  },
  REFL: {
    id: 'REFL',
    defaultMessage: 'Reference Lab'
  },
  SRINS: {
    id: 'SRINS',
    defaultMessage: 'Scientific Research Institute'
  },
  UNIV: {
    id: 'UNIV',
    defaultMessage: 'University'
  },
  CART_QUOTATION_TEXT: {
    id: 'CART_QUOTATION_TEXT',
    defaultMessage: 'Pricing can not be calculated. Add to cart to submit your quotation request.'
  },
  CART_ALCOHOL_TAX: {
    id: 'CART_ALCOHOL_TAX',
    defaultMessage: 'Alcohol Tax'
  },
  CART_CARRIER_HAZARD_FEE: {
    id: 'CART_CARRIER_HAZARD_FEE',
    defaultMessage: 'Carrier Hazard Fee'
  },
  CART_COMPLIANCE_FEE: {
    id: 'CART_COMPLIANCE_FEE',
    defaultMessage: 'DEA Administration Charge'
  },
  CART_DUTY_TOTAL: {
    id: 'CART_DUTY_TOTAL',
    defaultMessage: 'Duty'
  },
  CART_ELECTRICAL_FEE: {
    id: 'CART_ELECTRICAL_FEE',
    defaultMessage: 'Electrical Inspection Fee'
  },
  CART_MASS_CHARGE: {
    id: 'CART_MASS_CHARGE',
    defaultMessage: 'European Volatile Mass Charge'
  },
  CART_ICE_CHARGE: {
    id: 'CART_ICE_CHARGE',
    defaultMessage: 'Ice/Special Packaging Charges'
  },
  CART_TRANSPORTATION_CHARGE: {
    id: 'CART_TRANSPORTATION_CHARGE',
    defaultMessage: 'Transportation/Handling'
  },
  SALES_TAX: {
    id: 'SALES_TAX',
    defaultMessage: 'Sales Tax'
  },
  HANDLING_CHARGE: {
    id: 'HANDLING_CHARGE',
    defaultMessage: 'Handling'
  },
  CART_SUMMARY: {
    id: 'CART_SUMMARY',
    defaultMessage: 'Summary'
  },
  PAYMENT_DEALICENSE_NUMBER_EMPTY_SNACKBAR: {
    id: 'PAYMENT_DEALICENSE_NUMBER_EMPTY_SNACKBAR',
    defaultMessage: 'Your order contains a DEA controlled item and a valid license is required. Please provide your license number or contact our screening department at 800-521-8956 for more information. Item(s) {items}'
  },
  CART_ERROR_SUBMITTING: {
    id: 'CART_ERROR_SUBMITTING',
    defaultMessage: 'An error occurred submitting cart: {errorMessage}'
  },
  CART_ERROR_REQUESTING: {
    id: 'CART_ERROR_REQUESTING',
    defaultMessage: 'An error occurred requesting quote: {errorMessage}'
  },
  CART_ERROR_UPDATING: {
    id: 'CART_ERROR_UPDATING',
    defaultMessage: 'An error occurred updating cart: {errorMessage}'
  },
  CART_ERROR_SHIPPING_ADDRESS: {
    id: 'CART_ERROR_SHIPPING_ADDRESS',
    defaultMessage: 'Our system encountered an error with the shipping address for this order.Please verify that you have the correct State/Province Code, Postal Code and Country selected.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  ACTIVE_CART_NOT_FOUND_IN_DB: {
    id: 'ACTIVE_CART_NOT_FOUND_IN_DB',
    defaultMessage: 'Active cart not found in database'
  },
  CART_CONTACT_TELEPHONE_REQUIRED: {
    id: 'CART_CONTACT_TELEPHONE_REQUIRED',
    defaultMessage: 'Buyer Contact Phone Required'
  },
  CART_CONTACT_INFO_SAVE_ERROR: {
    id: 'CART_CONTACT_INFO_SAVE_ERROR',
    defaultMessage: 'An unknown error occurred saving contact info'
  },
  CART_CONTACT_INFO_SAVE: {
    id: 'CART_CONTACT_INFO_SAVE',
    defaultMessage: 'Save Contact Info'
  },
  CART_CONTACT_PARTY_INFO_SAVE_ERROR: {
    id: 'CART_CONTACT_PARTY_INFO_SAVE_ERROR',
    defaultMessage: 'An unknown error occurred saving contact party information'
  },
  CART_CONTACT_PARTY_INFO_CANCEL: {
    id: 'CART_CONTACT_PARTY_INFO_CANCEL',
    defaultMessage: 'Cancel'
  },
  CART_CONTACT_PARTY_INFO_SAVE: {
    id: 'CART_CONTACT_PARTY_INFO_SAVE',
    defaultMessage: 'Save Information'
  },
  CART_CC_ERROR_MSG: {
    id: 'CART_CC_ERROR_MSG',
    defaultMessage: 'Please separate email addresses with commas.'
  },
  CART_NAME: {
    id: 'CART_NAME',
    defaultMessage: 'Name'
  },
  EDIT_CONTACT_INFORMATION: {
    id: 'EDIT_CONTACT_INFORMATION',
    defaultMessage: 'Edit Contact Information'
  },
  CART_ADDRESS_NO_ORDERING_PARTY_ADDRESS: {
    id: 'CART_ADDRESS_NO_ORDERING_PARTY_ADDRESS',
    defaultMessage: 'No Buyer Information'
  },
  CART_ADDRESS_DISPLAY_FAX: {
    id: 'CART_ADDRESS_DISPLAY_FAX',
    defaultMessage: '{fax} (Fax)'
  },
  CART_ADDRESS_SHIPPING_ADDRESS_TITLE: {
    id: 'CART_ADDRESS_SHIPPING_ADDRESS_TITLE',
    defaultMessage: 'Buyer'
  },
  CART_EMAIL: {
    id: 'CART_EMAIL',
    defaultMessage: 'Email'
  },
  CART_SHIPPING_ADDRESS_SAVE: {
    id: 'CART_SHIPPING_ADDRESS_SAVE',
    defaultMessage: 'Save Address'
  },
  CART_SHIPPING_ADDRESS_SAVE_ERROR: {
    id: 'CART_SHIPPING_ADDRESS_SAVE_ERROR',
    defaultMessage: 'An unknown error occurred saving shipping address'
  },
  CART_SHIPPING_UPDATE_ERROR: {
    id: 'CART_SHIPPING_UPDATE_ERROR',
    defaultMessage: 'An unknown error occurred updating shipping address'
  },
  CART_SHIPPING_UPDATE_SUCCESS: {
    id: 'CART_SHIPPING_UPDATE_SUCCESS',
    defaultMessage: 'Shipping address successfully updated'
  },
  CART_ADDRESS_NO_SHIPPING_ADDRESS: {
    id: 'CART_ADDRESS_NO_SHIPPING_ADDRESS',
    defaultMessage: 'No Shipping Address'
  },
  PACKING_SLIP_NOTE: {
    id: 'PACKING_SLIP_NOTE',
    defaultMessage: 'Packing Slip Note'
  },
  CART_CSR_INSTRUCTIONS_ERROR: {
    id: 'CART_CSR_INSTRUCTIONS_ERROR',
    defaultMessage: 'An unknown error occurred saving customer service instructions'
  },
  CART_CSR_INSTRUCTIONS_SAVED: {
    id: 'CART_CSR_INSTRUCTIONS_SAVED',
    defaultMessage: 'Customer service instructions saved successfully'
  },
  CART_CSR_SAVE_NOTES: {
    id: 'CART_CSR_SAVE_NOTES',
    defaultMessage: 'Save Notes'
  },
  CART_CSR_INSTRUCTIONS_DETAILS: {
    id: 'CART_CSR_INSTRUCTIONS_DETAILS',
    defaultMessage: 'Special Order Notes are reviewed by a Customer Service Representative and will block your order from being automatically processed, causing a delay in your order. For collect shipments, please add your collect account number(s) and carrier name(s) in the Special Order Note field.'
  },
  CART_CSR_INSTRUCTIONS_LABEL: {
    id: 'CART_CSR_INSTRUCTIONS_LABEL',
    defaultMessage: 'Special Order Notes'
  },
  CART_CSR_INSTRUCTIONS: {
    id: 'CART_CSR_INSTRUCTIONS',
    defaultMessage: 'Customer Service Instructions'
  },
  CART_SAVE_PAYMENT_INFO_ERROR: {
    id: 'CART_SAVE_PAYMENT_INFO_ERROR',
    defaultMessage: 'An error occurred saving payment info'
  },
  CART_SAVE_PAYMENT_INFO_SUCCESS: {
    id: 'CART_SAVE_PAYMENT_INFO_SUCCESS',
    defaultMessage: 'Payment info updated successfully!'
  },
  CART_CANCEL_PAYMENT_INFO: {
    id: 'CART_CANCEL_PAYMENT_INFO',
    defaultMessage: 'Cancel'
  },
  CART_SAVE_PAYMENT_INFO: {
    id: 'CART_SAVE_PAYMENT_INFO',
    defaultMessage: 'Save Payment Info'
  },
  CART_PO_NUMBER: {
    id: 'CART_PO_NUMBER',
    defaultMessage: 'PO Number'
  },
  CART_PAYMENT_INFO: {
    id: 'CART_PAYMENT_INFO',
    defaultMessage: 'Payment Info'
  },
  CART_SAVE_BILLING_ADDRESS_ERROR: {
    id: 'CART_SAVE_BILLING_ADDRESS_ERROR',
    defaultMessage: 'An error occurred saving billing address'
  },
  CART_SAVE_BILLING_ADDRESS_SUCCESS: {
    id: 'CART_SAVE_BILLING_ADDRESS_SUCCESS',
    defaultMessage: 'Billing address updated successfully!'
  },
  CART_CANCEL_BILLING_ADDRESS: {
    id: 'CART_CANCEL_BILLING_ADDRESS',
    defaultMessage: 'Cancel'
  },
  CART_SAVE_BILLING_ADDRESS: {
    id: 'CART_SAVE_BILLING_ADDRESS',
    defaultMessage: 'Save Billing Address'
  },
  CART_EDIT_BILLING_ADDRESS: {
    id: 'CART_EDIT_BILLING_ADDRESS',
    defaultMessage: 'Edit Billing Address'
  },
  CART_ADDRESS_EDIT: {
    id: 'CART_ADDRESS_EDIT',
    defaultMessage: 'Edit'
  },
  CART_ADDRESS_NO_BILLING_ADDRESS: {
    id: 'CART_ADDRESS_NO_BILLING_ADDRESS',
    defaultMessage: 'No Billing Address'
  },
  CART_ADDRESS_DISPLAY_ATTN: {
    id: 'CART_ADDRESS_DISPLAY_ATTN',
    defaultMessage: 'ATTN: {name}'
  },
  CART_COUNTRY: {
    id: 'CART_COUNTRY',
    defaultMessage: 'Country'
  },
  CART_STATE: {
    id: 'CART_STATE',
    defaultMessage: 'State'
  },
  CART_POSTAL_CODE: {
    id: 'CART_POSTAL_CODE',
    defaultMessage: 'Postal Code'
  },
  CART_CITY: {
    id: 'CART_CITY',
    defaultMessage: 'City'
  },
  CART_STREET: {
    id: 'CART_STREET',
    defaultMessage: 'Street'
  },
  CART_ORGANIZATION: {
    id: 'CART_ORGANIZATION',
    defaultMessage: 'Organization'
  },
  RECIPIENT_EMAIL_ADDRESS: {
    id: 'RECIPIENT_EMAIL_ADDRESS',
    defaultMessage: 'Recipient Email Address *(Separate multiple email addresses with commas)'
  },
  MESSAGE: {
    id: 'MESSAGE',
    defaultMessage: 'Message'
  },
  SEND_ME_A_COPY: {
    id: 'SEND_ME_A_COPY',
    defaultMessage: 'Send me a copy'
  },
  SEND_EMAIL: {
    id: 'SEND_EMAIL',
    defaultMessage: 'Send Email'
  },
  EXPEDITED_DELIVERY: {
    id: 'EXPEDITED_DELIVERY',
    defaultMessage: 'Expedited Delivery'
  },
  ADDITIONAL_INFO_DESCRIPTION: {
    id: 'ADDITIONAL_INFO_DESCRIPTION',
    defaultMessage: 'Description:'
  },
  ADDITIONAL_INFO_FTCC: {
    id: 'ADDITIONAL_INFO_FTCC',
    defaultMessage: 'Foreign Trade Commodity Code:'
  },
  ADDITIONAL_INFO_UNTN: {
    id: 'ADDITIONAL_INFO_UNTN',
    defaultMessage: 'UN Transportation Number(IATA):'
  },
  ADDITIONAL_INFO_CAS: {
    id: 'ADDITIONAL_INFO_CAS',
    defaultMessage: 'CAS Number:'
  },
  ADDITIONAL_INFO_PDSC: {
    id: 'ADDITIONAL_INFO_PDSC',
    defaultMessage: 'Poison/Deleterious Substance Code:'
  },
  ADDITIONAL_INFO_JFC: {
    id: 'ADDITIONAL_INFO_JFC',
    defaultMessage: 'Japan Fire Code:'
  },
  ADDITIONAL_INFO_PACKAGING_INFO: {
    id: 'ADDITIONAL_INFO_PACKAGING_INFO',
    defaultMessage: 'Packaging Information'
  },
  ADDITIONAL_INFO_CARRIER_RESTRICTION: {
    id: 'ADDITIONAL_INFO_CARRIER_RESTRICTION',
    defaultMessage: 'Additional Fees/Restrictions:'
  },
  PRODUCT_INFO_TITLE: {
    id: 'PRODUCT_INFO_TITLE',
    defaultMessage: 'Product Information'
  },
  ADDITIONAL_INFO_POPOVER: {
    id: 'ADDITIONAL_INFO_POPOVER',
    defaultMessage: 'View Additional Information'
  },
  GENE_DETAIL: {
    id: 'GENE_DETAIL',
    defaultMessage: 'Gene Detail'
  },
  GENE_ID: {
    id: 'GENE_ID',
    defaultMessage: 'Gene ID'
  },
  GENE_SYMBOL: {
    id: 'GENE_SYMBOL',
    defaultMessage: 'Gene Symbol'
  },
  MOLECULAR_FORMULA: {
    id: 'MOLECULAR_FORMULA',
    defaultMessage: 'Molecular Formula'
  },
  MDL_NUMBER: {
    id: 'MDL_NUMBER',
    defaultMessage: 'MDL Number'
  },
  CAS_NUMBER: {
    id: 'CAS_NUMBER',
    defaultMessage: 'CAS Number'
  },
  PRODUCT_NUMBER: {
    id: 'PRODUCT_NUMBER',
    defaultMessage: 'Product Number'
  },
  PRODUCT_NUMBER_EXAMPLE: {
    id: 'PRODUCT_NUMBER_EXAMPLE',
    defaultMessage: 'Product Number'
  },
  LOT_BATCH_NUMBER: {
    id: 'LOT_BATCH_NUMBER',
    defaultMessage: 'Lot/Batch Number'
  },
  PRODUCT_NO: {
    id: 'PRODUCT_NO',
    defaultMessage: 'Product No.'
  },
  CATEGORIES: {
    id: 'CATEGORIES',
    defaultMessage: 'Categories'
  },
  SIGN_IN_TO_ACCEPT_INVITE: {
    id: 'SIGN_IN_TO_ACCEPT_INVITE',
    defaultMessage: 'Please sign in to accept the invite or request'
  },
  ORDER_APPROVALS_PROCESS: {
    id: 'ORDER_APPROVALS_PROCESS',
    defaultMessage: 'Advanced Ordering Process'
  },
  ORDER_APPROVALS_PROCESS_TITLE: {
    id: 'ORDER_APPROVALS_PROCESS_TITLE',
    defaultMessage: 'Order Approvals - Advanced Ordering Process'
  },
  ORDER_APPROVALS_BECOME_APPROVER: {
    id: 'ORDER_APPROVALS_BECOME_APPROVER',
    defaultMessage: 'Become an Approver'
  },
  ORDER_APPROVALS_BECOME_REQUESTER: {
    id: 'ORDER_APPROVALS_BECOME_REQUESTER',
    defaultMessage: 'Become a Requester'
  },
  PURCHASE: {
    id: 'PURCHASE',
    defaultMessage: 'Purchase'
  },
  BUY_CARD: {
    id: 'BUY_CARD',
    defaultMessage: 'Buy Card'
  },
  REFILL_CARD: {
    id: 'REFILL_CARD',
    defaultMessage: 'Refill Account'
  },
  RAPIDRED_NUMBER: {
    id: 'RAPIDRED_NUMBER',
    defaultMessage: 'RapidRed™ Payment Account Number'
  },
  MPOWER_NUMBER: {
    id: 'MPOWER_NUMBER',
    defaultMessage: 'MPower Payment Account Number'
  },
  BLANKETPO_NUMBER: {
    id: 'BLANKETPO_NUMBER',
    defaultMessage: 'Blanket PO Number'
  },
  PAYMENT_METHOD_FORM_TITLE: {
    id: 'PAYMENT_METHOD_FORM_TITLE',
    defaultMessage: 'Enter your card number and 4 digit PIN.'
  },
  VALID_UNTIL: {
    id: 'VALID_UNTIL',
    defaultMessage: 'valid until'
  },
  MPOWER_CONTRACT_NUMBER_IS_INVALID: {
    id: 'MPOWER_CONTRACT_NUMBER_IS_INVALID',
    defaultMessage: 'The MPower Payment Account number is invalid'
  },
  RAPIDRED_CONTRACT_NUMBER_IS_INVALID: {
    id: 'RAPIDRED_CONTRACT_NUMBER_IS_INVALID',
    defaultMessage: 'The RapidRed™ Payment Account number is invalid'
  },
  BLANKETPO_SOLD_TO_NOT_MAPPED_WITH_CONTRACT_NUMBER: {
    id: 'BLANKETPO_SOLD_TO_NOT_MAPPED_WITH_CONTRACT_NUMBER',
    defaultMessage: 'Entered Blanket PO number is not mapped to your sold to number'
  },
  MPOWER_SOLD_TO_NOT_MAPPED_WITH_CONTRACT_NUMBER: {
    id: 'MPOWER_SOLD_TO_NOT_MAPPED_WITH_CONTRACT_NUMBER',
    defaultMessage: 'Entered MPower Payment Account number is not mapped to your sold to number'
  },
  RAPIDRED_SOLD_TO_NOT_MAPPED_WITH_CONTRACT_NUMBER: {
    id: 'RAPIDRED_SOLD_TO_NOT_MAPPED_WITH_CONTRACT_NUMBER',
    defaultMessage: 'Entered RapidRed™ Payment Account number is not mapped to your sold to number'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  INVALID_PIN_NUMBER: {
    id: 'INVALID_PIN_NUMBER',
    defaultMessage: 'PIN number is invalid'
  },
  AMOUNT_INCLUDE_VAT: {
    id: 'AMOUNT_INCLUDE_VAT',
    defaultMessage: 'Amount Include VAT'
  },
  CANCEL_AND_RETURN: {
    id: 'CANCEL_AND_RETURN',
    defaultMessage: 'Cancel & Return To {thirdParty}'
  },
  BROWSE: {
    id: 'BROWSE',
    defaultMessage: 'Browse'
  },
  DROP_FILES_OR: {
    id: 'DROP_FILES_OR',
    defaultMessage: 'Drop files, or'
  },
  DROP_FILE_OR: {
    id: 'DROP_FILE_OR',
    defaultMessage: 'Drop a file, or'
  },
  ENTER_SKU_PLACEHOLDER: {
    id: 'ENTER_SKU_PLACEHOLDER',
    defaultMessage: 'Enter Product SKU'
  },
  PRODUCT_NUMBER_PACK_SIZE: {
    id: 'PRODUCT_NUMBER_PACK_SIZE',
    defaultMessage: 'Product Number-Pack Size'
  },
  DROP_CSV_XLS_FILE_OR: {
    id: 'DROP_CSV_XLS_FILE_OR',
    defaultMessage: 'Drag and drop your CSV or XLS file to upload or'
  },
  CHOOSE_FILE: {
    id: 'CHOOSE_FILE',
    defaultMessage: 'Choose File'
  },
  MULTI_PRODUCT_INVALID_INPUT: {
    id: 'MULTI_PRODUCT_INVALID_INPUT',
    defaultMessage: 'Error(s) found in product input:'
  },
  MULTI_PRODUCT_ERROR_BAD_QTY: {
    id: 'MULTI_PRODUCT_ERROR_BAD_QTY',
    defaultMessage: 'Invalid row: "{value}"; quantity value must be numeric'
  },
  MULTI_PRODUCT_ERROR_NO_SKU: {
    id: 'MULTI_PRODUCT_ERROR_NO_SKU',
    defaultMessage: 'Invalid row: "{value}"; first value must contain a product SKU'
  },
  MULTI_PRODUCT_ERROR_NUM_COLUMNS: {
    id: 'MULTI_PRODUCT_ERROR_NUM_COLUMNS',
    defaultMessage: 'Invalid row: "{value}"; must be one or two values per row, separated by a comma or tab'
  },
  MULTI_PRODUCT_ENTRY_EXAMPLE: {
    id: 'MULTI_PRODUCT_ENTRY_EXAMPLE',
    defaultMessage: 'Example:\nT1503-100G, 1\nA7000-25G-A, 10\nW4502-1L, 1'
  },
  ITEMS_ADDED_TO_CART: {
    id: 'ITEMS_ADDED_TO_CART',
    defaultMessage: 'item(s) added to cart'
  },
  ADDED_TO_CART: {
    id: 'ADDED_TO_CART',
    defaultMessage: 'added to your cart'
  },
  GENERIC_ERROR: {
    id: 'GENERIC_ERROR',
    defaultMessage: 'Sorry, an unexpected error has occurred'
  },
  GENERIC_ERROR_PIPELINE_CREATION: {
    id: 'GENERIC_ERROR_PIPELINE_CREATION',
    defaultMessage: 'The data entered does not match our records. Please check and try again. If issue persists, please contact your administrator who provided you the data.'
  },
  PAGE_ERROR: {
    id: 'PAGE_ERROR',
    defaultMessage: 'Unexpected error has occurred.'
  },
  TAKE_ME_BACK: {
    id: 'TAKE_ME_BACK',
    defaultMessage: 'Take Me Back'
  },
  ESTIMATED_TO_SHIP_ON: {
    id: 'ESTIMATED_TO_SHIP_ON',
    defaultMessage: 'Estimated to ship on'
  },
  SHIPS_ON_FROM: {
    id: 'SHIPS_ON_FROM',
    defaultMessage: 'Ships on'
  },
  PRICE_DROP: {
    id: 'PRICE_DROP',
    defaultMessage: 'New, lower price on this item!'
  },
  ESTIMATED_TO_SHIP_ON_DYNAMIC: {
    id: 'ESTIMATED_TO_SHIP_ON_DYNAMIC',
    defaultMessage: '{quantity}Estimated to ship on {date} from {plantLoc}'
  },
  ESTIMATED_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC: {
    id: 'ESTIMATED_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC',
    defaultMessage: '{quantity}Estimated to ship on {date}'
  },
  AVAILABLE_TO_SHIP_ON: {
    id: 'AVAILABLE_TO_SHIP_ON',
    defaultMessage: 'Available to ship on'
  },
  AVAILABLE_TO_SHIP_ON_DYNAMIC: {
    id: 'AVAILABLE_TO_SHIP_ON_DYNAMIC',
    defaultMessage: '{quantity}Available to ship on {date} from {plantLoc}'
  },
  AVAILABLE_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC: {
    id: 'AVAILABLE_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC',
    defaultMessage: '{quantity}Available to ship on {date}'
  },
  ESTIMATED_DELIVERY_ON_DYNAMIC: {
    id: 'ESTIMATED_DELIVERY_ON_DYNAMIC:',
    defaultMessage: '{quantity}Estimated Delivery on {date} from {plantLoc}'
  },
  ESTIMATED_DELIVERY_ON: {
    id: 'ESTIMATED_DELIVERY_ON',
    defaultMessage: '{quantity}Estimated Delivery on {date}'
  },
  AVAILABILITY_ESTIMATED_DELIVERY_ON: {
    id: 'AVAILABILITY_ESTIMATED_DELIVERY_ON',
    defaultMessage: 'Estimated Delivery on'
  },
  SHIPPING_DATE_NOT_AVAILABLE: {
    id: 'SHIPPING_DATE_NOT_AVAILABLE',
    defaultMessage: '{quantity}Shipping Date not available'
  },
  LOGOUT: {
    id: 'LOGOUT',
    defaultMessage: 'Logout'
  },
  YOUR_QUOTES: {
    id: 'YOUR_QUOTES',
    defaultMessage: 'Quotes'
  },
  YOUR_ORDERS: {
    id: 'YOUR_ORDERS',
    defaultMessage: 'Orders'
  },
  YOUR_PROFILE: {
    id: 'YOUR_PROFILE',
    defaultMessage: 'Profile'
  },
  SERVICES: {
    id: 'SERVICES',
    defaultMessage: 'Services'
  },
  ACCOUNT: {
    id: 'ACCOUNT',
    defaultMessage: 'Account'
  },
  ACCOUNTS: {
    id: 'ACCOUNTS',
    defaultMessage: 'Accounts'
  },
  SEE_ALL_BUYING_OPTIONS: {
    id: 'SEE_ALL_BUYING_OPTIONS',
    defaultMessage: 'See All Buying Options'
  },
  SEE_ALL_INDUSTRIES: {
    id: 'SEE_ALL_INDUSTRIES',
    defaultMessage: 'See All Industries'
  },
  SEE_ALL_VETEC: {
    id: 'SEE_ALL_VETEC',
    defaultMessage: 'See All Vetec'
  },
  SEE_ALL_MATERIALS_SCIENCE: {
    id: 'SEE_ALL_MATERIALS_SCIENCE',
    defaultMessage: 'See All Materials Science'
  },
  SEE_ALL_LIFE_SCIENCE: {
    id: 'SEE_ALL_LIFE_SCIENCE',
    defaultMessage: 'See All Life Science'
  },
  SEE_ALL_CHEMISTRY: {
    id: 'SEE_ALL_CHEMISTRY',
    defaultMessage: 'See All Chemistry'
  },
  SEE_ALL_ANALYTICAL_CHROMATOGRAPHY: {
    id: 'SEE_ALL_ANALYTICAL_CHROMATOGRAPHY',
    defaultMessage: 'See All Analytical Chromatography'
  },
  SEE_ALL_PRODUCTS: {
    id: 'SEE_ALL_PRODUCTS',
    defaultMessage: 'See All Products'
  },
  WESTERN_BLOT: {
    id: 'WESTERN_BLOT',
    defaultMessage: 'Western Blot'
  },
  POLYMER_SCIENCE: {
    id: 'POLYMER_SCIENCE',
    defaultMessage: 'Polymer Science'
  },
  NANOMATERIALS: {
    id: 'NANOMATERIALS',
    defaultMessage: 'Nanomaterials'
  },
  WESTERN_BLOTTING: {
    id: 'WESTERN_BLOTTING',
    defaultMessage: 'Western Blotting'
  },
  TRANSLATIONAL_RESEARCH_SOLUTIONS: {
    id: 'TRANSLATIONAL_RESEARCH_SOLUTIONS',
    defaultMessage: 'Translational Research Solutions'
  },
  TRANSFECTION: {
    id: 'TRANSFECTION',
    defaultMessage: 'Transfection'
  },
  STEM_CELL_BIOLOGY: {
    id: 'STEM_CELL_BIOLOGY',
    defaultMessage: 'Stem Cell Biology'
  },
  ROCHE_PRODUCTS: {
    id: 'ROCHE_PRODUCTS',
    defaultMessage: 'Roche Products'
  },
  ROCHE_BIOCHEMICAL_REAGENTS: {
    id: 'ROCHE_BIOCHEMICAL_REAGENTS',
    defaultMessage: 'Roche Biochemical Reagents'
  },
  PROTEOMICS: {
    id: 'PROTEOMICS',
    defaultMessage: 'Proteomics'
  },
  PROTEIN_EXTRACTION: {
    id: 'PROTEIN_EXTRACTION',
    defaultMessage: 'Protein Extraction'
  },
  PROTEASE_INHIBITORS: {
    id: 'PROTEASE_INHIBITORS',
    defaultMessage: 'Protease Inhibitors'
  },
  MOLECULAR_BIOLOGY: {
    id: 'MOLECULAR_BIOLOGY',
    defaultMessage: 'Molecular Biology'
  },
  MOLECULAR_BIOLOGY_FUNCTIONAL_GENOMICS: {
    id: 'MOLECULAR_BIOLOGY_FUNCTIONAL_GENOMICS',
    defaultMessage: 'Molecular Biology & Functional Genomics'
  },
  METABOLOMICS: {
    id: 'METABOLOMICS',
    defaultMessage: 'Metabolomics'
  },
  FUNCTIONAL_GENOMICS_RNAI: {
    id: 'FUNCTIONAL_GENOMICS_RNAI',
    defaultMessage: 'Functional Genomics & RNAi'
  },
  ELISA_KIT: {
    id: 'ELISA_KIT',
    defaultMessage: 'Elisa Kit'
  },
  DUOLINK_AND_PLA_TECHNOLOGY: {
    id: 'DUOLINK_AND_PLA_TECHNOLOGY',
    defaultMessage: 'Duolink & PLA Technology'
  },
  DNA_DAMAGE_AND_REPAIR: {
    id: 'DNA_DAMAGE_AND_REPAIR',
    defaultMessage: 'DNA Damage & Repair'
  },
  CUSTOM_OLIGOS: {
    id: 'CUSTOM_OLIGOS',
    defaultMessage: 'Custom Oligos'
  },
  CRISPR_CAS_9: {
    id: 'CRISPR_CAS_9',
    defaultMessage: 'CRISPR CAS 9'
  },
  CORE_BIOREAGENTS: {
    id: 'CORE_BIOREAGENTS',
    defaultMessage: 'Core Bioreagents'
  },
  CELL_CULTURE: {
    id: 'CELL_CULTURE',
    defaultMessage: 'Cell Culture'
  },
  CELL_BIOLOGY: {
    id: 'CELL_BIOLOGY',
    defaultMessage: 'Cell Biology'
  },
  CELL_ANALYSIS: {
    id: 'CELL_ANALYSIS',
    defaultMessage: 'Cell Analysis'
  },
  BIOCHEMICALS: {
    id: 'BIOCHEMICALS',
    defaultMessage: 'Biochemicals'
  },
  ANTIBODIES: {
    id: 'ANTIBODIES',
    defaultMessage: 'Antibodies'
  },
  ANTIBIOTICS: {
    id: 'ANTIBIOTICS',
    defaultMessage: 'Antibiotics'
  },
  '3D_CELL_CULTURE': {
    id: '3D_CELL_CULTURE',
    defaultMessage: '3D Cell Culture'
  },
  THREE_D_CELL_CULTURE: {
    id: 'THREE_D_CELL_CULTURE',
    defaultMessage: '3D Cell Culture'
  },
  STOCKROOM_REAGENTS: {
    id: 'STOCKROOM_REAGENTS',
    defaultMessage: 'Stockroom Reagents'
  },
  STABLE_ISOTOPES: {
    id: 'STABLE_ISOTOPES',
    defaultMessage: 'Stable Isotopes'
  },
  SOLVENTS: {
    id: 'SOLVENTS',
    defaultMessage: 'Solvents'
  },
  CHEMICAL_SYNTHESIS: {
    id: 'CHEMICAL_SYNTHESIS',
    defaultMessage: 'Chemical Synthesis'
  },
  TITRATION: {
    id: 'TITRATION',
    defaultMessage: 'Titration'
  },
  TITRIPAC: {
    id: 'TITRIPAC',
    defaultMessage: 'This product features our Titripac® packaging technology to reduce the risk of contamination.'
  },
  TITRIPAC_RFID: {
    id: 'TITRIPAC_RFID',
    defaultMessage: 'This product is equipped with an integrated RFID tag in the label for seamless data transfer to the titration instrument.'
  },
  TITRIPAC_RFID_EMBED: {
    id: 'TITRIPAC_RFID_EMBED',
    defaultMessage: 'This product features our Titripac® packaging technology to reduce the risk of contamination during use. It also includes an RFID tag embedded in the label for seamless data transfer to the titration instrument.'
  },
  TITRIPAC_RFID_3S: {
    id: 'TITRIPAC_RFID_3S:',
    defaultMessage: 'This products is equipped with our new 3S adapter technology. An RFID tag is embedded into a 3S Cap, enabling seamless data transfer to the titration instrument.'
  },
  SAMPLE_PREPARATION: {
    id: 'SAMPLE_PREPARATION',
    defaultMessage: 'Sample Preparation'
  },
  SAMPLE_COA: {
    id: 'SAMPLE_COA',
    defaultMessage: 'Sample COA'
  },
  GAS_CHROMATOGRAPHY: {
    id: 'GAS_CHROMATOGRAPHY',
    defaultMessage: 'Gas Chromatography'
  },
  FOOD_ANALYSIS: {
    id: 'FOOD_ANALYSIS',
    defaultMessage: 'Food Analysis'
  },
  ANALYTICAL_STANDARDS: {
    id: 'ANALYTICAL_STANDARDS',
    defaultMessage: 'Analytical Standards'
  },
  ANALYTICAL_REAGENTS: {
    id: 'ANALYTICAL_REAGENTS',
    defaultMessage: 'Analytical Reagents'
  },
  VETEC: {
    id: 'VETEC',
    defaultMessage: 'Vetec'
  },
  MATERIALS_SCIENCE: {
    id: 'MATERIALS_SCIENCE',
    defaultMessage: 'Materials Science'
  },
  MICROBIOLOGY: {
    id: 'MICROBIOLOGY',
    defaultMessage: 'Microbiology'
  },
  LIFE_SCIENCE: {
    id: 'LIFE_SCIENCE',
    defaultMessage: 'Life Science'
  },
  LABWARE: {
    id: 'LABWARE',
    defaultMessage: 'Labware'
  },
  CHEMISTRY: {
    id: 'CHEMISTRY',
    defaultMessage: 'Chemistry'
  },
  ANALYTICAL_CHROMATOGRAPHY: {
    id: 'ANALYTICAL_CHROMATOGRAPHY',
    defaultMessage: 'Analytical Chromatography'
  },
  PHARMACEUTICAL: {
    id: 'PHARMACEUTICAL',
    defaultMessage: 'Pharmaceutical'
  },
  FOOD_AND_BEVERAGE: {
    id: 'FOOD_AND_BEVERAGE',
    defaultMessage: 'Food & Beverage'
  },
  CLINICAL_TESTING: {
    id: 'CLINICAL_TESTING',
    defaultMessage: 'Clinical Testing'
  },
  CHEMICAL_MANUFACTURING: {
    id: 'CHEMICAL_MANUFACTURING',
    defaultMessage: 'Chemical Manufacturing'
  },
  AGRICULTURE: {
    id: 'AGRICULTURE',
    defaultMessage: 'Agriculture'
  },
  ACADEMIC_RESEARCH: {
    id: 'ACADEMIC_RESEARCH',
    defaultMessage: 'Academic Research'
  },
  INDUSTRIES: {
    id: 'INDUSTRIES',
    defaultMessage: 'Industries'
  },
  PRODUCTS: {
    id: 'PRODUCTS',
    defaultMessage: 'Products'
  },
  BUILDING_BLOCKS_EXPLORER: {
    id: 'BUILDING_BLOCKS_EXPLORER',
    defaultMessage: 'Building Blocks Explorer'
  },
  HEADER_SEARCH_PLACEHOLDER: {
    id: 'HEADER_SEARCH_PLACEHOLDER',
    defaultMessage: 'Type in Product Names, Product Numbers, or CAS Numbers to see suggestions.'
  },
  WEB_TOOLBOX: {
    id: 'WEB_TOOLBOX',
    defaultMessage: 'Web Toolbox'
  },
  CONTACT_US: {
    id: 'CONTACT_US',
    defaultMessage: 'Contact Us'
  },
  CONTACT_US_FOR_QUOTE: {
    id: 'CONTACT_US_FOR_QUOTE',
    defaultMessage: 'contact us for a quote'
  },
  SDS: {
    id: 'SDS',
    defaultMessage: 'SDS'
  },
  COA: {
    id: 'COA',
    defaultMessage: 'COA'
  },
  COO: {
    id: 'COO',
    defaultMessage: 'COO'
  },
  SAFETY_DATA_SHEET_TITLE: {
    id: 'SAFETY_DATA_SHEET_TITLE',
    defaultMessage: 'SAFETY DATA SHEET (SDS)'
  },
  NEW_PRODUCT: {
    id: 'NEW_PRODUCT',
    defaultMessage: 'New Product'
  },
  PROMOTION: {
    id: 'PROMOTION',
    defaultMessage: 'Promotion'
  },
  LINEAR_FORMULA: {
    id: 'LINEAR_FORMULA',
    defaultMessage: 'Linear Formula'
  },
  BEILSTEIN_REGISTRY_NUMBER: {
    id: 'BEILSTEIN_REGISTRY_NUMBER',
    defaultMessage: 'Beilstein Registry Number'
  },
  BEILSTEIN_NO: {
    id: 'BEILSTEIN_NO',
    defaultMessage: 'Beilstein No.'
  },
  EC_NO: {
    id: 'EC_NO',
    defaultMessage: 'EC No.'
  },
  SYNONYMS: {
    id: 'SYNONYMS',
    defaultMessage: 'Synonyms'
  },
  SEARCH_RESULTS: {
    id: 'SEARCH_RESULTS',
    defaultMessage: 'Search Results'
  },
  BACK: {
    id: 'BACK',
    defaultMessage: 'Back'
  },
  PAGINATION_OPTION_DISPLAY: {
    id: 'PAGINATION_OPTION_DISPLAY',
    defaultMessage: 'Page {page} of {numberOfPages}'
  },
  PAGINATION_LABEL_NAVIGATION: {
    id: 'PAGINATION_LABEL_NAVIGATION',
    defaultMessage: 'Pagination Navigation'
  },
  PAGINATION_LABEL_PREVIOUS: {
    id: 'PAGINATION_LABEL_PREVIOUS',
    defaultMessage: 'Go to previous page'
  },
  PAGINATION_LABEL_NEXT: {
    id: 'PAGINATION_LABEL_NEXT',
    defaultMessage: 'Go to next page'
  },
  HOME: {
    id: 'HOME',
    defaultMessage: 'Home'
  },
  SEE_FEWER: {
    id: 'SEE_FEWER',
    defaultMessage: 'See Fewer'
  },
  SEE_ALL: {
    id: 'SEE_ALL',
    defaultMessage: 'See All'
  },
  PRICING: {
    id: 'PRICING',
    defaultMessage: 'Pricing'
  },
  VIEW_FULL_DETAILS: {
    id: 'VIEW_FULL_DETAILS',
    defaultMessage: 'View Full Details'
  },
  CLEAR_ALL: {
    id: 'CLEAR_ALL',
    defaultMessage: 'Clear All'
  },
  ATTRIBUTE_SELECTOR_INSTRUCTION: {
    id: 'ATTRIBUTE_SELECTOR_INSTRUCTION',
    defaultMessage: ' Use the product attributes below to configure the comparison table. (Select up to {count} total.)'
  },
  PRODUCT_COMPARISON_GUIDE: {
    id: 'PRODUCT_COMPARISON_GUIDE',
    defaultMessage: 'Product Comparison Guide'
  },
  DID_YOU_MEAN: {
    id: 'DID_YOU_MEAN',
    defaultMessage: 'Did you mean'
  },
  MATCHES_FOUND: {
    id: 'MATCHES_FOUND',
    defaultMessage: 'matches found'
  },
  MATCHES_FOUND_FOR: {
    id: 'MATCHES_FOUND_FOR',
    defaultMessage: 'matches found for'
  },
  BRAND: {
    id: 'BRAND',
    defaultMessage: 'Brand'
  },
  APPLICATION: {
    id: 'APPLICATION',
    defaultMessage: 'Application'
  },
  COMPARE: {
    id: 'COMPARE',
    defaultMessage: 'Compare'
  },
  PRODUCT_COMPARE_TITLE: {
    id: 'PRODUCT_COMPARE_TITLE',
    defaultMessage: 'Product Compare'
  },
  USE_ADVANCED_SEARCH: {
    id: 'USE_ADVANCED_SEARCH',
    defaultMessage: 'Use Advanced Search'
  },
  SELECT_PRODUCT_NUMBER_AND_ENTER_LOT_NUMBER: {
    id: 'SELECT_PRODUCT_NUMBER_AND_ENTER_LOT_NUMBER',
    defaultMessage: 'Select a Product Number and enter a Lot Number to search for'
  },
  READ_ABSTRACT: {
    id: 'READ_ABSTRACT',
    defaultMessage: 'Read Abstract'
  },
  SUMMARY: {
    id: 'SUMMARY',
    defaultMessage: 'Summary'
  },
  SUBTOTAL: {
    id: 'SUBTOTAL',
    defaultMessage: 'Subtotal'
  },
  ITEM_PRICE: {
    id: 'ITEM_PRICE',
    defaultMessage: 'Item Price'
  },
  TOTAL_PRICE: {
    id: 'TOTAL_PRICE',
    defaultMessage: 'Total Price'
  },
  HANDLING: {
    id: 'HANDLING',
    defaultMessage: 'Handling'
  },
  LINE: {
    id: 'LINE',
    defaultMessage: 'Line'
  },
  TAXES: {
    id: 'TAXES',
    defaultMessage: 'Taxes'
  },
  KEYWORD: {
    id: 'KEYWORD',
    defaultMessage: 'Keyword'
  },
  SHOW: {
    id: 'SHOW',
    defaultMessage: 'Show'
  },
  SHOW_MORE: {
    id: 'SHOW_MORE',
    defaultMessage: 'Show More'
  },
  SHOW_MORE_NUMBER: {
    id: 'SHOW_MORE_NUMBER',
    defaultMessage: 'Show More ({number})'
  },
  SHOW_LESS: {
    id: 'SHOW_LESS',
    defaultMessage: 'Show Less'
  },
  HIDE_DETAILS: {
    id: 'HIDE_DETAILS',
    defaultMessage: 'Hide Details'
  },
  CANCEL: {
    id: 'CANCEL',
    defaultMessage: 'Cancel'
  },
  HOME_PAGE: {
    id: 'HOME_PAGE',
    defaultMessage: 'Home Page'
  },
  SUBMIT: {
    id: 'SUBMIT',
    defaultMessage: 'Submit'
  },
  CONFIRM_PASSWORD: {
    id: 'CONFIRM_PASSWORD',
    defaultMessage: 'Confirm Password'
  },
  PASSWORD: {
    id: 'PASSWORD',
    defaultMessage: 'Password'
  },
  PASSWORD_LOGIN: {
    id: 'PASSWORD_LOGIN',
    defaultMessage: 'Password'
  },
  MOBILE: {
    id: 'MOBILE',
    defaultMessage: 'Mobile'
  },
  CHANGE_MOBILE: {
    id: 'CHANGE_MOBILE',
    defaultMessage: 'Change Mobile'
  },
  MOBILE_INFO: {
    id: 'MOBILE_INFO',
    defaultMessage: 'Enter your mobile to receive your sign in security code.'
  },
  MOBILE_SECURITY_CODE: {
    id: 'MOBILE_SECURITY_CODE',
    defaultMessage: '6-Digit Security Code'
  },
  THIRD_PARTY_NOTICE: {
    id: 'THIRD_PARTY_NOTICE',
    defaultMessage: 'Third Party Notice'
  },
  THIRD_PARTY_LOGIN_NOTICE: {
    id: 'THIRD_PARTY_LOGIN_NOTICE',
    defaultMessage: 'By logging in you agree to share user profile and shopping cart information'
  },
  THIRD_PARTY_DATA_SHARING: {
    id: 'THIRD_PARTY_DATA_SHARING',
    defaultMessage: 'By clicking Accept & Continue, you agree to share user profile and shopping cart information.'
  },
  ACCEPT_CONTINUE: {
    id: 'ACCEPT_CONTINUE',
    defaultMessage: 'Accept & Continue'
  },
  WECHAT: {
    id: 'WECHAT',
    defaultMessage: 'WeChat'
  },
  WECHAT_LINK_ACCOUNT: {
    id: 'WECHAT_LINK_ACCOUNT',
    defaultMessage: 'Link Account'
  },
  WECHAT_LINK_MERCK_ACCOUNT_DESC: {
    id: 'WECHAT_LINK_MERCK_ACCOUNT_DESC',
    defaultMessage: 'To link your WeChat account with your Merck profile, please enter your email address and password.'
  },
  WECHAT_LINK_DO_NOT_HAVE_ACCOUNT: {
    id: 'WECHAT_LINK_DO_NOT_HAVE_ACCOUNT',
    defaultMessage: "Don't have a Merck account?"
  },
  WECHAT_LINK_REGISTER_A_NEW_PROFILE: {
    id: 'WECHAT_LINK_REGISTER_A_NEW_PROFILE',
    defaultMessage: 'Register a new profile'
  },
  WECHAT_LINK_WECHAT_ALREADY_BOUND: {
    id: 'WECHAT_LINK_WECHAT_ALREADY_BOUND',
    defaultMessage: 'WeChat is already bound.'
  },
  WECHAT_LINK_ACCOUNT_ALREADY_BOUND: {
    id: 'WECHAT_LINK_ACCOUNT_ALREADY_BOUND',
    defaultMessage: 'Merck account is already bound.'
  },
  WECHAT_LINK: {
    id: 'WECHAT_LINK',
    defaultMessage: 'Link'
  },
  PASSWORD_RULES: {
    id: 'PASSWORD_RULES',
    defaultMessage: 'Your password must include: at least 6 characters and include a number and letter'
  },
  PASSWORD_RULES_TITLE: {
    id: 'PASSWORD_RULES_TITLE',
    defaultMessage: 'Password Requirements'
  },
  PASSWORD_RULES_PATTERN_COUNT: {
    id: 'PASSWORD_RULES_PATTERN_COUNT',
    defaultMessage: 'Must include {patternLength} of the following requirements:'
  },
  PASSWORD_HAS_UPPER_CHAR: {
    id: 'PASSWORD_HAS_UPPER_CHAR',
    defaultMessage: 'Has uppercase letter'
  },
  PASSWORD_HAS_LOWER_CHAR: {
    id: 'PASSWORD_HAS_LOWER_CHAR',
    defaultMessage: 'Has lowercase letter'
  },
  PASSWORD_HAS_A_NUMBER: {
    id: 'PASSWORD_HAS_A_NUMBER',
    defaultMessage: 'Has a number'
  },
  PASSWORD_HAS_A_SPECIAL_CHAR: {
    id: 'PASSWORD_HAS_A_SPECIAL_CHAR',
    defaultMessage: 'Has a special character'
  },
  PASSWORD_RULES_CHAR_COUNT: {
    id: 'PASSWORD_RULES_CHAR_COUNT',
    defaultMessage: 'Must include at least 8 characters'
  },
  PASSWORD_RULES_NUMBER: {
    id: 'PASSWORD_RULES_NUMBER',
    defaultMessage: 'Must include a number'
  },
  PASSWORD_RULES_LETTER: {
    id: 'PASSWORD_RULES_LETTER',
    defaultMessage: 'Must include a letter'
  },
  PASSWORD_RULES_SPEC_CHAR: {
    id: 'PASSWORD_RULES_SPEC_CHAR',
    defaultMessage: 'Must include a special character'
  },
  CHOOSE_A_PASSWORD: {
    id: 'CHOOSE_A_PASSWORD',
    defaultMessage: 'Choose a Password'
  },
  PASSWORD_EXPIRED_MESSAGE: {
    id: 'PASSWORD_EXPIRED_MESSAGE',
    defaultMessage: 'Please Enter a New Password.'
  },
  PASSWORD_EXPIRED: {
    id: 'PASSWORD_EXPIRED',
    defaultMessage: 'Your Password has Expired.'
  },
  TOKEN_EXPIRED_EMAIL: {
    id: 'TOKEN_EXPIRED_EMAIL',
    defaultMessage: 'Your opportunity to verify your email address has expired.'
  },
  REQ_VERIFICATION_EMAIL: {
    id: 'REQ_VERIFICATION_EMAIL',
    defaultMessage: 'Click here to request a new verification email.'
  },
  REQ_PASSWORD_EXPIRY_BEGINING: {
    id: 'REQ_PASSWORD_EXPIRY_BEGINING',
    defaultMessage: 'The link you requested has expired.  Please go to the\n       {login} page and select Forgot Password? to request an updated Password Reset'
  },
  RESET_PASSWORD_REQUEST_HELP_MSG: {
    id: 'RESET_PASSWORD_REQUEST_HELP_MSG',
    defaultMessage: "Enter the password and confirm password and 'Submit' "
  },
  RESET_PASSWORD_REQUEST: {
    id: 'RESET_PASSWORD_REQUEST',
    defaultMessage: 'Reset Password Request'
  },
  PRE_REGISTERED_EMAIL: {
    id: 'PRE_REGISTERED_EMAIL',
    defaultMessage: 'Pre Registred Email'
  },
  CREATE_PROFILE: {
    id: 'CREATE_PROFILE',
    defaultMessage: 'Create Profile'
  },
  REGISTRATION_INFO_NOT_COLLECTED: {
    id: 'REGISTRATION_INFO_NOT_COLLECTED',
    defaultMessage: 'This information will not be collected for promotional purposes. For more, read our'
  },
  REGISTRATION_EMAIL_CONSENT: {
    id: 'REGISTRATION_EMAIL_CONSENT',
    defaultMessage: 'I agree that Merck KGaA, Darmstadt, Germany and its affiliates can use my data for direct marketing, including but not limited to email, phone and text to inform me about products, services and other offers in relation to life science products.'
  },
  REGISTRATION_EMAIL_CONSENT_JAPAN: {
    id: 'REGISTRATION_EMAIL_CONSENT_JAPAN',
    defaultMessage: 'I agree that Merck Ltd., Sigma-Aldrich Japan G.K. and its affiliates can contact me (including but not limited to email, phone, and text) to provide marketing information about their products, services, and other offers.'
  },
  REGISTRATION_INFO_PRIVACY_JAPAN: {
    id: 'REGISTRATION_INFO_PRIVACY_JAPAN',
    defaultMessage: 'You can withdraw your consent anytime by clicking the unsubscribe link contained in every email.'
  },
  REGISTRATION_INFO_PRIVACY: {
    id: 'REGISTRATION_INFO_PRIVACY',
    defaultMessage: 'You can withdraw your consent anytime by clicking the unsubscribe link contained in every email or by sending an email to {mailTo}. Detailed information is available in our'
  },
  PRIVACY_STATEMENT: {
    id: 'PRIVACY_STATEMENT',
    defaultMessage: 'Privacy Statement'
  },
  REGISTER_PERSONAL_DETAILS_TITLE: {
    id: 'REGISTER_PERSONAL_DETAILS_TITLE',
    defaultMessage: 'Personal Details'
  },
  REGISTER_TERMS_AND_CONDITIONS_TITLE: {
    id: 'REGISTER_TERMS_AND_CONDITIONS_TITLE',
    defaultMessage: 'Terms & Conditions'
  },
  OF: {
    id: 'OF',
    defaultMessage: 'of'
  },
  RESULTS: {
    id: 'RESULTS',
    defaultMessage: 'results'
  },
  SHOWING: {
    id: 'SHOWING',
    defaultMessage: 'Showing'
  },
  TERMS_AND_CONDITIONS: {
    id: 'TERMS_AND_CONDITIONS',
    defaultMessage: 'Terms & Conditions'
  },
  I_AGREE_LIKE: {
    id: 'I_AGREE_LIKE',
    defaultMessage: 'I agree like'
  },
  I_AGREE_TO_THE: {
    id: 'I_AGREE_TO_THE',
    defaultMessage: 'I agree to the'
  },
  I_AGREE_TO_THE_CROSS_DATA: {
    id: 'I_AGREE_TO_THE_CROSS_DATA',
    defaultMessage: 'I agree to cross-border transfers of my personal information as described in the'
  },
  CROSS_DATA_TRANSFERS_AS_DESCRIBED_CN: {
    id: 'CROSS_DATA_TRANSFERS_AS_DESCRIBED_CN',
    defaultMessage: 'cross-border transfers of my personal information as described'
  },
  LANGUAGE: {
    id: 'LANGUAGE',
    defaultMessage: 'Language'
  },
  COUNTRY: {
    id: 'COUNTRY',
    defaultMessage: 'Location'
  },
  PHONE_NUMBER: {
    id: 'PHONE_NUMBER',
    defaultMessage: 'Phone Number'
  },
  EMAIL: {
    id: 'EMAIL',
    defaultMessage: 'Email'
  },
  EMAIL_MAILTO: {
    id: 'EMAIL_MAILTO',
    defaultMessage: 'Email: {mailTo}'
  },
  RESEND_EMAIL: {
    id: 'RESEND_EMAIL',
    defaultMessage: 'Resend Email'
  },
  LAST_NAME: {
    id: 'LAST_NAME',
    defaultMessage: 'Last Name'
  },
  FIRST_NAME: {
    id: 'FIRST_NAME',
    defaultMessage: 'First Name'
  },
  REGISTRATION_WELCOME_MESSAGE: {
    id: 'REGISTRATION_WELCOME_MESSAGE',
    defaultMessage: 'Welcome. Creating an online profile will allow you personalize your shipping and billing information and provides you prices that are specific to you and the organization you work in. Do you already have an account?'
  },
  CREATE_YOUR_PROFILE: {
    id: 'CREATE_YOUR_PROFILE',
    defaultMessage: 'Create Your Profile'
  },
  SAVE: {
    id: 'SAVE',
    defaultMessage: 'Save'
  },
  GENERAL: {
    id: 'GENERAL',
    defaultMessage: 'General'
  },
  POSTAL_CODE: {
    id: 'POSTAL_CODE',
    defaultMessage: 'Postal Code'
  },
  STATE: {
    id: 'STATE',
    defaultMessage: 'State'
  },
  CITY: {
    id: 'CITY',
    defaultMessage: 'City'
  },
  STREET: {
    id: 'STREET',
    defaultMessage: 'Street'
  },
  BUILDING_ROOM: {
    id: 'BUILDING_ROOM',
    defaultMessage: 'Building / Room'
  },
  DEPARTMENT: {
    id: 'DEPARTMENT',
    defaultMessage: 'Department'
  },
  PROFILE_SHIPPING_ADDRESS_ATTENTION_TO: {
    id: 'PROFILE_SHIPPING_ADDRESS_ATTENTION_TO',
    defaultMessage: 'Attention To'
  },
  ORGANIZATION: {
    id: 'ORGANIZATION',
    defaultMessage: 'Organization'
  },
  YOUR_PASSWORD: {
    id: 'YOUR_PASSWORD',
    defaultMessage: 'Your Password'
  },
  EDIT: {
    id: 'EDIT',
    defaultMessage: 'Edit'
  },
  APPLY_NOW: {
    id: 'APPLY_NOW',
    defaultMessage: 'Apply Now'
  },
  FAX: {
    id: 'FAX',
    defaultMessage: 'Fax'
  },
  CELL_PHONE: {
    id: 'CELL_PHONE',
    defaultMessage: 'Cell Phone'
  },
  NAME: {
    id: 'NAME',
    defaultMessage: 'Name'
  },
  PERSON_NAME: {
    id: 'PERSON_NAME',
    defaultMessage: 'Name'
  },
  BLANKET_PO_NUMBER: {
    id: 'BLANKET_PO_NUMBER',
    defaultMessage: 'Blanket PO Number'
  },
  PHONE: {
    id: 'PHONE',
    defaultMessage: 'Phone'
  },
  EMAIL_ADDRESS: {
    id: 'EMAIL_ADDRESS',
    defaultMessage: 'Email Address'
  },
  REQUISITION_NUMBER: {
    id: 'REQUISITION_NUMBER',
    defaultMessage: 'Requisition Number'
  },
  RECIPIENT_CONTACT: {
    id: 'RECIPIENT_CONTACT',
    defaultMessage: 'Recipient Contact'
  },
  ENDING_IN: {
    id: 'ENDING_IN',
    defaultMessage: 'ending in'
  },
  CREDIT_CARD_NUMBER: {
    id: 'CREDIT_CARD_NUMBER',
    defaultMessage: 'Credit Card Number'
  },
  EXPIRATION_DATE: {
    id: 'EXPIRATION_DATE',
    defaultMessage: 'Expiration Date'
  },
  PO_BOX: {
    id: 'PO_BOX',
    defaultMessage: 'PO Box'
  },
  PROFILE_MAILING_ADDRESS_ATTENTION_TO: {
    id: 'PROFILE_MAILING_ADDRESS_ATTENTION_TO',
    defaultMessage: 'Attention To'
  },
  BCC_ADDRESS: {
    id: 'BCC_ADDRESS',
    defaultMessage: 'BCC Address'
  },
  SEND_OFFERS_PROMOTIONS: {
    id: 'SEND_OFFERS_PROMOTIONS',
    defaultMessage: 'Please send me offers and promotions.'
  },
  MARKETING_EMAILS: {
    id: 'MARKETING_EMAILS',
    defaultMessage: 'Marketing Emails'
  },
  ORDER_BILLING_ADDRESS: {
    id: 'ORDER_BILLING_ADDRESS',
    defaultMessage: 'Billing Address'
  },
  WELCOME_BACK: {
    id: 'WELCOME_BACK',
    defaultMessage: 'Welcome back'
  },
  WELCOME_TO_MERCK: {
    id: 'WELCOME_TO_MERCK',
    defaultMessage: 'Welcome to Merck'
  },
  WELCOME_AND_THANK_REGISTERING: {
    id: 'WELCOME_AND_THANK_REGISTERING',
    defaultMessage: 'Welcome and thank you for registering with Merck.'
  },
  CONTACT_TECHNICAL_SERVICE: {
    id: 'CONTACT_TECHNICAL_SERVICE',
    defaultMessage: 'Contact Technical Service'
  },
  CONTACT_SELLER_WITH_NAME: {
    id: 'CONTACT_SELLER_WITH_NAME',
    defaultMessage: 'Contact {sellerName}.'
  },
  TECHNICAL_SERVICE_DESC: {
    id: 'TECHNICAL_SERVICE_DESC',
    defaultMessage: 'Our team of scientists has experience in all areas  of research including Life Science, Material Science, Chemical Synthesis, Chromatography, Analytical and many others'
  },
  HAVING_TROUBLE: {
    id: 'HAVING_TROUBLE',
    defaultMessage: 'Having trouble? Feel free to contact '
  },
  ADDITIONAL_EXAMPLES: {
    id: 'ADDITIONAL_EXAMPLES',
    defaultMessage: 'Additional examples:'
  },
  EXAMPLE: {
    id: 'EXAMPLE',
    defaultMessage: 'Example:'
  },
  TECHNICAL_SERVICE: {
    id: 'TECHNICAL_SERVICE',
    defaultMessage: 'Technical Service'
  },
  SAFETY_INFORMATION: {
    id: 'SAFETY_INFORMATION',
    defaultMessage: 'Safety Information'
  },
  SUPPLEMENTARY_HAZARDS: {
    id: 'SUPPLEMENTARY_HAZARDS',
    defaultMessage: 'Supplementary Hazards'
  },
  QUICK_ACCESS: {
    id: 'QUICK_ACCESS',
    defaultMessage: 'Quick Access'
  },
  ALL_PHOTOS: {
    id: 'ALL_PHOTOS',
    defaultMessage: 'All Photos'
  },
  FREQUENTLY_ASKED_QUESTIONS: {
    id: 'FREQUENTLY_ASKED_QUESTIONS',
    defaultMessage: 'Frequently Asked Questions'
  },
  MORE_DOCUMENTS: {
    id: 'MORE_DOCUMENTS',
    defaultMessage: 'More Documents'
  },
  ADDITIONAL_RESOURCES: {
    id: 'ADDITIONAL_RESOURCES',
    defaultMessage: 'Additional Resources'
  },
  CERTIFICATE_OF_ORIGIN: {
    id: 'CERTIFICATE_OF_ORIGIN',
    defaultMessage: 'Certificate of Origin'
  },
  CERTIFICATES_OF_ORIGIN: {
    id: 'CERTIFICATES_OF_ORIGIN',
    defaultMessage: 'Certificates of Origin'
  },
  CERTIFICATE_OF_ORIGIN_COO: {
    id: 'CERTIFICATE_OF_ORIGIN_COO',
    defaultMessage: 'Certificates of Origin (COO)'
  },
  CERTIFICATE_OF_ANALYSIS: {
    id: 'CERTIFICATE_OF_ANALYSIS',
    defaultMessage: 'Certificate of Analysis'
  },
  CERTIFICATES_OF_ANALYSIS: {
    id: 'CERTIFICATES_OF_ANALYSIS',
    defaultMessage: 'Certificates of Analysis'
  },
  CERTIFICATE_OF_ANALYSIS_COA: {
    id: 'CERTIFICATE_OF_ANALYSIS_COA',
    defaultMessage: 'Certificates of Analysis (COA)'
  },
  CERTIFICATE_OF_QUALITY: {
    id: 'CERTIFICATE_OF_QUALITY',
    defaultMessage: 'Certificate of Quality'
  },
  CERTIFICATES_OF_QUALITY: {
    id: 'CERTIFICATES_OF_QUALITY',
    defaultMessage: 'Certificates of Quality'
  },
  CERTIFICATES_OF_QUALITY_COQ: {
    id: 'CERTIFICATES_OF_QUALITY_COQ',
    defaultMessage: 'Certificates of Quality (COQ)'
  },
  CERTIFICATE_OF_QUALITY_COQ: {
    id: 'CERTIFICATE_OF_QUALITY_COQ',
    defaultMessage: 'Certificates of Quality (COQ)'
  },
  COQ_INSTRUCTIONS: {
    id: 'COQ_INSTRUCTIONS',
    defaultMessage: 'To search for a Certificate of Quality (COQ), please enter both the product number and the lot/batch number.'
  },
  DOCUMENTATION_COA_CHOOSE_VERSION: {
    id: 'DOCUMENTATION_COA_CHOOSE_VERSION',
    defaultMessage: 'Choose from one of the most recent versions:'
  },
  NOT_FINDING_WHAT_YOU_ARE_LOOKING_FOR: {
    id: 'NOT_FINDING_WHAT_YOU_ARE_LOOKING_FOR',
    defaultMessage: 'Not Finding What You Are Looking For?'
  },
  REQUEST_CERTIFICATE_INSTRUCTIONS: {
    id: 'REQUEST_CERTIFICATE_INSTRUCTIONS',
    defaultMessage: 'In some cases, a {type} may not be available online. If your search was unable to find the {type} you can request one.'
  },
  REQUEST_CERTIFICATE: {
    id: 'REQUEST_CERTIFICATE',
    defaultMessage: 'Request {type}'
  },
  SEARCH_SAFETY_DATA_SHEET: {
    id: 'SEARCH_SAFETY_DATA_SHEET',
    defaultMessage: 'Search for Safety Data Sheet (SDS)'
  },
  SAFETY_DATA_SHEET: {
    id: 'SAFETY_DATA_SHEET',
    defaultMessage: 'Safety Data Sheet'
  },
  SAFETY_DATA_SHEETS: {
    id: 'SAFETY_DATA_SHEETS',
    defaultMessage: 'Safety Data Sheets'
  },
  SAFETY_DATA_SHEETS_TITLE: {
    id: 'SAFETY_DATA_SHEETS_TITLE',
    defaultMessage: 'Safety Data Sheets (SDS)'
  },
  SDS_INSTRUCTIONS: {
    id: 'SDS_INSTRUCTIONS',
    defaultMessage: 'To search for a Safety Data Sheet, please enter the product number.'
  },
  INDICATES_REQUIRED_FIELD: {
    id: 'INDICATES_REQUIRED_FIELD',
    defaultMessage: '* indicates a required field'
  },
  BRANDS: {
    id: 'BRANDS',
    defaultMessage: 'Brands'
  },
  SDS_SELECT_BRAND: {
    id: 'SDS_SELECT_BRAND',
    defaultMessage: 'Select a brand to view available Safety Data Sheets.'
  },
  LANGUAGES: {
    id: 'LANGUAGES',
    defaultMessage: 'Languages'
  },
  ENTER_PRODUCT_AND_LOT_NUMBER: {
    id: 'ENTER_PRODUCT_AND_LOT_NUMBER',
    defaultMessage: 'Please enter the Product Number and Lot Number.'
  },
  ENTER_COO: {
    id: 'ENTER_COO',
    defaultMessage: 'Enter Lot Number to search for Certificate of Origin (COO).'
  },
  ENTER_PRODUCT_NUMBER_FOR_SDS: {
    id: 'ENTER_PRODUCT_NUMBER_FOR_SDS',
    defaultMessage: 'Please enter the Product Number to find an SDS.'
  },
  DOCUMENTATION: {
    id: 'DOCUMENTATION',
    defaultMessage: 'Documentation'
  },
  VIEW_CERTIFICATE: {
    id: 'VIEW_CERTIFICATE',
    defaultMessage: 'View Certificate'
  },
  LOT_NUMBER: {
    id: 'LOT_NUMBER',
    defaultMessage: 'Lot Number'
  },
  LOT_BATCH: {
    id: 'LOT_BATCH',
    defaultMessage: 'Lot/Batch'
  },
  LEGAL_INFORMATION: {
    id: 'LEGAL_INFORMATION',
    defaultMessage: 'Legal Information'
  },
  PROPERTIES: {
    id: 'PROPERTIES',
    defaultMessage: 'Properties'
  },
  DESCRIPTION: {
    id: 'DESCRIPTION',
    defaultMessage: 'Description'
  },
  RECOMMENDED_PRODUCTS: {
    id: 'RECOMMENDED_PRODUCTS',
    defaultMessage: 'Recommended Products'
  },
  FEATURED: {
    id: 'FEATURED',
    defaultMessage: 'Featured'
  },
  RELATED_PRODUCTS: {
    id: 'RELATED_PRODUCTS',
    defaultMessage: 'Related Products'
  },
  RECENTLY_VIEWED_PRODUCTS: {
    id: 'RECENTLY_VIEWED_PRODUCTS',
    defaultMessage: 'Recently Viewed Products'
  },
  EMPIRICAL_FORMULA: {
    id: 'EMPIRICAL_FORMULA',
    defaultMessage: 'Empirical Formula'
  },
  EMPIRICAL_FORMULA_HILL_NOTATION: {
    id: 'EMPIRICAL_FORMULA_HILL_NOTATION',
    defaultMessage: 'Empirical Formula (Hill Notation)'
  },
  SEE_ALL_RESULTS: {
    id: 'SEE_ALL_RESULTS',
    defaultMessage: 'See All {count} results'
  },
  PEER_REVIEWED_PAPERS: {
    id: 'PEER_REVIEWED_PAPERS',
    defaultMessage: 'Peer Reviewed Papers'
  },
  RISK_AND_SAFETY: {
    id: 'RISK_AND_SAFETY',
    defaultMessage: 'Risk and Safety'
  },
  DOWNLOAD: {
    id: 'DOWNLOAD',
    defaultMessage: 'Download'
  },
  USAGE_AND_SAFETY: {
    id: 'USAGE_AND_SAFETY',
    defaultMessage: 'Usage and Safety'
  },
  ABSORPTION: {
    id: 'ABSORPTION',
    defaultMessage: 'Absorption'
  },
  BOILING_POINT: {
    id: 'BOILING_POINT',
    defaultMessage: 'Boiling Point'
  },
  FORM: {
    id: 'FORM',
    defaultMessage: 'Form'
  },
  Solubility: {
    id: 'Solubility',
    defaultMessage: 'Solubility'
  },
  PHYSIOCHEMICAL_PROPERTIES: {
    id: 'PHYSIOCHEMICAL_PROPERTIES',
    defaultMessage: 'Physiochemical Properties'
  },
  ASSAY: {
    id: 'ASSAY',
    defaultMessage: 'Assay'
  },
  PKA_25_C: {
    id: 'PKA_25_C',
    defaultMessage: 'pKa (25 °C)'
  },
  IMPURITIES: {
    id: 'IMPURITIES',
    defaultMessage: 'Impurities'
  },
  USEFUL_PH_RANGE: {
    id: 'USEFUL_PH_RANGE',
    defaultMessage: 'Useful pH range'
  },
  CHEMICAL_PROPERTIES: {
    id: 'CHEMICAL_PROPERTIES',
    defaultMessage: 'Chemical Properties'
  },
  QUALITY_DESIGNATIONS: {
    id: 'QUALITY_DESIGNATIONS',
    defaultMessage: 'Quality Designations'
  },
  BACK_TO_SEARCH: {
    id: 'BACK_TO_SEARCH',
    defaultMessage: 'Back to Search'
  },
  QUICK_ADD_ACTION: {
    id: 'QUICK_ADD_ACTION',
    defaultMessage: 'Quick Add'
  },
  QUICK_ADD_HEADING: {
    id: 'QUICK_ADD_HEADING',
    defaultMessage: 'Quick Add'
  },
  ADD_TO_QUICK_ORDER: {
    id: 'ADD_TO_QUICK_ORDER',
    defaultMessage: 'Add to Quick Order'
  },
  ADD_TO_QUICK_ORDER_DESCRIPTION: {
    id: 'ADD_TO_QUICK_ORDER_DESCRIPTION',
    defaultMessage: 'Sign in to import items from your recent orders, quotes, or lists.'
  },
  CONFIRM_QUOTE: {
    id: 'CONFIRM_QUOTE',
    defaultMessage: 'Confirm Quote'
  },
  CONFIRM_ORDER: {
    id: 'CONFIRM_ORDER',
    defaultMessage: 'Confirm Order'
  },
  RESUBMIT_ORDER: {
    id: 'RESUBMIT_ORDER',
    defaultMessage: 'Resubmit Order'
  },
  RESUBMIT_QUOTE: {
    id: 'RESUBMIT_QUOTE',
    defaultMessage: 'Resubmit Quote'
  },
  REJECT_QUOTE: {
    id: 'REJECT_QUOTE',
    defaultMessage: 'Reject Quote'
  },
  CONFIRM_QUOTE_MSG: {
    id: 'CONFIRM_QUOTE_MSG',
    defaultMessage: 'Have you verified the quote was completely transferred to SAP'
  },
  CONFIRM_ORDER_MSG: {
    id: 'CONFIRM_ORDER_MSG',
    defaultMessage: 'Have you verified the order was completely transferred to SAP'
  },
  RESUBMIT_ORDER_MSG: {
    id: 'RESUBMIT_ORDER_MSG',
    defaultMessage: 'Have you verified the order does not exist in the SAP system for this customer?'
  },
  RESUBMIT_QUOTE_MSG: {
    id: 'RESUBMIT_QUOTE_MSG',
    defaultMessage: 'Have you verified the quote does not exist in the SAP system for this customer?'
  },
  REJECT_ORDER_MSG: {
    id: 'REJECT_ORDER_MSG',
    defaultMessage: 'Are you sure want to reject this order?'
  },
  REJECT_QUOTE_MSG: {
    id: 'REJECT_QUOTE_MSG',
    defaultMessage: 'Are you sure want to reject this quote?'
  },
  QUOTE_VERIFICATION_TITLE: {
    id: 'QUOTE_VERIFICATION_TITLE',
    defaultMessage: 'QUOTE VERIFICATION'
  },
  ORDER_VERIFICATION_TITLE: {
    id: 'ORDER_VERIFICATION_TITLE',
    defaultMessage: 'ORDER VERIFICATION'
  },
  ORDER_REJECT_TITLE: {
    id: 'ORDER_REJECT_TITLE',
    defaultMessage: 'REJECT ORDER'
  },
  QUOTE_REJECT_TITLE: {
    id: 'QUOTE_REJECT_TITLE',
    defaultMessage: 'REJECT QUOTE'
  },
  ADD_TO_LIST: {
    id: 'ADD_TO_LIST',
    defaultMessage: 'Add to List'
  },
  ADD_MEMBERS: {
    id: 'ADD_MEMBERS',
    defaultMessage: 'Add Members'
  },
  START_A_NEW_SEARCH: {
    id: 'START_A_NEW_SEARCH',
    defaultMessage: 'Start a New Search'
  },
  NO_PRODUCTS_TO_COMPARE: {
    id: 'NO_PRODUCTS_TO_COMPARE',
    defaultMessage: 'No Products To Compare'
  },
  MATERIAL: {
    id: 'MATERIAL',
    defaultMessage: 'Material'
  },
  MATERIALS: {
    id: 'MATERIALS',
    defaultMessage: 'Materials'
  },
  ABSTRACT: {
    id: 'ABSTRACT',
    defaultMessage: 'Abstract'
  },
  order_status_complete: {
    id: 'order_status_complete',
    defaultMessage: 'Complete'
  },
  order_status_in_progress: {
    id: 'order_status_in_progress',
    defaultMessage: 'In Progress'
  },
  order_status_open: {
    id: 'order_status_open',
    defaultMessage: 'Open'
  },
  order_status_any: {
    id: 'order_status_any',
    defaultMessage: 'Any'
  },
  order_field_label_order_details: {
    id: 'order_field_label_order_details',
    defaultMessage: 'Order Details'
  },
  order_field_label_search_orders: {
    id: 'order_field_label_search_orders',
    defaultMessage: 'Search'
  },
  order_field_label_to_date: {
    id: 'order_field_label_to_date',
    defaultMessage: 'To Date'
  },
  order_field_label_from_date: {
    id: 'order_field_label_from_date',
    defaultMessage: 'From Date'
  },
  order_field_label_total: {
    id: 'order_field_label_total',
    defaultMessage: 'Total'
  },
  order_field_label_status: {
    id: 'order_field_label_status',
    defaultMessage: 'Status'
  },
  order_field_label_po_number: {
    id: 'order_field_label_po_number',
    defaultMessage: 'P.O. Number'
  },
  order_field_label_ordered_by: {
    id: 'order_field_label_ordered_by',
    defaultMessage: 'Ordered By'
  },
  order_field_label_order_date: {
    id: 'order_field_label_order_date',
    defaultMessage: 'Order Date'
  },
  order_field_label_product_number: {
    id: 'order_field_label_product_number',
    defaultMessage: 'Product Number'
  },
  order_field_label_order_number: {
    id: 'order_field_label_order_number',
    defaultMessage: 'Order Number'
  },
  order_search_no_results: {
    id: 'order_search_no_results',
    defaultMessage: 'No results. Revise your search query and try again.'
  },
  order_search_fetch_error: {
    id: 'order_search_fetch_error',
    defaultMessage: 'An error occurred fetching your orders.'
  },
  order_search_no_ship_to: {
    id: 'order_search_no_ship_to',
    defaultMessage: 'Any'
  },
  order_search_all: {
    id: 'order_search_all',
    defaultMessage: 'All'
  },
  QUOTE_NUMBER: {
    id: 'QUOTE_NUMBER',
    defaultMessage: 'Quote Number'
  },
  order_search_all_ship_to: {
    id: 'order_search_all_ship_to',
    defaultMessage: 'Any'
  },
  OUT_OF_STOCK: {
    id: 'OUT_OF_STOCK',
    defaultMessage: 'We apologize but fulfillment and delivery of this product is delayed. We are working to minimize these delays as quickly as possible.'
  },
  ANY: {
    id: 'ANY',
    defaultMessage: 'Any'
  },
  EXPIRED: {
    id: 'EXPIRED',
    defaultMessage: 'Expired'
  },
  PRODUCT_NOTE: {
    id: 'PRODUCT_NOTE',
    defaultMessage: 'Packing Slip Note'
  },
  YOUR_REFERENCE: {
    id: 'YOUR_REFERENCE',
    defaultMessage: 'Your Reference'
  },
  QUOTE_ITEM_TABLE_PRODUCT: {
    id: 'QUOTE_ITEM_TABLE_PRODUCT',
    defaultMessage: 'Product'
  },
  QUOTE_TOTAL: {
    id: 'QUOTE_TOTAL',
    defaultMessage: 'Total'
  },
  QUOTE_SUMMARY: {
    id: 'QUOTE_SUMMARY',
    defaultMessage: 'Summary'
  },
  QUOTE_BILLING_ADDRESS: {
    id: 'QUOTE_BILLING_ADDRESS',
    defaultMessage: 'Billing Address'
  },
  CREATED_DATE: {
    id: 'CREATED_DATE',
    defaultMessage: 'Created Date'
  },
  CREATED_BY: {
    id: 'CREATED_BY',
    defaultMessage: 'Created By'
  },
  REQUEST_DATE: {
    id: 'REQUEST_DATE',
    defaultMessage: 'Request Date'
  },
  QUOTE_DETAILS: {
    id: 'QUOTE_DETAILS',
    defaultMessage: 'Quote Details'
  },
  ORDER_TYPE_MILLIPORESIGMA: {
    id: 'ORDER_TYPE_MILLIPORESIGMA',
    defaultMessage: 'MilliporeSigma'
  },
  ORDER_TYPE_MERCK: {
    id: 'ORDER_TYPE_MERCK',
    defaultMessage: 'Merck'
  },
  ORDER_TYPE_MARKETPLACE: {
    id: 'ORDER_TYPE_MARKETPLACE',
    defaultMessage: 'Marketplace'
  },
  INVALID_INPUT_VIEW_MORE: {
    id: 'INVALID_INPUT_VIEW_MORE',
    defaultMessage: '(view {remaining} more errors)'
  },
  INVALID_SKUS_EXPANDED: {
    id: 'INVALID_SKUS_EXPANDED',
    defaultMessage: 'Invalid SKUs:'
  },
  INVALID_SKUS_VIEW_MORE: {
    id: 'INVALID_SKUS_VIEW_MORE',
    defaultMessage: '(view {remaining} more)'
  },
  INVALID_SKUS_COLLAPSED: {
    id: 'INVALID_SKUS_COLLAPSED',
    defaultMessage: 'Invalid SKUs: {skus}...'
  },
  INVALID_SKUS: {
    id: 'INVALID_SKUS',
    defaultMessage: 'Invalid SKUs: {skus}'
  },
  EMPTY_SKU: {
    id: 'EMPTY_SKU',
    defaultMessage: 'Empty SKU'
  },
  INVALID_SKU: {
    id: 'INVALID_SKU',
    defaultMessage: 'Invalid SKU: {sku} - please enter a valid SKU'
  },
  THIS_PRODUCT_NUMBER_DOES_NOT_EXIST: {
    id: 'THIS_PRODUCT_NUMBER_DOES_NOT_EXIST',
    defaultMessage: 'This product number does not exist. Please enter the correct number.'
  },
  ADD_TO_STAGING: {
    id: 'ADD_TO_STAGING',
    defaultMessage: 'Add'
  },
  ENTER_SKU: {
    id: 'ENTER_SKU',
    defaultMessage: 'Enter product SKU, complete with package size.'
  },
  CLEAR_ITEMS: {
    id: 'CLEAR_ITEMS',
    defaultMessage: 'Clear Items'
  },
  MULIPLE_PRODUCT_ENTRY: {
    id: 'MULIPLE_PRODUCT_ENTRY',
    defaultMessage: 'Multiple Product Entry'
  },
  SINGLE_PRODUCT_ENTRY: {
    id: 'SINGLE_PRODUCT_ENTRY',
    defaultMessage: 'Single Product Entry'
  },
  SELECT_AN_ENTRY_METHOD: {
    id: 'SELECT_AN_ENTRY_METHOD',
    defaultMessage: 'SELECT AN ENTRY METHOD'
  },
  PRODUCT_AVAILABILITY_FROM: {
    id: 'PRODUCT_AVAILABILITY_FROM',
    defaultMessage: 'from'
  },
  CHECK_AVAILABILITY_BUTTON: {
    id: 'CHECK_AVAILABILITY_BUTTON',
    defaultMessage: 'Check Availability'
  },
  PRICING_AND_AVAILABILITY_UNAVAILABLE: {
    id: 'PRICING_AND_AVAILABILITY_UNAVAILABLE',
    defaultMessage: 'Pricing and availability is not currently available.'
  },
  INVALID_MATERIAL_ID: {
    id: 'INVALID_MATERIAL_ID',
    defaultMessage: '{productNumber} is not a valid product number.'
  },
  PRICING_AND_AVAILABILITY_TEMPORARILY_UNAVAILABLE_FROM: {
    id: 'PRICING_AND_AVAILABILITY_TEMPORARILY_UNAVAILABLE_FROM',
    defaultMessage: 'Pricing and availability is temporarily unavailable from {preferredDealerName}'
  },
  CUSTOMER_NUMBER_BLOCKED: {
    id: 'CUSTOMER_NUMBER_BLOCKED',
    defaultMessage: 'Your customer number ({customerNumber}) is currently blocked. Please contact customer service for assistance.'
  },
  AVAILABILITY_SHIPS_ON: {
    id: 'AVAILABILITY_SHIPS_ON',
    defaultMessage: 'Estimated to ship on'
  },
  REQUEST_BULK_ORDER: {
    id: 'REQUEST_BULK_ORDER',
    defaultMessage: 'Request a Bulk Order'
  },
  BULK_ORDER: {
    id: 'BULK_ORDER',
    defaultMessage: 'For orders above 5 units please use the Request a Bulk Order button.'
  },
  BULK_QUOTE: {
    id: 'BULK_QUOTE',
    defaultMessage: 'Bulk Quote-Order Product'
  },
  EMPROVE_SUBSCRIBED_AVAILABILITY: {
    id: 'EMPROVE_SUBSCRIBED_AVAILABILITY',
    defaultMessage: 'Available for download once your purchase is completed'
  },
  EMPROVE_NONSUBSCRIBED_AVAILABILITY: {
    id: 'EMPROVE_NONSUBSCRIBED_AVAILABILITY',
    defaultMessage: 'Available for download once your purchase is completed and approved'
  },
  RESULTS_PER_PAGE: {
    id: 'RESULTS_PER_PAGE',
    defaultMessage: 'Results per page'
  },
  GET_HELP: {
    id: 'GET_HELP',
    defaultMessage: 'Get Help'
  },
  DOWNLOAD_TEMPLATE: {
    id: 'DOWNLOAD_TEMPLATE',
    defaultMessage: 'Download Template'
  },
  ENTER_SKUS: {
    id: 'ENTER_SKUS',
    defaultMessage: 'Type or paste product SKUs and quantities, separated by commas. Enter separate products on new lines.'
  },
  CONFIRM_CLEAR_ITEMS: {
    id: 'CONFIRM_CLEAR_ITEMS',
    defaultMessage: 'Clear Items'
  },
  CANCEL_CLEAR_ITEMS: {
    id: 'CANCEL_CLEAR_ITEMS',
    defaultMessage: 'Cancel'
  },
  CONFIRM_CLEAR_ITEMS_DIALOG: {
    id: 'CONFIRM_CLEAR_ITEMS_DIALOG',
    defaultMessage: 'Are you sure you want to clear all items?'
  },
  IS_NOT_A_VALID_PRODUCT_NUMBER: {
    id: 'IS_NOT_A_VALID_PRODUCT_NUMBER',
    defaultMessage: '{productNumber} is not a valid product number.'
  },
  CANT_REQUEST_QUOTE_WITH_MARKETPLACE_ITEMS: {
    id: 'CANT_REQUEST_QUOTE_WITH_MARKETPLACE_ITEMS',
    defaultMessage: 'Marketplace items cannot be added to a quote. Please remove the item to request a quote.'
  },
  PRODUCT_NOT_AVAILABLE_FOR_PURCHASE: {
    id: 'PRODUCT_NOT_AVAILABLE_FOR_PURCHASE',
    defaultMessage: 'Product {productNumber} is currently not available for purchase.'
  },
  FROM: {
    id: 'FROM',
    defaultMessage: 'From'
  },
  ORDER_SUMMARY: {
    id: 'ORDER_SUMMARY',
    defaultMessage: 'Order Summary'
  },
  SHIPPING_METHOD: {
    id: 'SHIPPING_METHOD',
    defaultMessage: 'Shipping Method'
  },
  SHIPPED_SOLD_BY: {
    id: 'SHIPPED_SOLD_BY',
    defaultMessage: 'Shipped & Sold By'
  },
  BUYER: {
    id: 'BUYER',
    defaultMessage: 'Buyer'
  },
  CUSTOMER_SERVICE_NOTE: {
    id: 'CUSTOMER_SERVICE_NOTE',
    defaultMessage: 'Customer Service Note'
  },
  ORDER_DETAIL_PAGE: {
    id: 'ORDER_DETAIL_PAGE',
    defaultMessage: 'Order Detail Page'
  },
  ORDER_DETAILS: {
    id: 'ORDER_DETAILS',
    defaultMessage: 'Order Details'
  },
  ORDER_TOTAL: {
    id: 'ORDER_TOTAL',
    defaultMessage: 'Order Total'
  },
  PENDING_ORDER: {
    id: 'PENDING_ORDER',
    defaultMessage: 'Pending Order'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  NO_ORDERS_FOUND: {
    id: 'NO_ORDERS_FOUND',
    defaultMessage: 'No orders found.'
  },
  ORDER_RETURN: {
    id: 'ORDER_RETURN',
    defaultMessage: 'Order Return'
  },
  ORDER_RETURN_SHIPPED_AND_SOLD: {
    id: 'ORDER_RETURN_SHIPPED_AND_SOLD',
    defaultMessage: 'Shipped & Sold by {sellerName}'
  },
  ORDER_ITEM_VIEW_PRODUCT_LINK: {
    id: 'ORDER_ITEM_VIEW_PRODUCT_LINK',
    defaultMessage: 'View Product'
  },
  ORDER_ITEM_PRICE: {
    id: 'ORDER_ITEM_PRICE',
    defaultMessage: 'Price'
  },
  ORDER_ITEM_QUANTITY: {
    id: 'ORDER_ITEM_QUANTITY',
    defaultMessage: 'Quantity'
  },
  ORDER_ITEM_TOTAL: {
    id: 'ORDER_ITEM_TOTAL',
    defaultMessage: 'Total'
  },
  ORDER_ITEM_SHIPPING_METHOD: {
    id: 'ORDER_ITEM_SHIPPING_METHOD',
    defaultMessage: 'Shipping Method'
  },
  ORDER_ITEM_YOUR_REFERENCE: {
    id: 'ORDER_ITEM_YOUR_REFERENCE',
    defaultMessage: 'Your Reference'
  },
  ORDER_ITEM_PRODUCT_NOTE: {
    id: 'ORDER_ITEM_PRODUCT_NOTE',
    defaultMessage: 'Packing Slip Note'
  },
  ORDER_ITEM_DESCRIPTION: {
    id: 'ORDER_ITEM_DESCRIPTION',
    defaultMessage: 'Description'
  },
  ORDER_ITEM_COUNT: {
    id: 'ORDER_ITEM_COUNT',
    defaultMessage: 'Items'
  },
  ERROR_PROCESSING_ORDER: {
    id: 'ERROR_PROCESSING_ORDER',
    defaultMessage: 'An error has occurred while processing this order. Please contact Customer Service at Phone {phoneNumber} Email {email}.'
  },
  ERROR_PROCESSING_ORDER_NO_CONTACT_INFO: {
    id: 'ERROR_PROCESSING_ORDER_NO_CONTACT_INFO',
    defaultMessage: 'An error has occurred while processing this order. Please contact Customer Service.'
  },
  ORDER_SETTINGS: {
    id: 'ORDER_SETTINGS',
    defaultMessage: 'Order Settings'
  },
  YOUR_SETTINGS: {
    id: 'YOUR_SETTINGS',
    defaultMessage: 'Your Settings'
  },
  QUOTES_REF_NUMBER: {
    id: 'QUOTES_REF_NUMBER',
    defaultMessage: 'Quote Reference Number'
  },
  EDQM: {
    id: 'EDQM',
    defaultMessage: 'Product with sales restriction can only be purchased with limited quantity per month.'
  },
  ONLINE_ORDERS: {
    id: 'ONLINE_ORDERS',
    defaultMessage: 'Online Orders'
  },
  RECENT_ORDERS: {
    id: 'RECENT_ORDERS',
    defaultMessage: 'Recent Orders'
  },
  RECENT_QUOTES: {
    id: 'RECENT_QUOTES',
    defaultMessage: 'Recent Quotes'
  },
  SPECIAL_OFFERS: {
    id: 'SPECIAL_OFFERS',
    defaultMessage: 'Special Offers'
  },
  WEBINARS: {
    id: 'WEBINARS',
    defaultMessage: 'Webinars'
  },
  NEWS_AND_OFFERS: {
    id: 'NEWS_AND_OFFERS',
    defaultMessage: 'News and Offers'
  },
  FEATURED_PRODUCTS: {
    id: 'FEATURED_PRODUCTS',
    defaultMessage: 'Featured Products'
  },
  HI: {
    id: 'HI',
    defaultMessage: 'Hi {userName}'
  },
  SIGN_IN_TO_GET_STARTED: {
    id: 'SIGN_IN_TO_GET_STARTED',
    defaultMessage: 'Sign In to Get Started'
  },
  LOGIN_DISCLAIMER: {
    id: 'LOGIN_DISCLAIMER',
    defaultMessage: '{asterisk} Welcome to the new SigmaAldrich.com. As a security feature, all registered users must reset their password when logging into the new site for the first time. Please enter your profile email address and password to have a password reset link sent to your email account. This link will expire after 24 hours. If you need support, please contact your local customer service team.'
  },
  NEW_LIST_NAME: {
    id: 'NEW_LIST_NAME',
    defaultMessage: 'New list name'
  },
  LIST_NUM_ONE: {
    id: 'LIST_NUM_ONE',
    defaultMessage: 'List #1'
  },
  THAT_NAME_IS_ALREADY_IN_USE: {
    id: 'THAT_NAME_IS_ALREADY_IN_USE',
    defaultMessage: 'That name is already in use'
  },
  ACCESS_ORDER_HISTORY: {
    id: 'ACCESS_ORDER_HISTORY',
    defaultMessage: 'View orders'
  },
  MULTIPLE_SHIPPING_LOCATIONS: {
    id: 'MULTIPLE_SHIPPING_LOCATIONS',
    defaultMessage: 'Add multiple shipping locations'
  },
  CHECK_OUT_FASTER: {
    id: 'CHECK_OUT_FASTER',
    defaultMessage: 'Check out faster'
  },
  CREATE_AND_SHARE_LISTS: {
    id: 'CREATE_AND_SHARE_LISTS',
    defaultMessage: 'Create and share lists'
  },
  ACCESS_ONLINE_TOOLS: {
    id: 'ACCESS_ONLINE_TOOLS',
    defaultMessage: 'Access online tools'
  },
  LOGIN_LEAD: {
    id: 'LOGIN_LEAD',
    defaultMessage: 'You are currently not signed in. For a better experience, please create an online profile, which will allow you to find products and prices specific to your organization. {break}{break}In addition, you can:'
  },
  LOGIN_INADDITION: {
    id: 'LOGIN_INADDITION',
    defaultMessage: 'In addition, you can'
  },
  LOGIN_TITLE: {
    id: 'LOGIN_TITLE',
    defaultMessage: 'Personalize Your Experience'
  },
  CREATE_NEW_PROFILE: {
    id: 'CREATE_NEW_PROFILE',
    defaultMessage: 'Register'
  },
  CREATE_NEW_PROFILE_DESCRIPTION: {
    id: 'CREATE_NEW_PROFILE_DESCRIPTION',
    defaultMessage: 'Lorem ipsum dolor sit amet, consetetur sadipscing, sed diam nonumy'
  },
  CREATE_NEW_ACCOUNT: {
    id: 'CREATE_NEW_ACCOUNT',
    defaultMessage: 'Create a New Account'
  },
  RESET_PASSWORD: {
    id: 'RESET_PASSWORD',
    defaultMessage: 'Reset Password'
  },
  USERNAME_EMAIL: {
    id: 'USERNAME_EMAIL',
    defaultMessage: 'Username / Email Address'
  },
  LOGIN_DIRECTIONS: {
    id: 'LOGIN_DIRECTIONS',
    defaultMessage: 'To sign into your profile, please enter your username or email address and password.'
  },
  SIGN_IN_PROFILE: {
    id: 'SIGN_IN_PROFILE',
    defaultMessage: 'Sign In'
  },
  LINK_PROFILE_DIALOG_ILL_DO_THIS_LATER: {
    id: 'LINK_PROFILE_DIALOG_ILL_DO_THIS_LATER',
    defaultMessage: "I'll Do This Later"
  },
  LINK_PROFILE_DIALOG_TRY_AGAIN: {
    id: 'LINK_PROFILE_DIALOG_TRY_AGAIN',
    defaultMessage: 'Try Again'
  },
  LINK_PROFILE_DIALOG_REGISTER_ORGANIZATION: {
    id: 'LINK_PROFILE_DIALOG_REGISTER_ORGANIZATION',
    defaultMessage: 'Register Organization'
  },
  LINK_PROFILE_DIALOG_TEXT: {
    id: 'LINK_PROFILE_DIALOG_TEXT',
    defaultMessage: 'Are you sure you want to link your account later? To order products, view contract pricing, or request quotes, a profile must be linked to a registered organization. You may also register a new organization.'
  },
  LINK_PROFILE_DIALOG_TITLE: {
    id: 'LINK_PROFILE_DIALOG_TITLE',
    defaultMessage: 'Are You Sure?'
  },
  PACKING_SLIP_NUMBER: {
    id: 'PACKING_SLIP_NUMBER',
    defaultMessage: 'Packing Slip Number'
  },
  PREVIOUS_NUMBER: {
    id: 'PREVIOUS_NUMBER',
    defaultMessage: 'Order Number'
  },
  TRANSACTIONAL_INFORMATION: {
    id: 'TRANSACTIONAL_INFORMATION',
    defaultMessage: 'Transactional Information'
  },
  CUSTOMER_PIN: {
    id: 'CUSTOMER_PIN',
    defaultMessage: 'Pin Number'
  },
  CUSTOMER_NUMBER: {
    id: 'CUSTOMER_NUMBER',
    defaultMessage: 'Customer Number'
  },
  ACCOUNT_INFORMATION: {
    id: 'ACCOUNT_INFORMATION',
    defaultMessage: 'Account Information'
  },
  LINK_PROFILE_VAT_SALES_EXEMPTION_STATUS: {
    id: 'LINK_PROFILE_VAT_SALES_EXEMPTION_STATUS',
    defaultMessage: 'VAT and sales tax exemption status'
  },
  LINK_PROFILE_ORGANIZATION_HEADQUARTERS_ADDRESS: {
    id: 'LINK_PROFILE_ORGANIZATION_HEADQUARTERS_ADDRESS',
    defaultMessage: 'The address of your organization’s registered office'
  },
  LINK_PROFILE_PRIMARY_BUSINESS_ACTIVITY: {
    id: 'LINK_PROFILE_PRIMARY_BUSINESS_ACTIVITY',
    defaultMessage: 'Primary business activities'
  },
  LINK_PROFILE_SHIPPING_BILLING_PAYMENT_ADDRESSES: {
    id: 'LINK_PROFILE_SHIPPING_BILLING_PAYMENT_ADDRESSES',
    defaultMessage: 'Shipping, billing & payment addresses'
  },
  ORG_FIRST_TIME_ORDER_INFO: {
    id: 'ORG_FIRST_TIME_ORDER_INFO',
    defaultMessage: 'You will need to provide some information about your organization. Please have the following information ready:'
  },
  ORG_FIRST_TIME_ORDER: {
    id: 'ORG_FIRST_TIME_ORDER',
    defaultMessage: 'Not Sure? Continue Registration.'
  },
  LINK_PROFILE_COMPLETE_PROCESS: {
    id: 'LINK_PROFILE_COMPLETE_PROCESS',
    defaultMessage: 'Expedite the process by entering a Customer Number along with a corresponding transaction number.'
  },
  LINK_PROFILE_COMPLETE_PROCESS_HINT: {
    id: 'LINK_PROFILE_COMPLETE_PROCESS_HINT',
    defaultMessage: 'How to enter Account Information & Transactional Information?'
  },
  LINK_PROFILE_ACCOUNT_INFO_MODAL_TITLE: {
    id: 'LINK_PROFILE_ACCOUNT_INFO_MODAL_TITLE',
    defaultMessage: 'Account info & Transactional info'
  },
  LINK_PROFILE_ACCOUNT_INFO_MODAL_CONTENT: {
    id: 'LINK_PROFILE_ACCOUNT_INFO_MODAL_CONTENT',
    defaultMessage: 'Please use the invoice example to find your account information and transactional information.'
  },
  ORG_ORDERED_IN_PAST: {
    id: 'ORG_ORDERED_IN_PAST',
    defaultMessage: 'Has your organization ordered from us before?'
  },
  LINK_PROFILE_ERROR_MESSAGE: {
    id: 'LINK_PROFILE_ERROR_MESSAGE',
    defaultMessage: '{errorHeading} {errorsList}'
  },
  FIELD_REQUIRED_COUNT: {
    id: 'FIELD_REQUIRED_COUNT',
    defaultMessage: 'There are {numErrors} errors on the page'
  },
  FIELD_REQUIRED_NAME: {
    id: 'FIELD_REQUIRED_NAME',
    defaultMessage: 'Field Required'
  },
  LINK_PROFILE_OR_CONTINUE: {
    id: 'LINK_PROFILE_OR_CONTINUE',
    defaultMessage: 'or continue registration.'
  },
  LINK_OR_REGISTER_PROFILE_HEADING: {
    id: 'LINK_OR_REGISTER_PROFILE_HEADING',
    defaultMessage: 'Link or register your organization'
  },
  LINK_OR_REGISTER_PROFILE_HEADING_SELF: {
    id: 'LINK_OR_REGISTER_PROFILE_HEADING_SELF',
    defaultMessage: 'Self Affiliate'
  },
  BASIC_REGISTRATION_COMPLETE: {
    id: 'BASIC_REGISTRATION_COMPLETE',
    defaultMessage: 'Thank you for registering your profile!'
  },
  PERSONAL_INFORMATION_COLLECTION_CONSENT_LETTER: {
    id: 'PERSONAL_INFORMATION_COLLECTION_CONSENT_LETTER',
    defaultMessage: 'Personal Information Collection Consent Letter'
  },
  MARKETPLACE_TERMS_OF_USE: {
    id: 'MARKETPLACE_TERMS_OF_USE',
    defaultMessage: 'Terms of Use'
  },
  MARKETPLACE_SALES_TERMS_AND_CONDITIONS: {
    id: 'MARKETPLACE_SALES_TERMS_AND_CONDITIONS',
    defaultMessage: 'sales terms and conditions'
  },
  FULL_REGISTRATION_SAVED: {
    id: 'FULL_REGISTRATION_SAVED',
    defaultMessage: 'Thank you for registering. Your information has been saved. You may finish registration at checkout.'
  },
  FULL_REGISTRATION_VALIDATION_FAILED: {
    id: 'FULL_REGISTRATION_VALIDATION_FAILED',
    defaultMessage: 'Fix errors to continue'
  },
  FULL_REGISTRATION_SHIPPING_ADDRESS: {
    id: 'FULL_REGISTRATION_SHIPPING_ADDRESS',
    defaultMessage: 'Shipping Address'
  },
  FULL_REGISTRATION_PLEASE_VERIFY_MOBILE: {
    id: 'FULL_REGISTRATION_PLEASE_VERIFY_MOBILE',
    defaultMessage: 'Please verify mobile'
  },
  FULL_REGISTRATION_SHIPPING_ADDRESS_USE_ABOVE: {
    id: 'FULL_REGISTRATION_SHIPPING_ADDRESS_USE_ABOVE',
    defaultMessage: 'Use above contact details for shipping contact'
  },
  FULL_REGISTRATION_PAYMENT_ATTENTION_TO: {
    id: 'FULL_REGISTRATION_PAYMENT_ATTENTION_TO',
    defaultMessage: 'Attention To'
  },
  FULL_REGISTRATION_CONTACT_INFORMATION_COPY: {
    id: 'FULL_REGISTRATION_CONTACT_INFORMATION_COPY',
    defaultMessage: 'Copy Contact Information'
  },
  FULL_REGISTRATION_ADDRESS_SAME_REGISTERED_OFFICE: {
    id: 'FULL_REGISTRATION_ADDRESS_SAME_REGISTERED_OFFICE',
    defaultMessage: 'Same as Registered Office Address'
  },
  FULL_REGISTRATION_ORGANIZATION_DEALER: {
    id: 'FULL_REGISTRATION_ORGANIZATION_DEALER',
    defaultMessage: 'Dealer'
  },
  FULL_REGISTRATION_ORGANIZATION_END_USER: {
    id: 'FULL_REGISTRATION_ORGANIZATION_END_USER',
    defaultMessage: 'End User'
  },
  FULL_REGISTRATION_TAX_OTHER: {
    id: 'FULL_REGISTRATION_TAX_OTHER',
    defaultMessage: 'Other'
  },
  FULL_REGISTRATION_AGREEMENT_POLICY: {
    id: 'FULL_REGISTRATION_AGREEMENT_POLICY',
    defaultMessage: 'I agree to the Compliance Terms & Conditions of Sales. I accept the Site Use Terms and Privacy Policy and I consent to use cookies in accordance with such policy.'
  },
  THANK_YOU_FOR_REGISTERING: {
    id: 'THANK_YOU_FOR_REGISTERING',
    defaultMessage: 'Thank you for registering!'
  },
  CONGRATULATIONS_YOU_CAN_START_YOUR: {
    id: 'CONGRATUALATIONS_YOU_CAN_START_YOUR',
    defaultMessage: 'Congratulations! You can start your online purchasing journey now.'
  },
  PLEASE_NOTE_THAT_YOU_ARE: {
    id: 'PLEASE_NOTE_THAT_YOU_ARE',
    defaultMessage: 'Please note that you are currently viewing the list price of {companyName}.'
  },
  WE_WILL_CONTACT_YOU_BY_EMAIL: {
    id: 'WE_WILL_CONTACT_YOU_BY_EMAIL',
    defaultMessage: 'We will contact you by email after we review your account information, and it should take approximately 2 to 3 business days. Once the review is complete, the dealer’s price will be displayed.'
  },
  IN_THE_MEANTIME_IF_YOU: {
    id: 'IN_THE_MEANTIME_IF_YOU',
    defaultMessage: 'In the meantime, if you would like to confirm the final purchase price before placing your order, please contact the dealer directly.'
  },
  IN_ADDITION_FOR_CUSTOM_PRODUCTS: {
    id: 'IN_ADDITION_FOR_CUSTOM_PRODUCTS',
    defaultMessage: 'In addition, for custom products (oligo DNA, siRNA), you may start placing orders now.'
  },
  ENTER_DEALER_NAME_OR_LOCATION: {
    id: 'ENTER_DEALER_NAME_OR_LOCATION',
    defaultMessage: 'Enter Dealer Name or Location'
  },
  ANALYST: {
    id: 'ANALYST',
    defaultMessage: 'Analyst'
  },
  CONSULTANT: {
    id: 'CONSULTANT',
    defaultMessage: 'Consultant'
  },
  DISTRIBUTOR_DEALER_RESELLER_EXPORTER: {
    id: 'DISTRIBUTOR_DEALER_RESELLER_EXPORTER',
    defaultMessage: 'Distributor/Dealer/Reseller/Exporter'
  },
  EDUCATOR: {
    id: 'EDUCATOR',
    defaultMessage: 'Educator'
  },
  HEALTH_SAFETY: {
    id: 'HEALTH_SAFETY',
    defaultMessage: 'Health/Safety'
  },
  LABORATORY_TECHNICIAN: {
    id: 'LABORATORY_TECHNICIAN',
    defaultMessage: 'Laboratory Technician'
  },
  LAB_DIRECTOR: {
    id: 'LAB_DIRECTOR',
    defaultMessage: 'Lab Director'
  },
  MANAGEMENT_ADMINISTRATION: {
    id: 'MANAGEMENT_ADMINISTRATION',
    defaultMessage: 'Management/Administration'
  },
  OTHER: {
    id: 'OTHER',
    defaultMessage: 'Other'
  },
  OUTSOURCING: {
    id: 'OUTSOURCING',
    defaultMessage: 'Outsourcing'
  },
  OUTSOURCING_PROFESSIONAL: {
    id: 'OUTSOURCING_PROFESSIONAL',
    defaultMessage: 'Outsourcing Professional'
  },
  PRINCIPAL_SCIENTIST: {
    id: 'PRINCIPAL_SCIENTIST',
    defaultMessage: 'Principal Scientist'
  },
  PROCUREMENT_PURCHASING: {
    id: 'PROCUREMENT_PURCHASING',
    defaultMessage: 'Procurement/Purchasing'
  },
  QUALITY_CONTROL_DIRECTOR: {
    id: 'QUALITY_CONTROL_DIRECTOR',
    defaultMessage: 'Quality Control Director'
  },
  QUALITY_CONTROL_MANAGER: {
    id: 'QUALITY_CONTROL_MANAGER',
    defaultMessage: 'Quality Control Manager'
  },
  SENIOR_ANALYST: {
    id: 'SENIOR_ANALYST',
    defaultMessage: 'Senior Analyst'
  },
  SENIOR_ANALYST_QUALITY_CONTROL_DIRECTOR_QUALITY_CONTROL_MANAGER: {
    id: 'SENIOR_ANALYST_QUALITY_CONTROL_DIRECTOR_QUALITY_CONTROL_MANAGER',
    defaultMessage: 'Senior Analyst/Quality Control Director/Quality Control Manager'
  },
  SENIOR_RESEARCHER: {
    id: 'SENIOR_RESEARCHER',
    defaultMessage: 'Senior Researcher'
  },
  SENIOR_RESEARCHER_PRINCIPAL_SCIENTIST_LAB_DIRECTOR: {
    id: 'SENIOR_RESEARCHER_PRINCIPAL_SCIENTIST_LAB_DIRECTOR',
    defaultMessage: 'Senior Researcher/Principal Scientist/Lab Director'
  },
  STOCKROOM_MANAGER: {
    id: 'STOCKROOM_MANAGER',
    defaultMessage: 'Stockroom Manager'
  },
  UNIVERSITY_COLLEGE_STUDENT_UNDERGRADUATE_GRADUATE: {
    id: 'UNIVERSITY_COLLEGE_STUDENT_UNDERGRADUATE_GRADUATE',
    defaultMessage: 'University/College Student (Undergraduate, Graduate)'
  },
  UNIVERSITY_COLLEGE_STUDENT: {
    id: 'UNIVERSITY_COLLEGE_STUDENT',
    defaultMessage: 'University/College Student'
  },
  SHOW_ALL_RESULTS: {
    id: 'SHOW_ALL_RESULTS',
    defaultMessage: 'Show All {count, number} Results'
  },
  SHOW_FEWER_RESULTS: {
    id: 'SHOW_FEWER_RESULTS',
    defaultMessage: 'Show Fewer Results'
  },
  UNIPROTKB_ID: {
    id: 'UNIPROTKB_ID',
    defaultMessage: 'UniProtKB ID'
  },
  USER_NAME_FORGOT_PASSWORD: {
    id: 'USER_NAME_FORGOT_PASSWORD',
    defaultMessage: 'Username'
  },
  USER_NAME: {
    id: 'USER_NAME',
    defaultMessage: 'User Name'
  },
  RESET_PASSWORD_REQUEST_MESSAGE: {
    id: 'RESET_PASSWORD_REQUEST_MESSAGE',
    defaultMessage: 'Enter your email address to receive a link to reset your password.'
  },
  CREATE_NEW_LIST: {
    id: 'CREATE_NEW_LIST',
    defaultMessage: 'Create New List'
  },
  CARTS_LOADED: {
    id: 'CARTS_LOADED',
    defaultMessage: 'Cart(s) Loaded'
  },
  CARTS_DELETED: {
    id: 'CARTS_DELETED',
    defaultMessage: 'Cart(s) Deleted'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  SHIPTO_OR_BILLTO_PARTNER_NOT_MATCHED: {
    id: 'SHIPTO_OR_BILLTO_PARTNER_NOT_MATCHED',
    defaultMessage: 'The Billing and/or Shipping Address for the selected cart(s) do not match the Billing and/or Shipping Address in your active cart. These cannot be merged.'
  },
  SKU: {
    id: 'SKU',
    defaultMessage: 'SKU'
  },
  ADD_REFERENCE: {
    id: 'ADD_REFERENCE',
    defaultMessage: 'Add Reference'
  },
  ADD_LIST_TO_CART: {
    id: 'ADD_LIST_TO_CART',
    defaultMessage: 'Add List to Cart'
  },
  MOVE_TO: {
    id: 'MOVE_TO',
    defaultMessage: 'Move to'
  },
  LOADING_PRICE_AND_AVAILABILITY: {
    id: 'LOADING_PRICE_AND_AVAILABILITY',
    defaultMessage: 'Loading Price & Availability'
  },
  DELETE: {
    id: 'DELETE',
    defaultMessage: 'Delete'
  },
  REMOVED_FROM_CART: {
    id: 'REMOVED_FROM_CART',
    defaultMessage: 'Item removed from cart'
  },
  EDIT_LIST_NAME: {
    id: 'EDIT_LIST_NAME',
    defaultMessage: 'Edit list name'
  },
  SHARE_LIST: {
    id: 'SHARE_LIST',
    defaultMessage: 'Share list'
  },
  DECLINE: {
    id: 'DECLINE',
    defaultMessage: 'Decline'
  },
  ACCEPT: {
    id: 'ACCEPT',
    defaultMessage: 'Accept'
  },
  CERTIFICATE_DISCLOSURE: {
    id: 'CERTIFICATE_DISCLOSURE',
    defaultMessage: 'This document has been produced electronically and is valid without a signature. The information above is correct to the best of our knowledge, based on the information received from our suppliers. This information is to be used for the purpose of determining animal origin only and not “country of origin” for import/export purposes. This document does not make any warranty, express or implied, of fitness for any particular use of the product(s). Purchaser must determine the suitability of the product(s) for its use under the applicable laws and regulations.'
  },
  LOT_NUMBER_RESULTS: {
    id: 'LOT_NUMBER_RESULTS',
    defaultMessage: 'Lot {lotNumber} Results'
  },
  SPECIFICATION: {
    id: 'SPECIFICATION',
    defaultMessage: 'Specification'
  },
  SPECIFICATION_SHEET: {
    id: 'SPECIFICATION_SHEET',
    defaultMessage: 'Specification Sheet'
  },
  SPEC_SHEET: {
    id: 'SPEC_SHEET',
    defaultMessage: 'Spec Sheet'
  },
  TEST: {
    id: 'TEST',
    defaultMessage: 'Test'
  },
  EFFECTIVE: {
    id: 'EFFECTIVE',
    defaultMessage: 'Effective'
  },
  REVISION_NUMBER: {
    id: 'REVISION_NUMBER',
    defaultMessage: 'Revision'
  },
  EMPTY_CART: {
    id: 'EMPTY_CART',
    defaultMessage: 'Empty cart'
  },
  ITEMS_IN_YOUR_COMPANY_CART: {
    id: 'ITEMS_IN_YOUR_COMPANY_CART',
    defaultMessage: 'Items in {companyName} cart'
  },
  ITEMS_IN_YOUR_CART: {
    id: 'ITEMS_IN_YOUR_CART',
    defaultMessage: 'Items in Your Cart'
  },
  ITEMS_IN_MARKETPLACE_CART: {
    id: 'ITEMS_IN_MARKETPLACE_CART',
    defaultMessage: 'Items in marketplace cart'
  },
  YOUR_CART_IS_EMPTY: {
    id: 'YOUR_CART_IS_EMPTY',
    defaultMessage: 'Your shopping cart is empty.'
  },
  NONE: {
    id: 'NONE',
    defaultMessage: 'None'
  },
  CHANGE: {
    id: 'CHANGE',
    defaultMessage: 'Change'
  },
  ADDRESS: {
    id: 'ADDRESS',
    defaultMessage: 'Address'
  },
  SELECTED: {
    id: 'SELECTED',
    defaultMessage: 'Selected'
  },
  SHIPPING: {
    id: 'SHIPPING',
    defaultMessage: 'Shipping'
  },
  SELECT: {
    id: 'SELECT',
    defaultMessage: 'Select'
  },
  DESELECT: {
    id: 'DESELECT',
    defaultMessage: 'Deselect'
  },
  SELECT_ALL: {
    id: 'SELECT_ALL',
    defaultMessage: 'Select All'
  },
  DESELECT_ALL: {
    id: 'DESELECT_ALL',
    defaultMessage: 'Deselect All'
  },
  GENERATE_QUOTE: {
    id: 'GENERATE_QUOTE',
    defaultMessage: 'Generate Quote'
  },
  SUBMIT_ORDER: {
    id: 'SUBMIT_ORDER',
    defaultMessage: 'Submit Order'
  },
  SHIPPING_FROM: {
    id: 'SHIPPING_FROM',
    defaultMessage: 'Shipping From'
  },
  CART_PO_BOX: {
    id: 'CART_PO_BOX',
    defaultMessage: 'PO Box'
  },
  CART_SUMMARY_TERMS: {
    id: 'CART_SUMMARY_TERMS',
    defaultMessage: "By proceeding to checkout, I accept Sigma-Aldrich's Site Use Terms, Privacy Policy and Terms and Conditions of Sale."
  },
  CART_SHIPPING_ADDRESS_CANCEL: {
    id: 'CART_SHIPPING_ADDRESS_CANCEL',
    defaultMessage: 'Cancel'
  },
  CART_CONTACT_INFO_CANCEL: {
    id: 'CART_CONTACT_INFO_CANCEL',
    defaultMessage: 'Cancel'
  },
  CART_QUICK_ADD_PREFERRED_DEALER: {
    id: 'CART_QUICK_ADD_PREFERRED_DEALER',
    defaultMessage: 'Quick add items will be added to the preferred dealer'
  },
  SET_PREFERRED_DEALER: {
    id: 'SET_PREFERRED_DEALER',
    defaultMessage: 'Set Preferred Dealer'
  },
  AVAILABILITY: {
    id: 'AVAILABILITY',
    defaultMessage: 'Availability'
  },
  PACK_SIZE: {
    id: 'PACK_SIZE',
    defaultMessage: 'Pack Size'
  },
  CANCEL_CART_NO: {
    id: 'CANCEL_CART_NO',
    defaultMessage: 'No'
  },
  CANCEL_CART_YES: {
    id: 'CANCEL_CART_YES',
    defaultMessage: 'Yes'
  },
  CANCEL_CART_CONFIRM_TEXT: {
    id: 'CANCEL_CART_CONFIRM_TEXT',
    defaultMessage: 'Are you sure you want to cancel?'
  },
  CLEAR_CART_CANCEL: {
    id: 'CLEAR_CART_CANCEL',
    defaultMessage: 'Cancel'
  },
  CLEAR_CART_OKAY: {
    id: 'CLEAR_CART_OKAY',
    defaultMessage: 'OK'
  },
  SELECT_SHIPPING_ADDRESS: {
    id: 'SELECT_SHIPPING_ADDRESS',
    defaultMessage: 'Select Shipping Address'
  },
  SEARCH: {
    id: 'SEARCH',
    defaultMessage: 'Search'
  },
  DOCUMENT_LIBRARY: {
    id: 'DOCUMENT_LIBRARY',
    defaultMessage: 'Document Library'
  },
  SEARCH_OPTIONS: {
    id: 'SEARCH_OPTIONS',
    defaultMessage: 'Search Options'
  },
  DATA_SPECIFIC_SEARCH: {
    id: 'DATA_SPECIFIC_SEARCH',
    defaultMessage: 'Data Specific Search'
  },
  ERROR_404_SDS_MESSAGE: {
    id: 'ERROR_404_SDS_MESSAGE',
    defaultMessage: 'We were unable to locate the SDS from your search.'
  },
  ERROR_404_QUICK_LINK_MESSAGE: {
    id: 'ERROR_404_QUICK_LINK_MESSAGE',
    defaultMessage: 'Here are some quick links you might like to visit:'
  },
  LESS: {
    id: 'LESS',
    defaultMessage: 'Less'
  },
  MORE: {
    id: 'MORE',
    defaultMessage: 'More'
  },
  CHINESE: {
    id: 'CHINESE',
    defaultMessage: '中文 - ZH'
  },
  ENGLISH: {
    id: 'ENGLISH',
    defaultMessage: 'English - EN'
  },
  LOGIN_TO_ADD_TO_CART: {
    id: 'LOGIN_TO_ADD_TO_CART',
    defaultMessage: 'Login To Add To Cart'
  },
  LINK_PROFILE: {
    id: 'LINK_PROFILE',
    defaultMessage: 'Link Profile'
  },
  QUANTITY: {
    id: 'QUANTITY',
    defaultMessage: 'Quantity'
  },
  PRICE: {
    id: 'PRICE',
    defaultMessage: 'Price'
  },
  LIST_PRICE: {
    id: 'LIST_PRICE',
    defaultMessage: 'List Price'
  },
  QUESTIONS: {
    id: 'QUESTIONS',
    defaultMessage: 'Questions?'
  },
  CHECK_AVAILABILITY_TRIGGER: {
    id: 'CHECK_AVAILABILITY_TRIGGER',
    defaultMessage: 'From'
  },
  SORRY: {
    id: 'SORRY',
    defaultMessage: 'Sorry'
  },
  ABBREVIATED_QUANTITY: {
    id: 'ABBREVIATED_QUANTITY',
    defaultMessage: 'Qty.'
  },
  ABBREVIATED_QUANTITY_WITHOUT_DOT: {
    id: 'ABBREVIATED_QUANTITY_WITHOUT_DOT',
    defaultMessage: 'Qty'
  },
  ITEM: {
    id: 'ITEM',
    defaultMessage: 'item(s)'
  },
  ITEM_SINGULAR: {
    id: 'ITEM_SINGULAR',
    defaultMessage: 'Item'
  },
  SIGN_UP: {
    id: 'SIGN_UP',
    defaultMessage: 'Sign Up'
  },
  LOGIN: {
    id: 'LOGIN',
    defaultMessage: 'Login'
  },
  TOP: {
    id: 'TOP',
    defaultMessage: 'Top'
  },
  CART_ERROR_REQUESTING_QUOTE: {
    id: 'CART_ERROR_REQUESTING',
    defaultMessage: 'An error occurred requesting quote: {errorMessage}'
  },
  CART_ADDRESS_ORDERING_PARTY_ADDRESS_TITLE: {
    id: 'CART_ADDRESS_SHIPPING_ADDRESS_TITLE',
    defaultMessage: 'Buyer'
  },
  SAP_CART_ITEM_ERROR_NOT_FOUND: {
    id: 'SAP_CART_ITEM_ERROR_NOT_FOUND',
    defaultMessage: 'We are sorry but item {itemNumber} is no longer available for sale. Please remove it from your shopping cart to continue.'
  },
  USER_LOGON_ID_OR_PASSWORD_EMPTY: {
    id: 'USER_INVALID_LOGIN_DETAILS',
    defaultMessage: 'Sorry, username or password is incorrect.'
  },
  FORGOT_PASSWORD_PASSWORD_EXPIRED: {
    id: 'FORGOT_PASSWORD_PASSWORD_EXPIRED',
    defaultMessage: 'Your password has expired. To reset your password, please enter your email address below to receive the reset link.'
  },
  SAVE_CART: {
    id: 'SAVE_CART',
    defaultMessage: 'Save Cart'
  },
  SAVE_CART_NAME: {
    id: 'SAVE_CART_NAME',
    defaultMessage: 'Cart Name'
  },
  SAVE_CART_COMMENT: {
    id: 'SAVE_CART_COMMENT',
    defaultMessage: 'Cart Comment'
  },
  CART_MASK_VALUE_TITLE: {
    id: 'CART_MASK_VALUE_TITLE',
    defaultMessage: 'Formatting Guide'
  },
  CART_MASK_HASH_VALUE_LABEL: {
    id: 'CART_MASK_HASH_VALUE_LABEL',
    defaultMessage: '#'
  },
  CART_MASK_HASH_VALUE_MESSAGE: {
    id: 'CART_MASK_HASH_VALUE_MESSAGE',
    defaultMessage: 'Numeric Character (0 through 9)'
  },
  CART_MASK_CARET_VALUE_LABEL: {
    id: 'CART_MASK_CARET_VALUE_LABEL',
    defaultMessage: '^'
  },
  CART_MASK_CARET_VALUE_MESSAGE: {
    id: 'CART_MASK_CARET_VALUE_MESSAGE',
    defaultMessage: 'Alpha Character (A through Z)'
  },
  CART_MASK_ASTERISK_VALUE_LABEL: {
    id: 'CART_MASK_ASTERISK_VALUE_LABEL',
    defaultMessage: '*'
  },
  CART_MASK_ASTERISK_VALUE_MESSAGE: {
    id: 'CART_MASK_ASTERISK_VALUE_MESSAGE',
    defaultMessage: 'Alphanumeric Character (A through Z or 0 through 9)'
  },
  CART_MASK_AMPERSAND_VALUE_LABEL: {
    id: 'CART_MASK_AMPERSAND_VALUE_LABEL',
    defaultMessage: '&&'
  },
  CART_MASK_AMPERSAND_VALUE_MESSAGE: {
    id: 'CART_MASK_AMPERSAND_VALUE_MESSAGE',
    defaultMessage: 'Data between ampersands is a constant value'
  },
  CART_MASK_AMPERSAND_VALUE_MESSAGE_HELPER: {
    id: 'CART_MASK_AMPERSAND_VALUE_MESSAGE_HELPER',
    defaultMessage: 'do not enter the ampersand when providing the value'
  },
  CART_MASK_SPACE_VALUE_LABEL: {
    id: 'CART_MASK_SPACE_VALUE_LABEL',
    defaultMessage: 'Space'
  },
  CART_MASK_SPACE_VALUE_MESSAGE: {
    id: 'CART_MASK_SPACE_VALUE_MESSAGE',
    defaultMessage: 'Indicates break between data'
  },
  CART_MASK_BLANK_VALUE_LABEL: {
    id: 'CART_MASK_BLANK_VALUE_LABEL',
    defaultMessage: 'Blank'
  },
  CART_MASK_BLANK_VALUE_MESSAGE: {
    id: 'CART_MASK_BLANK_VALUE_MESSAGE',
    defaultMessage: 'No restrictions on format'
  },
  PAYMENT_DEALICENSE_NUMBER_MASKING_ERROR_SNACKBAR: {
    id: 'PAYMENT_DEALICENSE_NUMBER_MASKING_ERROR_SNACKBAR',
    defaultMessage: 'You must enter the DEA License Number in the correct format.'
  },
  PO_MARKETPLACE_PAYMENT: {
    id: 'PO_MARKETPLACE_PAYMENT',
    defaultMessage: 'PO Marketplace Payment'
  },
  PO_MARKETPLACE_DEFAULT_DESCRIPTION: {
    id: 'PO_MARKETPLACE_DEFAULT_DESCRIPTION',
    defaultMessage: 'A new financing option for Marketplace orders'
  },
  PO_MARKETPLACE_PENDING_DESCRIPTION: {
    id: 'PO_MARKETPLACE_PENDING_DESCRIPTION',
    defaultMessage: 'Application Pending'
  },
  PO_MARKETPLACE_REJECTED_DESCRIPTION: {
    id: 'PO_MARKETPLACE_REJECTED_DESCRIPTION',
    defaultMessage: 'Application Rejected'
  },
  PO_MARKETPLACE_REGISTERED_DESCRIPTION: {
    id: 'PO_MARKETPLACE_REGISTERED_DESCRIPTION',
    defaultMessage: 'Application Registered'
  },
  PO_MARKETPLACE_DISABLED_DESCRIPTION: {
    id: 'PO_MARKETPLACE_DISABLED_DESCRIPTION',
    defaultMessage: 'Application Suspended'
  },
  PO_MARKETPLACE_AVAILABLE_CREDIT: {
    id: 'PO_MARKETPLACE_AVAILABLE_CREDIT',
    defaultMessage: 'Available Credit:'
  },
  PO_MARKETPLACE_CREDIT_LIMIT: {
    id: 'PO_MARKETPLACE_CREDIT_LIMIT',
    defaultMessage: 'Credit Limit:'
  },
  PO_PREFER_TO_COMPLETE_YOUR_PURCHASE_LATER: {
    id: 'PO_PREFER_TO_COMPLETE_YOUR_PURCHASE_LATER',
    defaultMessage: 'Prefer to complete your purchase later?'
  },
  PO_YOU_WILL_RECEIVE_A_DECISION_VIA_EMAIL_WITHIN_TWO_BUSINESS_DAYS: {
    id: 'PO_YOU_WILL_RECEIVE_A_DECISION_VIA_EMAIL_WITHIN_TWO_BUSINESS_DAYS',
    defaultMessage: 'You will receive a decision via email within 2 business days.'
  },
  PO_CUSTOMER_DOES_NOT_HAVE_ROLES_TO_USE_TREVIPAY: {
    id: 'PO_CUSTOMER_DOES_NOT_HAVE_ROLES_TO_USE_TREVIPAY',
    defaultMessage: "You don't have the correct roles to use TreviPay. For further assistance, please contact Customer Service at"
  },
  PO_FAILED_AT_TREVIPAY_API: {
    id: 'PO_FAILED_AT_TREVIPAY_API',
    defaultMessage: 'An error occurred when loading TreviPay. Please refresh the page and try again.'
  },
  PO_FOR_FURTHER_ASSISTANCE_PLEASE_CONTACT_CUSTOMER_SERVICE_AT: {
    id: 'PO_FOR_FURTHER_ASSISTANCE_PLEASE_CONTACT_CUSTOMER_SERVICE_AT',
    defaultMessage: 'For further assistance, please contact Customer Service at'
  },
  PO_ORGANIZATION_HAS_CREDIT: {
    id: 'PO_ORGANIZATION_HAS_CREDIT',
    defaultMessage: 'Your organization has a credit line available, please contact {name} {phoneNumber} to get permissions to use the credit line for placing orders.'
  },
  PO_ORGANIZATION_DISABLED: {
    id: 'PO_ORGANIZATION_DISABLED',
    defaultMessage: 'Your TreviPay line of credit is currently in suspended status. To reinstate your account, please contact TreviPay at +1 (877) 278-2165.'
  },
  PO_GET_APPROVED_FOR_A_DEDICATED_LINE_OF_CREDIT_ON_MARKETPLACE_ITEMS_IN_AS_LITTLE_AS_30_SECONDS: {
    id: 'PO_GET_APPROVED_FOR_A_DEDICATED_LINE_OF_CREDIT_ON_MARKETPLACE_ITEMS_IN_AS_LITTLE_AS_30_SECONDS',
    defaultMessage: 'Get approved for a dedicated line of credit on Marketplace items in as little as 30 seconds.'
  },
  DRAW_STRUCTURE: {
    id: 'DRAW_STRUCTURE',
    defaultMessage: 'Draw Structure'
  },
  RESET: {
    id: 'RESET',
    defaultMessage: 'Reset'
  },
  RECENT_SEARCHES: {
    id: 'RECENT_SEARCHES',
    defaultMessage: 'Recent Searches'
  },
  NO_SKETCHER_CONTENT_CAPTURED: {
    id: 'NO_SKETCHER_CONTENT_CAPTURED',
    defaultMessage: 'No sketcher content captured'
  },
  STRUCTURE_QUERY_IMAGE_ALT: {
    id: 'STRUCTURE_QUERY_IMAGE_ALT',
    defaultMessage: 'Structure query image. Only available when generated direct from editor'
  },
  STRUCTURE_CRITERIA: {
    id: 'STRUCTURE_CRITERIA',
    defaultMessage: 'Structure Criteria'
  },
  SEARCH_TIPS: {
    id: 'SEARCH_TIPS',
    defaultMessage: 'Search Tips'
  },
  COULDNT_FIND_MATCHES: {
    id: 'COULDNT_FIND_MATCHES',
    defaultMessage: "Sorry, we couldn't find any matches for"
  },
  SHOP_BY_PRODUCT_CATEGORY: {
    id: 'SHOP_BY_PRODUCT_CATEGORY',
    defaultMessage: 'Shop by Product Category'
  },
  SEARCH_PRODUCT_CATEGORY: {
    id: 'SEARCH_PRODUCT_CATEGORY',
    defaultMessage: 'Search Product Category'
  },
  CANT_FIND_PRODUCT: {
    id: 'CANT_FIND_PRODUCT',
    defaultMessage: "Can't find the product in our catalog?"
  },
  MAKE_A_SUGGESTION: {
    id: 'MAKE_A_SUGGESTION',
    defaultMessage: 'Make a suggestion'
  },
  MAKE_A_SUGGESTION_MESSAGE: {
    id: 'MAKE_A_SUGGESSTION_MESSAGE',
    defaultMessage: 'If you are unable to find a product you need in our web catalog, you can make a suggestion for the item through our site.'
  },
  WORDS_SPELLED_CORRECTLY: {
    id: 'WORDS_SPELLED_CORRECTLY',
    defaultMessage: 'Make sure all the words are spelled correctly'
  },
  REMOVE_SPACES: {
    id: 'REMOVE_SPACES',
    defaultMessage: 'Remove spaces and symbols'
  },
  TRY_REPHRASING: {
    id: 'TRY_REPHRASING',
    defaultMessage: 'Try rephrasing keywords or using synonyms'
  },
  TRY_STRUCTURE_SEARCH: {
    id: 'TRY_STRUCTURE_SEARCH',
    defaultMessage: 'Try our structure search or advanced search tool.'
  },
  TOOL: {
    id: 'TOOL',
    defaultMessage: 'tool'
  },
  OR: {
    id: 'OR',
    defaultMessage: 'or'
  },
  TRY: {
    id: 'TRY',
    defaultMessage: 'Try'
  },
  SEARCH_BUILDING_BLOCKS: {
    id: 'SEARCH_BUILDING_BLOCKS',
    defaultMessage: 'Search Building Blocks'
  },
  WHY_TO_TRY_BUILDING_BLOCK: {
    id: 'WHY_TO_TRY_BUILDING_BLOCK',
    defaultMessage: 'Try searching in Building Blocks Explorer, includes direct access to hundreds of thousands of reliably available synthetic building block products from selected global suppliers and expanded catalog.'
  },
  NO_RESULTS_ON_BUILDING_BLOCKS: {
    id: 'NO_RESULTS_ON_BUILDING_BLOCKS',
    defaultMessage: "Sorry, we couldn't find any matches within Building Blocks Explorer."
  },
  NO_RESULTS_ON_STRUCTURE_SEARCH: {
    id: 'NO_RESULTS_ON_STRUCTURE_SEARCH',
    defaultMessage: "Sorry, we couldn't find any matches for your structure."
  },
  REVIEW_YOUR_STRUCTURE: {
    id: 'REVIEW_YOUR_STRUCTURE',
    defaultMessage: 'Review your structure for missing or misplaced heteroatoms or functional groups.'
  },
  SIMPLIFY_STRUCTURE: {
    id: 'SIMPLIFY_STRUCTURE',
    defaultMessage: 'Simplify structure, if possible, by removing functional groups in reverse order of their importance.'
  },
  MAKE_SEARCH_LESS_SPECIFIC: {
    id: 'MAKE_SEARCH_LESS_SPECIFIC',
    defaultMessage: 'Make the search less specific. Search specificity decreases from left to right for these settings:'
  },
  UPDATE_SEARCH_TYPE: {
    id: 'UPDATE_SEARCH_TYPE',
    defaultMessage: 'Update Search Type: Exact'
  },
  TRY_KEYWORDS: {
    id: 'TRY_KEYWORDS',
    defaultMessage: 'Try different or generic keywords'
  },
  SUBSTRUCTURE: {
    id: 'SUBSTRUCTURE',
    defaultMessage: 'Substructure'
  },
  MOLECULAR_WEIGHT_ABBREV: {
    id: 'MOLECULAR_WEIGHT_ABBREV',
    defaultMessage: 'MW'
  },
  MOLECULAR_FORMULA_ABBREV: {
    id: 'MOLECULAR_FORMULA_ABBREV',
    defaultMessage: 'MF'
  },
  CAS_NUMBER_ABBREV: {
    id: 'CAS_NUMBER_ABBREV',
    defaultMessage: 'CAS'
  },
  SIMILARITY: {
    id: 'SIMILARITY',
    defaultMessage: 'Similarity'
  },
  product_number: {
    id: 'product_number',
    defaultMessage: 'Product Number'
  },
  product_name: {
    id: 'product_name',
    defaultMessage: 'Product Name'
  },
  PRODUCT_NAME: {
    id: 'PRODUCT_NAME',
    defaultMessage: 'Product Name'
  },
  cas_number: {
    id: 'cas_number',
    defaultMessage: 'CAS Number'
  },
  CAS_NO: {
    id: 'CAS_NO',
    defaultMessage: 'CAS No.'
  },
  mol_form: {
    id: 'mol_form',
    defaultMessage: 'Molecular Formula'
  },
  MOL_FORM: {
    id: 'MOL_FORM',
    defaultMessage: 'Molecular Formula'
  },
  mdl_number: {
    id: 'mdl_number',
    defaultMessage: 'MDL Number'
  },
  fema_number: {
    id: 'fema_number',
    defaultMessage: 'Fema Number'
  },
  FEMA_NUMBER: {
    id: 'FEMA_NUMBER',
    defaultMessage: 'Fema Number'
  },
  color_index: {
    id: 'color_index',
    defaultMessage: 'Color Index'
  },
  COLOR_INDEX: {
    id: 'COLOR_INDEX',
    defaultMessage: 'Color Index'
  },
  egec_number: {
    id: 'egec_number',
    defaultMessage: 'EG/EC Number'
  },
  EGEC_NUMBER: {
    id: 'EGEC_NUMBER',
    defaultMessage: 'EG/EC Number'
  },
  ec_number: {
    id: 'ec_number',
    defaultMessage: 'Enzyme Commission number'
  },
  EC_NUMBER: {
    id: 'EC_NUMBER',
    defaultMessage: 'EC Number'
  },
  TRC_NUMBER: {
    id: 'TRC_NUMBER',
    defaultMessage: 'TRC Number'
  },
  trc_number: {
    id: 'trc_number',
    defaultMessage: 'TRC Number'
  },
  refseq: {
    id: 'refseq',
    defaultMessage: 'RefSeq'
  },
  REFSEQ: {
    id: 'REFSEQ',
    defaultMessage: 'RefSeq'
  },
  GENE_DESC: {
    id: 'GENE_DESC',
    defaultMessage: 'Gene Description'
  },
  gene_desc: {
    id: 'gene_desc',
    defaultMessage: 'Gene Description'
  },
  gene_symbol: {
    id: 'gene_symbol',
    defaultMessage: 'Gene Symbol'
  },
  gene_id: {
    id: 'gene_id',
    defaultMessage: 'Gene ID'
  },
  ADD_CHECKOUT_ITEM: {
    id: 'ADD_CHECKOUT_ITEM',
    defaultMessage: 'Add To Your Shopping Cart'
  },
  MATERIAL_NUMBER_SKU: {
    id: 'MATERIAL_NUMBER_SKU',
    defaultMessage: 'Material Number / SKU'
  },
  DELETE_SHOPPING_CART: {
    id: 'DELETE_SHOPPING_CART',
    defaultMessage: 'Clear this cart'
  },
  AGREE: {
    id: 'AGREE',
    defaultMessage: 'Agree'
  },
  DISAGREE: {
    id: 'DISAGREE',
    defaultMessage: 'Disagree'
  },
  CANCEL_CART_MODAL_DESCRIPTION: {
    id: 'CANCEL_CART_MODAL_DESCRIPTION',
    defaultMessage: 'Are you sure you want to clear this cart and all of its items?'
  },
  CANCEL_MARKETPLACE_CART_MODAL_DESCRIPTION: {
    id: 'CANCEL_MARKETPLACE_CART_MODAL_DESCRIPTION',
    defaultMessage: 'Are you sure you want to clear this Marketplace Cart and all of its items?'
  },
  UPDATE_TOTALS: {
    id: 'UPDATE_TOTALS',
    defaultMessage: 'Update Totals'
  },
  CART_PROMO_CODE: {
    id: 'CART_PROMO_CODE',
    defaultMessage: 'Cart Promo Code'
  },
  UPDATING_CART: {
    id: 'UPDATING_CART',
    defaultMessage: 'Updating Cart'
  },
  DELETING_CART: {
    id: 'DELETING_CART',
    defaultMessage: 'Deleting Cart'
  },
  ADDING_ITEMS_TO_CART: {
    id: 'ADDING_ITEMS_TO_CART',
    defaultMessage: 'Adding Items to Cart'
  },
  REQUESTING_QUOTE: {
    id: 'REQUESTING_QUOTE',
    defaultMessage: 'Requesting Quote'
  },
  MIN_2_NUMBERS: {
    id: 'MIN_2_NUMBERS',
    defaultMessage: 'must be a minimum of 2 numbers'
  },
  UNABLE_TO_LOGIN_B2B_MESSAGE: {
    id: 'UNABLE_TO_LOGIN_B2B_MESSAGE',
    defaultMessage: 'Unable to Login into the application.'
  },
  BUNDLE_AND_SAVE: {
    id: 'BUNDLE_AND_SAVE',
    defaultMessage: 'Bundle & Save'
  },
  BUNDLE_AND_SAVE_DESCRIPTION: {
    id: 'BUNDLE_AND_SAVE_DESCRIPTION',
    defaultMessage: 'Purchase your selected item in bundle with complementing workflow components to receive a discount for the complete product bundle'
  },
  EXPAND: {
    id: 'EXPAND',
    defaultMessage: 'Expand'
  },
  HIDE: {
    id: 'HIDE',
    defaultMessage: 'Hide'
  },
  EXPAND_TO_VIEW_PRICING: {
    id: 'EXPAND_TO_VIEW_PRICING',
    defaultMessage: 'Expand to view pricing'
  },
  NEW_FEATURE_QUICK_VIEW: {
    id: 'NEW_FEATURE_QUICK_VIEW',
    defaultMessage: 'New: Hover over an item line for a Quick View'
  },
  QUICK_VIEW: {
    id: 'QUICK_VIEW',
    defaultMessage: 'Quick View'
  },
  REQUIRED_FOR_CHECKOUT: {
    id: 'REQUIRED_FOR_CHECKOUT',
    defaultMessage: 'Required for Checkout'
  },
  REQUIRED_FOR_QUOTE: {
    id: 'REQUIRED_FOR_QUOTE',
    defaultMessage: 'Required for Quote'
  },
  AVAILABLE_IN_KIT_AT_DISCOUNTED_PRICE: {
    id: 'AVAILABLE_IN_KIT_AT_DISCOUNTED_PRICE',
    defaultMessage: 'Buy in a Bundle'
  },
  SUPPORT: {
    id: 'SUPPORT',
    defaultMessage: 'Support'
  },
  INVENTORY_BATCH_SEARCH: {
    id: 'INVENTORY_BATCH_SEARCH',
    defaultMessage: 'Inventory Batch Search'
  },
  PRESS_UPDATE_CART_TO_CONTINUE: {
    id: 'PRESS_UPDATE_CART_TO_CONTINUE',
    defaultMessage: "Please press the 'Update Cart' button to remove this item from your cart."
  },
  EDIT_QUERY: {
    id: 'EDIT_QUERY',
    defaultMessage: 'Edit Query'
  },
  SEARCH_INVENTORY_BATCH: {
    id: 'SEARCH_INVENTORY_BATCH',
    defaultMessage: 'Search Inventory Batch'
  },
  SELECT_A_PRODUCT_NUMBER: {
    id: 'SELECT_A_PRODUCT_NUMBER',
    defaultMessage: 'Select a Product Number'
  },
  DONT_SEE_YOUR_PRODUCT_NUMBER: {
    id: 'DONT_SEE_YOUR_PRODUCT_NUMBER',
    defaultMessage: "Don't see your Product Number?"
  },
  SELECT_PRODUCT: {
    id: 'SELECT_PRODUCT',
    defaultMessage: 'Select Product'
  },
  INVALID_PRODUCT_NUMBER: {
    id: 'INVALID_PRODUCT_NUMBER',
    defaultMessage: 'Invalid product number. Please input valid product number and try again.'
  },
  PRODUCT_NUMBER_REQUIRED: {
    id: 'PRODUCT_NUMBER_REQUIRED',
    defaultMessage: 'Product number is required.'
  },
  BATCH_LOT: {
    id: 'BATCH_LOT',
    defaultMessage: 'Batch/Lot'
  },
  UNITS: {
    id: 'UNITS',
    defaultMessage: 'Units'
  },
  WAREHOUSE: {
    id: 'WAREHOUSE',
    defaultMessage: 'Warehouse'
  },
  EXPIRATION: {
    id: 'EXPIRATION',
    defaultMessage: 'Expiration'
  },
  GLOBAL_ITEM_STATUS: {
    id: 'GLOBAL_ITEM_STATUS',
    defaultMessage: 'Global Item Status'
  },
  QUOTE_CONFIRMATION_SCREENING_NOTE: {
    id: 'QUOTE_CONFIRMATION_SCREENING_NOTE',
    defaultMessage: 'Compliance/Screening Notes: This order may require review prior to shipping.'
  },
  ENTER_NEW_LIST_NAME: {
    id: 'ENTER_NEW_LIST_NAME',
    defaultMessage: 'Enter New List Name'
  },
  ADD_ITEMS_TO_LIST: {
    id: 'ADD_ITEMS_TO_LIST',
    defaultMessage: 'Add items to this list. Enter product SKU, complete with package size'
  },
  CUSTOM_BUNDLE_BUTTON_TEXT: {
    id: 'CUSTOM_BUNDLE_BUTTON_TEXT',
    defaultMessage: 'Need more custom Bundle options?'
  },
  NEED_MORE_BUNDLE_BUTTON_TEXT: {
    id: 'NEED_MORE_BUNDLE_BUTTON_TEXT',
    defaultMessage: 'Need more bundle options?'
  },
  CREATE_CUSTOM_BUNDLE: {
    id: 'CREATE_CUSTOM_BUNDLE',
    defaultMessage: 'Create Custom Bundle'
  },
  CUSTOM_BUNDLE_EXPLANATION: {
    id: 'CUSTOM_BUNDLE_EXPLANATION',
    defaultMessage: 'Enter a description and a minimum of two bundle items to request a custom promotional bundle.'
  },
  THIS_PRODUCT_HAS_BEEN_DISCONTINUED: {
    id: 'THIS_PRODUCT_HAS_BEEN_DISCONTINUED',
    defaultMessage: 'Product {productNumber} has been discontinued.'
  },
  CUSTOM_BUNDLE_DESCRIPTION: {
    id: 'CUSTOM_BUNDLE_DESCRIPTION',
    defaultMessage: 'Bundle Description'
  },
  CUSTOM_BUNDLE_REQUEST_SUCCESS: {
    id: 'CUSTOM_BUNDLE_REQUEST_SUCCESS',
    defaultMessage: 'Successfully requested custom promotional bundle. We will contact you shortly.'
  },
  SKU_PLUS_PACK_SIZE: {
    id: 'SKU_PLUS_PACK_SIZE',
    defaultMessage: 'SKU+Pack Size'
  },
  ADD_ROW: {
    id: 'ADD_ROW',
    defaultMessage: 'Add Row'
  },
  CUSTOM_BUNDLE_ITEMS_ERROR: {
    id: 'CUSTOM_BUNDLE_ITEMS_ERROR',
    defaultMessage: "Two or more SKU's required to submit"
  },
  BUNDLE_UNAVAILABLE: {
    id: 'BUNDLE_UNAVAILABLE',
    defaultMessage: 'Sorry! This Promotional Bundle might not be available in\n    your country.'
  },
  CSR_PARTNER_ERROR: {
    id: 'CSR_PARTNER_ERROR',
    defaultMessage: 'There is an error retrieving one or more Partners for this Account. Please select a different SoldTo.'
  },
  SALES_ORDER_SOURCE: {
    id: 'SALES_ORDER_SOURCE',
    defaultMessage: 'Sales Order Source'
  },
  SOLD_TO: {
    id: 'SOLD_TO',
    defaultMessage: 'Sold To'
  },
  ATTENTION_TO: {
    id: 'ATTENTION_TO',
    defaultMessage: 'Attention To'
  },
  PAYER: {
    id: 'PAYER',
    defaultMessage: 'Payer'
  },
  NO_RECORD_FOUND: {
    id: 'NO_RECORD_FOUND',
    defaultMessage: 'No record found'
  },
  CART: {
    id: 'CART',
    defaultMessage: 'Cart'
  },
  MANUAL_ENTRY: {
    id: 'MANUAL_ENTRY',
    defaultMessage: 'Manual Entry'
  },
  BULK_UPLOAD: {
    id: 'BULK_UPLOAD',
    defaultMessage: 'Bulk Upload'
  },
  ADD_FIVE_MORE_ROWS: {
    id: 'ADD_FIVE_MORE_ROWS',
    defaultMessage: 'Add 5 More Rows'
  },
  ADD_ITEMS: {
    id: 'ADD_ITEMS',
    defaultMessage: 'Add Items'
  },
  TYPE: {
    id: 'TYPE',
    defaultMessage: 'Type'
  },
  ITEMS: {
    id: 'ITEMS',
    defaultMessage: 'Items'
  },
  APPROVAL_AMOUNT: {
    id: 'APPROVAL_AMOUNT',
    defaultMessage: 'Approval Amount'
  },
  AMOUNT: {
    id: 'AMOUNT',
    defaultMessage: 'Amount'
  },
  AMOUNT_LIMIT: {
    id: 'AMOUNT_LIMIT',
    defaultMessage: 'Amount Limit'
  },
  MANUAL_ENTRY_INSTRUCTIONS: {
    id: 'MANUAL_ENTRY_INSTRUCTIONS',
    defaultMessage: 'Enter the product SKU (product number and pack size) and quantity for each item you would like to order.'
  },
  BULK_UPLOAD_ENTRY_EXAMPLE: {
    id: 'BULK_UPLOAD_ENTRY_EXAMPLE',
    defaultMessage: 'Example:\nT1503-100G, 1\nA7000-25G-A, 10\nW4502-1L, 1\nT1503-500G,'
  },
  BULK_UPLOAD_ERROR_BAD_QTY: {
    id: 'BULK_UPLOAD_ERROR_BAD_QTY',
    defaultMessage: 'Invalid row: "{row}"; quantity value must be numeric and no more than 9999'
  },
  BULK_UPLOAD_ERROR_NO_SKU: {
    id: 'BULK_UPLOAD_ERROR_NO_SKU',
    defaultMessage: 'Invalid row: "{row}"; first value must contain a product SKU'
  },
  BULK_UPLOAD_ERROR_NUM_COLUMNS: {
    id: 'BULK_UPLOAD_ERROR_NUM_COLUMNS',
    defaultMessage: 'Invalid row: "{row}"; must be one through three values per row, separated by a comma'
  },
  BULK_UPLOAD_ERROR_MAX_PRODUCTS: {
    id: 'BULK_UPLOAD_ERROR_MAX_PRODUCTS',
    defaultMessage: 'Bulk upload limited to 50 items at a time'
  },
  PACK_SIZE_QUANTITY: {
    id: 'PACK_SIZE_QUANTITY',
    defaultMessage: 'Pack Size/Quantity'
  },
  ENTER_COQ: {
    id: 'ENTER_COQ',
    defaultMessage: 'Enter Lot Number to search for Certificate of Quality (COQ).'
  },
  MILLIPORE_PRODUCTS: {
    id: 'MILLIPORE_PRODUCTS',
    defaultMessage: 'Millipore Products'
  },
  MILLIPORE_PRODUCTS_INSTRUCTIONS: {
    id: 'MILLIPORE_PRODUCTS_INSTRUCTIONS',
    defaultMessage: 'For assistance obtaining the Lot or Batch number for Millipore Products, please contact '
  },
  COQ_NOT_FOUND: {
    id: 'COQ_NOT_FOUND',
    defaultMessage: 'COQ Not Found'
  },
  COQ_NOT_FOUND_INSTRUCTIONS: {
    id: 'COQ_NOT_FOUND_INSTRUCTIONS',
    defaultMessage: 'In some cases, a COQ may not be available online. If your search was unable to find the COQ you are looking for, you will be presented with a COQ Request form. In many cases a COQ can be faxed to you upon submission of this form.'
  },
  WHERE_TO_FIND_BATCH_NUMBERS: {
    id: 'WHERE_TO_FIND_BATCH_NUMBERS',
    defaultMessage: "Lot and Batch Numbers can be found on a product's label following the words 'Lot' or 'Batch'."
  },
  ALDRICH_PRODUCTS: {
    id: 'ALDRICH_PRODUCTS',
    defaultMessage: 'Aldrich Products'
  },
  LONG_DELIVERY_IT: {
    id: 'LONG_DELIVERY_IT',
    defaultMessage: 'Long delivery time, special transport requirements needed. For more information please contact Customer Support.'
  },
  ENTER_WITHOUT_FIRST_TWO_LETTERS: {
    id: 'ENTER_WITHOUT_FIRST_TWO_LETTERS',
    defaultMessage: "For a lot number such as TO09019TO, enter it as 09019TO (without the first two letters 'TO')."
  },
  ENTER_WITHOUT_NUMERIC_FILLING_CODE: {
    id: 'ENTER_WITHOUT_NUMERIC_FILLING_CODE',
    defaultMessage: "For a lot number with a filling-code such as 05427ES-021, enter it as 05427ES (without the filling-code '-021')."
  },
  ENTER_WITHOUT_ALPHA_NUMERIC_FILLING_CODE: {
    id: 'ENTER_WITHOUT_ALPHA_NUMERIC_FILLING_CODE',
    defaultMessage: "For a lot number with a filling-code such as STBB0728K9, enter it as STBB0728 without the filling-code 'K9'."
  },
  UNABLE_TO_FIND_CERTIFICATE: {
    id: 'UNABLE_TO_FIND_CERTIFICATE',
    defaultMessage: 'We are unable to find the certificate you are searching for.'
  },
  CERTIFICATE_TYPE_NOT_FOUND: {
    id: 'CERTIFICATE_TYPE_NOT_FOUND',
    defaultMessage: '{certificateType} Not Found'
  },
  SIGMA_PRODUCTS: {
    id: 'SIGMA_PRODUCTS',
    defaultMessage: 'Sigma Products'
  },
  SIGMA_PRODUCTS_INSTRUCTIONS: {
    id: 'SIGMA_PRODUCTS_INSTRUCTIONS',
    defaultMessage: "If the letter is preceded by only two numbers (e.g. 62K1064), insert a '0' to the beginning and enter it as 062K1064."
  },
  REQUEST_A_COO: {
    id: 'REQUEST_A_COO',
    defaultMessage: 'request a COO'
  },
  DETAILS: {
    id: 'DETAILS',
    defaultMessage: 'Details'
  },
  PROMO_CODE_APPLIED_IN_CART: {
    id: 'PROMO_CODE_APPLIED_IN_CART',
    defaultMessage: 'Promo Code pricing will be applied in Cart'
  },
  LEFT_MENU_ORDERS: {
    id: 'LEFT_MENU_ORDERS',
    defaultMessage: 'Orders'
  },
  APPROVE_INVOICES: {
    id: 'APPROVE_INVOICES',
    defaultMessage: 'Approve Invoices'
  },
  LIST: {
    id: 'LIST',
    defaultMessage: 'List'
  },
  LIST_IS_EMPTY: {
    id: 'LIST_IS_EMPTY',
    defaultMessage: 'Your List is Empty'
  },
  LIST_IS_EMPTY_DESCRIPTION: {
    id: 'LIST_IS_EMPTY_DESCRIPTION',
    defaultMessage: 'There are lots of ways you can add to your list. Get started by entering product SKUs with Quick Add or try adding to your list directly from search.'
  },
  LIST_ITEMS_CAN_NOT_BE_ADDED_TO_QUOTE: {
    id: 'LIST_ITEMS_CAN_NOT_BE_ADDED_TO_QUOTE',
    defaultMessage: 'Some items in this list cannot be added to a quote. Please deselect items to request a quote.'
  },
  LIST_ITEM_DOES_NOT_CONTAIN_PRICING: {
    id: 'LIST_ITEM_DOES_NOT_CONTAIN_PRICING',
    defaultMessage: '{materialNumber} - Item does not contain pricing.'
  },
  LIST_ITEM_IS_MARKETPLACE: {
    id: 'LIST_ITEM_IS_MARKETPLACE',
    defaultMessage: '{materialNumber} - Marketplace items cannot be added to quotes.'
  },
  LIST_ITEM_IS_THIRD_PARTY: {
    id: 'LIST_ITEM_IS_THIRD_PARTY',
    defaultMessage: '{materialNumber} - Third-Party items cannot be added to quotes.'
  },
  UNSAVED_CHANGES: {
    id: 'UNSAVED_CHANGES',
    defaultMessage: 'You have unsaved changes, are you sure?'
  },
  SHARED_LIST: {
    id: 'SHARED_LIST',
    defaultMessage: 'Shared List'
  },
  CREATE_LIST: {
    id: 'CREATE_LIST',
    defaultMessage: 'Create List'
  },
  CREATE_SHARED_LIST_FAILED: {
    id: 'CREATE_SHARED_LIST_FAILED',
    defaultMessage: 'Items added successfully, but we were unable to create a new list. Please try again.'
  },
  ADD_ITEMS_TO_LIST_FAILED: {
    id: 'ADD_ITEMS_TO_LIST_FAILED',
    defaultMessage: 'New list created, but we were unable to add this item to your other list(s). Please try again.'
  },
  LIST_DELETED: {
    id: 'LIST_DELETED',
    defaultMessage: 'List deleted'
  },
  LIST_LEFT: {
    id: 'LIST_LEFT',
    defaultMessage: "You've left \"{listName}\""
  },
  LIST_NAME: {
    id: 'LIST_NAME',
    defaultMessage: 'List Name'
  },
  LIST_NAME_PLACEHOLDER: {
    id: 'LIST_NAME_PLACEHOLDER',
    defaultMessage: 'University Biology Shared List'
  },
  LIST_DESCRIPTION: {
    id: 'LIST_DESCRIPTION',
    defaultMessage: 'List Description'
  },
  YOU_HAVE_ACCEPTED: {
    id: 'YOU_HAVE_ACCEPTED',
    defaultMessage: "You've accepted"
  },
  YOU_HAVE_DECLINED: {
    id: 'YOU_HAVE_DECLINED',
    defaultMessage: "You've declined"
  },
  DECLINE_INVITE: {
    id: 'DECLINE_INVITE',
    defaultMessage: 'Decline Invite'
  },
  CONFIRM_DECLINE_INVITE_HEADER: {
    id: 'CONFIRM_DECLINE_INVITE_HEADER',
    defaultMessage: 'Are you sure you want to decline invite?'
  },
  CONFIRM_DECLINE_INVITE_BODY: {
    id: 'CONFIRM_DECLINE_INVITE_BODY',
    defaultMessage: 'If you decline this invite you will no longer be able to join or contribute to {name}. If you want to rejoin the list a contributor will have to add you to the list again.'
  },
  LIST_MEMBER_IM_DONE: {
    id: 'LIST_MEMBER_IM_DONE',
    defaultMessage: "I'm Done"
  },
  LIST_DONE_ADDING: {
    id: 'LIST_DONE_ADDING',
    defaultMessage: 'Done Adding'
  },
  EXPLORE_LATER: {
    id: 'EXPLORE_LATER',
    defaultMessage: 'Explore Later'
  },
  NOTE: {
    id: 'NOTE',
    defaultMessage: 'Note'
  },
  ADD_A_NOTE: {
    id: 'ADD_A_NOTE',
    defaultMessage: 'Add a Note'
  },
  EDIT_NOTE: {
    id: 'EDIT_NOTE',
    defaultMessage: 'Edit Note'
  },
  ADDED_BY: {
    id: 'ADDED_BY',
    defaultMessage: 'Added by'
  },
  LIST_DESCRIPTION_PLACEHOLDER: {
    id: 'LIST_DESCRIPTION_PLACEHOLDER',
    defaultMessage: 'Collective List for Biology Department'
  },
  INVITE_MEMBERS: {
    id: 'INVITE_MEMBERS',
    defaultMessage: 'Invite Members'
  },
  INVITE_MEMBERS_HELP: {
    id: 'INVITE_MEMBERS_HELP',
    defaultMessage: 'Invite colleagues to contribute to your shopping list for group orders.'
  },
  INVITE_MEMBERS_HELP_2: {
    id: 'INVITE_MEMBERS_HELP_2',
    defaultMessage: 'Leave notes on important products for buyers and view comments from other collaborators.'
  },
  INVITED_BY: {
    id: 'INVITED_BY',
    defaultMessage: 'You were invited by {name}'
  },
  OPEN_CART: {
    id: 'OPEN_CART',
    defaultMessage: 'Open Cart'
  },
  EDIT_NAME: {
    id: 'EDIT_NAME',
    defaultMessage: 'Edit Name'
  },
  DELETE_CART: {
    id: 'DELETE_CART',
    defaultMessage: 'Delete Cart'
  },
  SAVED: {
    id: 'SAVED',
    defaultMessage: 'Saved'
  },
  IS_CHROME_WARNING: {
    id: 'IS_CHROME_WARNING',
    defaultMessage: 'Some features of this page are not supported by the Google Chrome web browser. Please open this page in Firefox to access the full functionality of this page.'
  },
  BASIC_DATA: {
    id: 'BASIC_DATA',
    defaultMessage: 'Basic Data'
  },
  CAS_NUMBERS: {
    id: 'CAS_NUMBERS',
    defaultMessage: 'CAS Numbers'
  },
  STRUCTURES: {
    id: 'STRUCTURES',
    defaultMessage: 'Structures'
  },
  PORTAL_TEXT: {
    id: 'PORTAL_TEXT',
    defaultMessage: 'The data files to which this site links are subject to a confidentiality agreements between your organization and {legalCompanyName}, and possible third parties. Downloading any or all of these files constitutes your reaffirmation(1) to be bound by the agreement(s) and (2) to assure that any other person in your organization who is given access to the data, in part or in full, will also be bound by the agreement(s).'
  },
  PAGE: {
    id: 'PAGE',
    defaultMessage: 'Page'
  },
  _PC_SPEC_DRY: {
    id: '_PC_SPEC_DRY',
    defaultMessage: 'Dry'
  },
  _PC_SPEC_IS_WATER: {
    id: '_PC_SPEC_IS_WATER',
    defaultMessage: 'In Solution (Water)'
  },
  _PC_SPEC_IS_TE: {
    id: '_PC_SPEC_IS_TE',
    defaultMessage: 'In Solution (TE)'
  },
  _PC_SPEC_IS_TRIS: {
    id: '_PC_SPEC_IS_TRIS',
    defaultMessage: 'In Solution (TRIS)'
  },
  CONF_DNAPLATES_CONCENTRATION_MEASURE: {
    id: 'CONF_DNAPLATES_CONCENTRATION_MEASURE',
    defaultMessage: ' µM'
  },
  CONF_ISCALE_DNA_AMOUNT_MEASURE: {
    id: 'CONF_ISCALE_DNA_AMOUNT_MEASURE',
    defaultMessage: ' mg'
  },
  DESALT: {
    id: 'DESALT',
    defaultMessage: 'Desalt'
  },
  RP1: {
    id: 'RP1',
    defaultMessage: 'Cartridge'
  },
  DRY: {
    id: 'DRY',
    defaultMessage: 'Dry'
  },
  LIQUID: {
    id: 'LIQUID',
    defaultMessage: 'In Solution (Water)'
  },
  TE: {
    id: 'TE',
    defaultMessage: 'In Solution (TE)'
  },
  TRIS: {
    id: 'TRIS',
    defaultMessage: 'In Solution (TRIS)'
  },
  CONF_DUPLEX_AMOUNT_MOLES: {
    id: 'CONF_DUPLEX_AMOUNT_MOLES',
    defaultMessage: '  nmol'
  },
  CONF_SEARCH_TERM: {
    id: 'CONF_SEARCH_TERM',
    defaultMessage: 'Search Term'
  },
  REVIEW_PAGE_ERROR_HEADER_MESSAGE: {
    id: 'REVIEW_PAGE_ERROR_HEADER_MESSAGE',
    defaultMessage: 'Click on the link for the Item Name to correct the error(s) and proceed to the next step.'
  },
  SINGLE_PRODUCT_ENTRY_PAGE_LEVEL_ERROR_MESSAGE: {
    id: 'SINGLE_PRODUCT_ENTRY_PAGE_LEVEL_ERROR_MESSAGE',
    defaultMessage: '  Please correct error(s) before adding a new item.'
  },
  __PC_SPEC_3_Amino_Modifier_C7: {
    id: '__PC_SPEC_3_Amino_Modifier_C7',
    defaultMessage: 'Amino-Modifier C7'
  },
  Shallow_well_40: {
    id: 'Shallow_well_40',
    defaultMessage: 'Shallow Well (40 μL)'
  },
  PLATE_96_SHALLOW_PCR_100: {
    id: 'PLATE_96_SHALLOW_PCR_100',
    defaultMessage: 'Shallow Well PCR (100 μL)'
  },
  PLATE_96_SHALLOW_200: {
    id: 'PLATE_96_SHALLOW_200',
    defaultMessage: 'Shallow Well (200 μL)'
  },
  PLATE_96_DEEP_400: {
    id: 'PLATE_96_DEEP_400',
    defaultMessage: 'Deep  Well (400 μL)'
  },
  PLATE_96_DEEP_800: {
    id: 'PLATE_96_DEEP_800',
    defaultMessage: 'Deep Well (800 μL)'
  },
  QPCR_PROBES_kICqStart_primers: {
    id: 'QPCR_PROBES_kICqStart_primers',
    defaultMessage: 'KiCqStart™ Primers'
  },
  QPCR_PROBES_Desc_kICqStart_primers: {
    id: 'QPCR_PROBES_Desc_kICqStart_primers',
    defaultMessage: 'Predesigned primer pairs for quantifying gene expression by SYBR{registered} Green | RT-qPCR.'
  },
  QPCR_PROBES_kICqStart_primers_gene_arrays: {
    id: 'QPCR_PROBES_kICqStart_primers_gene_arrays',
    defaultMessage: 'KiCqStart Primers Gene Arrays'
  },
  QPCR_PROBES_Desc_kICqStart_primers_gene_arrays: {
    id: 'QPCR_PROBES_Desc_kICqStart_primers_gene_arrays',
    defaultMessage: 'Predesigned primer pairs organized into the groups of genes that comprise common gene families'
  },
  QPCR_PROBES_kICqStart_probes_assays: {
    id: 'QPCR_PROBES_kICqStart_probes_assays',
    defaultMessage: 'KiCqStart Probe Assays'
  },
  QPCR_PROBES_Desc_kICqStart_probes_assays: {
    id: 'QPCR_PROBES_Desc_kICqStart_probes_assays',
    defaultMessage: 'Predesigned probes assays for quantifying gene expression by RT-qPCR'
  },
  QPCR_PROBES_Desc_dual_labeled_probes: {
    id: 'QPCR_PROBES_Desc_dual_labeled_probes',
    defaultMessage: "The most common probe type, linear hydrolysis probes are used in the 5' nuclease assays"
  },
  QPCR_PROBES_Desc_molecular_beacons: {
    id: 'QPCR_PROBES_Desc_molecular_beacons',
    defaultMessage: 'Structured hybridization probes that are not degraded during the amplification reaction'
  },
  QPCR_PROBES_lightcycler_probes: {
    id: 'QPCR_PROBES_lightcycler_probes',
    defaultMessage: 'LightCycler® Probes'
  },
  QPCR_PROBES_Desc_lightcycler_probes: {
    id: 'QPCR_PROBES_Desc_lightcycler_probes',
    defaultMessage: 'Dual-hybridization probes designed for use in Roche™ LightCycler instruments'
  },
  QPCR_PROBES_scorpions_uni_probes: {
    id: 'QPCR_PROBES_scorpions_uni_probes',
    defaultMessage: 'Scorpions® Uni-Probes'
  },
  QPCR_PROBES_Desc_scorpions_uni_probes: {
    id: 'QPCR_PROBES_Desc_scorpions_uni_probes',
    defaultMessage: 'Combination one-part structured probes - primers that internally hybridized to the target sequence'
  },
  DNA_OLIGO_IN_TUBES: {
    id: 'DNA_OLIGO_IN_TUBES',
    defaultMessage: 'DNA Oligos in Tubes'
  },
  DNA_OLIGO_IN_TUBES_DESC: {
    id: 'DNA_OLIGO_IN_TUBES_DESC',
    defaultMessage: 'For applications including PCR, sequencing and cloning.'
  },
  DNA_OLIGO_IN_PLATES: {
    id: 'DNA_OLIGO_IN_PLATES',
    defaultMessage: 'DNA Oligos in Plates'
  },
  DNA_OLIGO_IN_PLATES_DESC: {
    id: 'DNA_OLIGO_IN_PLATES_DESC',
    defaultMessage: 'Supplied in 96 or 384-well plates including many formatting options.'
  },
  RNA_OLIGO: {
    id: 'RNA_OLIGO',
    defaultMessage: 'RNA Oligos'
  },
  RNA_OLIGO_DESC: {
    id: 'RNA_OLIGO_DESC',
    defaultMessage: 'Single strand oligos containing RNA bases, mixed DNA/RNA or RNA/OMe bases.'
  },
  EASY_OLIGO: {
    id: 'EASY_OLIGO',
    defaultMessage: 'Easy™ Oligos'
  },
  EASY_OLIGO_DESC: {
    id: 'EASY_OLIGO_DESC',
    defaultMessage: 'Normalized to 100 μM at a fixed price.'
  },
  PURE_PRIMERS: {
    id: 'PURE_PRIMERS',
    defaultMessage: 'Pure And Simple Primers'
  },
  PURE_PRIMERS_DESC: {
    id: 'PURE_PRIMERS_DESC',
    defaultMessage: 'Cartridge purified primers at a fixed price.'
  },
  WELLRED_OLIGO_DESC: {
    id: 'WELLRED_OLIGO_DESC',
    defaultMessage: "For use with Beckman Coulter's CEQ/GeXP Genetic Analysis Systems"
  },
  WELLRED_OLIGO: {
    id: 'WELLRED_OLIGO',
    defaultMessage: 'WellRED Oligos'
  },
  DNA_RNA_OLIGO: {
    id: 'DNA_RNA_OLIGO',
    defaultMessage: 'DNA & RNA Oligos'
  },
  QPCR_PROBES_PRIMERS: {
    id: 'QPCR_PROBES_PRIMERS',
    defaultMessage: 'Predesigned Primers And Custom qPCR Probes'
  },
  CUSTOM_OLIGO_PROBES: {
    id: 'CUSTOM_OLIGO_PROBES',
    defaultMessage: 'Sigma offers a comprehensive portfolio of DNA oligos, RNA oligos, predesigned primers and qPCR probes for application including PCR, cloning, next-generation sequencing (NGS), microarray production, CRISPR, gene synthesis, gene expression analysis, genotyping and pathogen detection.'
  },
  CUSTOM_OLIGO_PROBES_TITLE: {
    id: 'CUSTOM_OLIGO_PROBES_TITLE',
    defaultMessage: 'Custom Oligos and qPCR Probes'
  },
  OLIGO_DESCRIPTION: {
    id: 'OLIGO_DESCRIPTION',
    defaultMessage: 'description'
  },
  ORDER_TEXT: {
    id: 'ORDER_TEXT',
    defaultMessage: 'Order'
  },
  KNOCKOUT: {
    id: 'KNOCKOUT',
    defaultMessage: 'Knockout'
  },
  KNOCKDOWN: {
    id: 'KNOCKDOWN',
    defaultMessage: 'Knockdown'
  },
  EXPRESSIONS: {
    id: 'EXPRESSIONS',
    defaultMessage: 'Expressions'
  },
  KNOCKOUT_PRODUCT_CRISPR_DESCRIPTION: {
    id: 'KNOCKOUT_PRODUCT_CRISPR_DESCRIPTION',
    defaultMessage: 'Order plasmids, RNA, nickases, controls and more for this exciting genome editing technology.'
  },
  KNOCKDOWN_PRODUCT_shRNA: {
    id: 'KNOCKDOWN_PRODUCT_shRNA',
    defaultMessage: 'shRNA'
  },
  KNOCKDOWN_PRODUCT_shRNA_DESCRIPTION: {
    id: 'KNOCKDOWN_PRODUCT_shRNA_DESCRIPTION',
    defaultMessage: 'The largest, most validated shRNA collection on the planet; order clones and libraries or request custom designs.'
  },
  KNOCKDOWN_PRODUCT_siRNA: {
    id: 'KNOCKDOWN_PRODUCT_siRNA',
    defaultMessage: 'siRNA'
  },
  KNOCKDOWN_PRODUCT_siRNA_DESCRIPTION: {
    id: 'KNOCKDOWN_PRODUCT_siRNA_DESCRIPTION',
    defaultMessage: 'Duplex RNA available as predesigned constructs or synthesized to your custom specifications.'
  },
  EXPRESSION_PRODUCT_ORF_DESCRIPTION: {
    id: 'EXPRESSION_PRODUCT_ORF_DESCRIPTION',
    defaultMessage: 'Protein coding sequences from the human ORFeum in several lentiviral vector choices.'
  },
  CUSTOM_PEPTIDE_LIBRARIES: {
    id: 'CUSTOM_PEPTIDE_LIBRARIES',
    defaultMessage: 'PEPscreen® : Custom Peptide Libraries'
  },
  CUSTOM_PEPTIDE_LIBRARIES_PRODUCT_PEPSCREEN: {
    id: 'CUSTOM_PEPTIDE_LIBRARIES_PRODUCT_PEPSCREEN',
    defaultMessage: 'PEPscreen Peptide Libraries'
  },
  CUSTOM_PEPTIDE_LIBRARIES_PRODUCT_PEPSCREEN_DESCRIPTION: {
    id: 'CUSTOM_PEPTIDE_LIBRARIES_PRODUCT_PEPSCREEN_DESCRIPTION',
    defaultMessage: 'Small scale peptides delivered in a 96 tube rack format.'
  },
  AQUA_Peptides_PRODUCT_AQUA: {
    id: 'AQUA_Peptides_PRODUCT_AQUA',
    defaultMessage: 'AQUA™ Peptides'
  },
  AQUA_Peptides_PRODUCT_AQUA_DESCRIPTION: {
    id: 'AQUA_Peptides_PRODUCT_AQUA_DESCRIPTION',
    defaultMessage: 'Quantitated peptides with or without isotope labeling.'
  },
  PREVIOUS_TEXT: {
    id: 'PREVIOUS_TEXT',
    defaultMessage: 'Previous'
  },
  PC_CONJUGATION_CHEMISTRY: {
    id: 'PC_CONJUGATION_CHEMISTRY',
    defaultMessage: 'Conjugation Chemistry'
  },
  PC_CONJUGATION_KLH: {
    id: 'PC_CONJUGATION_KLH',
    defaultMessage: 'KLH'
  },
  PC_CONJUGATION_BSA: {
    id: 'PC_CONJUGATION_BSA',
    defaultMessage: 'BSA'
  },
  PC_CONJUGATION_DIMERIZATION: {
    id: 'PC_CONJUGATION_DIMERIZATION',
    defaultMessage: 'Dimerization'
  },
  PC_CONJUGATION_NONE: {
    id: 'PC_CONJUGATION_NONE',
    defaultMessage: 'None'
  },
  PC_CONJUGATION_CHEMISTRY_EDC: {
    id: 'PC_CONJUGATION_CHEMISTRY_EDC',
    defaultMessage: 'EDC'
  },
  PC_CONJUGATION_CHEMISTRY_AEDC: {
    id: 'PC_CONJUGATION_CHEMISTRY_AEDC',
    defaultMessage: 'Activated EDC'
  },
  PC_CONJUGATION_CHEMISTRY_GMBS: {
    id: 'PC_CONJUGATION_CHEMISTRY_GMBS',
    defaultMessage: 'GMBS'
  },
  PC_CONJUGATION_CHEMISTRY_GLUT: {
    id: 'PC_CONJUGATION_CHEMISTRY_GLUT',
    defaultMessage: 'Glutaraldehyde'
  },
  Unpurified: {
    id: 'Unpurified',
    defaultMessage: 'Unpurified'
  },
  Desalt: {
    id: 'Desalt',
    defaultMessage: 'Desalted'
  },
  INSOL: {
    id: 'INSOL',
    defaultMessage: 'In Solution (20mg/ml in DMSO)'
  },
  INSOL40: {
    id: 'INSOL40',
    defaultMessage: 'In Solution (40mg/ml in DMSO)'
  },
  INSOLCSR: {
    id: 'INSOLCSR',
    defaultMessage: 'In Solution (Other)'
  },
  YES: {
    id: 'YES',
    defaultMessage: 'Yes'
  },
  NO: {
    id: 'NO',
    defaultMessage: 'No'
  },
  KSPRA2015_HEX: {
    id: 'KSPRA2015_HEX',
    defaultMessage: 'HEX'
  },
  KSPRA2015_FAM: {
    id: 'KSPRA2015_FAM',
    defaultMessage: 'FAM'
  },
  MULTIPLE_LINE_ERROR: {
    id: 'MULTIPLE_LINE_ERROR',
    defaultMessage: 'Multiple lines are not allowed for Name'
  },
  PEPTIDE_NAME: {
    id: 'PEPTIDE_NAME',
    defaultMessage: 'Peptide Name'
  },
  DNA_LONG_OLIGO_DESC: {
    id: 'DNA_LONG_OLIGO_DESC',
    defaultMessage: 'DNA oligo lengths from 121 to 180 bases for gene construction, microarray development and CRISPR gene editing.'
  },
  CUSTOMOLIGO_OLIGO_PROBE: {
    id: 'CUSTOMOLIGO_OLIGO_PROBE',
    defaultMessage: 'Oligo & Probes'
  },
  CUSTOMOLIGO_GENE_KNOCKOUT: {
    id: 'CUSTOMOLIGO_GENE_KNOCKOUT',
    defaultMessage: 'Gene Knockout, Knockdown & Expression'
  },
  CUSTOMOLIGO_PEPTIDE: {
    id: 'CUSTOMOLIGO_PEPTIDE',
    defaultMessage: 'Peptides'
  },
  MILLIPLEX_KIT_ERROR_MESSAGE: {
    id: 'MILLIPLEX_KIT_ERROR_MESSAGE',
    defaultMessage: 'Please select a kit before proceeding to next step.'
  },
  PC_SELECT_ANALYTES_milliplex: {
    id: 'PC_SELECT_ANALYTES_milliplex',
    defaultMessage: 'SELECT ANALYTES'
  },
  PC_KITS_HT_milliplex: {
    id: 'PC_KITS_HT_milliplex',
    defaultMessage: 'Result content will be availble once all search criteria are selected'
  },
  CUSTOM_CRISPR_CRRNA_POPUP_customcrispr: {
    id: 'CUSTOM_CRISPR_CRRNA_POPUP_customcrispr',
    defaultMessage: 'crRNA'
  },
  CUSTOM_CRISPR_SGRNA_POPUP_customcrispr: {
    id: 'CUSTOM_CRISPR_SGRNA_POPUP_customcrispr',
    defaultMessage: 'sgRNA'
  },
  CUSTOM_CRISPR_MOD_SGRNA_POPUP_customcrispr: {
    id: 'CUSTOM_CRISPR_MOD_SGRNA_POPUP_customcrispr',
    defaultMessage: 'Modified sgRNA'
  },
  CUSTOM_CRISPR_MOD_CRRNA_POPUP_customcrispr: {
    id: 'CUSTOM_CRISPR_MOD_CRRNA_POPUP_customcrispr',
    defaultMessage: 'Modified crRNA'
  },
  PAGINATION_GOTO_TEXT: {
    id: 'PAGINATION_GOTO_TEXT',
    defaultMessage: 'Go to page '
  },
  PAGINATION_GOTO_LAST_TEXT: {
    id: 'PAGINATION_GOTO_LAST_TEXT',
    defaultMessage: 'Go to last page'
  },
  PAGINATION_GOTO_FIRST_TEXT: {
    id: 'PAGINATION_GOTO_FIRST_TEXT',
    defaultMessage: 'Go to first page'
  },
  CONF_COMPANY_INSTITUTION_ANTISERA: {
    id: 'CONF_COMPANY_INSTITUTION_ANTISERA',
    defaultMessage: 'Company/Institution Name'
  },
  CONF_CUSTOMER_SUPPORT: {
    id: 'CONF_CUSTOMER_SUPPORT',
    defaultMessage: 'Customer Support'
  },
  COPIED: {
    id: 'COPIED',
    defaultMessage: 'Copied'
  },
  COMPANION_PRODUCT_TRACRRNAMOD_crisprgrna: {
    id: 'COMPANION_PRODUCT_TRACRRNAMOD_crisprgrna',
    defaultMessage: 'TRACRRNAMOD'
  },
  COMPANION_PRODUCT_TRACRRNA05N_crisprgrna: {
    id: 'COMPANION_PRODUCT_TRACRRNA05N_crisprgrna',
    defaultMessage: 'TRACRRNA05N'
  },
  COMPANION_PRODUCT_CAS9PROT_crisprgrna: {
    id: 'COMPANION_PRODUCT_CAS9PROT_crisprgrna',
    defaultMessage: 'CAS9PROT'
  },
  COMPANION_PRODUCT_ESPCAS9PRO_crisprgrna: {
    id: 'COMPANION_PRODUCT_ESPCAS9PRO_crisprgrna',
    defaultMessage: 'ESPCAS9PRO'
  },
  COMPANION_PRODUCT_FNCAS9TRACR_crisprgrna: {
    id: 'COMPANION_PRODUCT_FNCAS9TRACR_crisprgrna',
    defaultMessage: 'FNCAS9TRACR'
  },
  COMPANION_PRODUCT_MODIFIED_FNCAS9TRACR_crisprgrna: {
    id: 'COMPANION_PRODUCT_MODIFIED_FNCAS9TRACR_crisprgrna',
    defaultMessage: 'Modified FNCAS9TRACR'
  },
  COMPANION_PRODUCT_FNCAS9PROT_crisprgrna: {
    id: 'COMPANION_PRODUCT_FNCAS9PROT_crisprgrna',
    defaultMessage: 'FNCAS9PROT'
  },
  COMPANION_PRODUCT_TRACRRNAMOD_DESC_crisprgrna: {
    id: 'COMPANION_PRODUCT_TRACRRNAMOD_DESC_crisprgrna',
    defaultMessage: 'SygRNA® Cas9 Synthetic Modified tracrRNA'
  },
  COMPANION_PRODUCT_TRACRRNA05N_DESC_crisprgrna: {
    id: 'COMPANION_PRODUCT_TRACRRNA05N_DESC_crisprgrna',
    defaultMessage: 'SygRNA® SpCas9 tracrRNA'
  },
  COMPANION_PRODUCT_CAS9_PROTEIN_DESC_crisprgrna: {
    id: 'COMPANION_PRODUCT_CAS9_PROTEIN_DESC_crisprgrna',
    defaultMessage: 'CAS9 Protein'
  },
  COMPANION_PRODUCT_ESPCAS9_PROTEIN_DESC_crisprgrna: {
    id: 'COMPANION_PRODUCT_ESPCAS9_PROTEIN_DESC_crisprgrna',
    defaultMessage: 'ESPCAS9 Protein'
  },
  COMPANION_PRODUCT_FNCAS9TRACR_DESC_crisprgrna: {
    id: 'COMPANION_PRODUCT_FNCAS9TRACR_DESC_crisprgrna',
    defaultMessage: 'SygRNA® FnCas9 Synthetic tracrRNA'
  },
  COMPANION_PRODUCT_MODIFIED_FNCAS9TRACR_DESC_crisprgrna: {
    id: 'COMPANION_PRODUCT_MODIFIED_FNCAS9TRACR_DESC_crisprgrna',
    defaultMessage: 'SygRNA® FnCas9 Synthetic Modified tracrRNA'
  },
  COMPANION_PRODUCT_FNCAS9PROT_PROTEIN_DESC_crisprgrna: {
    id: 'COMPANION_PRODUCT_FNCAS9PROT_PROTEIN_DESC_crisprgrna',
    defaultMessage: 'FnCas9 Protein'
  },
  COMPANION_PRODUCT_HEADER_crisprgrna: {
    id: 'COMPANION_PRODUCT_HEADER_crisprgrna',
    defaultMessage: 'CRISPR RNA requires the use of tracrRNA and a Cas9 protein. Please choose from the recommended options.'
  },
  PREVIOUS_STEP: {
    id: 'PREVIOUS_STEP',
    defaultMessage: 'Previous Step'
  },
  SAVED_CART_DELETE_DIALOG_DESCRIPTION: {
    id: 'SAVED_CART_DELETE_DIALOG_DESCRIPTION',
    defaultMessage: 'Are you sure you want to delete your cart?'
  },
  SAVED_CART_DELETED: {
    id: 'SAVED_CART_DELETED',
    defaultMessage: 'Saved Cart Deleted'
  },
  SAVED_CART_MERGED: {
    id: 'SAVED_CART_MERGED',
    defaultMessage: 'Cart Successfully Added'
  },
  SAVED_CART_ADD_WRONG_TYPE: {
    id: 'SAVED_CART_ADD_WRONG_TYPE',
    defaultMessage: '{productNumber} is a {companyName} item. Please add it to your {companyName} saved cart.'
  },
  SAVED_CART_ADD_WRONG_TYPE_TO_PURPLE: {
    id: 'SAVED_CART_ADD_WRONG_TYPE_TO_PURPLE',
    defaultMessage: '{productNumber} is a {companyNameA} item, and you are trying to add it to a {companyNameB} cart. Please switch to a {companyNameA} cart to add this item.'
  },
  EDIT_FIELD: {
    id: 'EDIT_FIELD',
    defaultMessage: 'Edit {field}'
  },
  UPDATED: {
    id: 'UPDATED',
    defaultMessage: 'Updated'
  },
  REDIRECTING_TO_CHECKOUT: {
    id: 'REDIRECTING_TO_CHECKOUT',
    defaultMessage: 'Redirecting to checkout process'
  },
  CART_SAVE_SUCCESS: {
    id: 'CART_SAVE_SUCCESS',
    defaultMessage: 'Cart Successfully Saved!'
  },
  KIT_SOLD_SEPARATE: {
    id: 'KIT_SOLD_SEPARATE',
    defaultMessage: 'Kit Components Also Available Separately'
  },
  KIT_ONLY: {
    id: 'KIT_ONLY',
    defaultMessage: 'Kit Components Only'
  },
  ANALYTE: {
    id: 'ANALYTE',
    defaultMessage: 'Analyte'
  },
  SOLVENT: {
    id: 'SOLVENT',
    defaultMessage: 'Solvent'
  },
  BULLETIN: {
    id: 'BULLETIN',
    defaultMessage: 'Bulletin'
  },
  UNAVAILABLE: {
    id: 'UNAVAILABLE',
    defaultMessage: 'Unavailable'
  },
  LEARN_MORE: {
    id: 'LEARN_MORE',
    defaultMessage: 'Learn More'
  },
  LOCATION: {
    id: 'LOCATION',
    defaultMessage: 'Location'
  },
  CONTINUE: {
    id: 'CONTINUE',
    defaultMessage: 'Continue'
  },
  SAVE_LOCATION: {
    id: 'SAVE_LOCATION',
    defaultMessage: 'Save Location'
  },
  SELECT_REGION_AND_LOCATION: {
    id: 'SELECT_REGION_AND_LOCATION',
    defaultMessage: 'Please select a region and location.'
  },
  SHIPS_TODAY: {
    id: 'SHIPS_TODAY',
    defaultMessage: 'Ships Today'
  },
  SHIPS_TODAY_MESSAGE: {
    id: 'SHIPS_TODAY_MESSAGE',
    defaultMessage: 'Same day shipping on orders placed before {shippingTime}.'
  },
  SELECT_DEALER: {
    id: 'SELECT_DEALER',
    defaultMessage: 'Select Dealer'
  },
  SET_AS_PREFERRED_DEALER: {
    id: 'SET_AS_PREFERRED_DEALER',
    defaultMessage: 'Set as Preferred Dealer'
  },
  SELECT_PREFERRED_DEALER: {
    id: 'SELECT_PREFERRED_DEALER',
    defaultMessage: 'Select a preferred dealer'
  },
  SELECT_VIEW_PRICING: {
    id: 'SELECT_VIEW_PRICING',
    defaultMessage: 'Select and View Pricing'
  },
  DEALER_LABEL_ADDRESS: {
    id: 'DEALER_LABEL_ADDRESS',
    defaultMessage: 'Address'
  },
  DEALER_LABEL_CITY: {
    id: 'DEALER_LABEL_CITY',
    defaultMessage: 'City'
  },
  DEALER_LABEL_DEALER: {
    id: 'DEALER_LABEL_DEALER',
    defaultMessage: 'Dealer'
  },
  DEALER_LABEL_BRANCH: {
    id: 'DEALER_LABEL_BRANCH',
    defaultMessage: 'Branch'
  },
  DEALER_LABEL_PREFECTURE: {
    id: 'DEALER_LABEL_PREFECTURE',
    defaultMessage: 'Prefecture'
  },
  DEALER_DIALOG_TITLE: {
    id: 'DEALER_DIALOG_TITLE',
    defaultMessage: 'Dealer Country'
  },
  DEALER_DIALOG_BODY: {
    id: 'DEALER_DIALOG_BODY',
    defaultMessage: 'Are you an existing Direct Sales Customer or Dealer?'
  },
  LOOKING_FOR_SIMILAR_PRODUCTS: {
    id: 'LOOKING_FOR_SIMILAR_PRODUCTS',
    defaultMessage: 'Looking for similar products?'
  },
  VISIT_PRODUCT_COMPARISON_GUIDE: {
    id: 'VISIT_PRODUCT_COMPARISON_GUIDE',
    defaultMessage: 'Visit Product Comparison Guide'
  },
  ATTRIBUTE_SELECTOR_ERROR: {
    id: 'ATTRIBUTE_SELECTOR_ERROR',
    defaultMessage: 'You have selected the maximum number of product attributes (3) to compare. Please remove at least one to add a new selection.'
  },
  ORDERING_INFORMATION: {
    id: 'ORDERING_INFORMATION',
    defaultMessage: 'Ordering Information'
  },
  OCI_CHECKOUT_ERROR: {
    id: 'OCI_CHECKOUT_ERROR',
    defaultMessage: 'An error occurred during checkout: {errorMessage}'
  },
  DATA_ERROR: {
    id: 'DATA_ERROR',
    defaultMessage: 'Data Error'
  },
  QUOTE_ITEM: {
    id: 'QUOTE_ITEM',
    defaultMessage: 'Quote Item'
  },
  EMPROVE_DOSSIERS: {
    id: 'EMPROVE_DOSSIERS',
    defaultMessage: 'Emprove® Dossiers'
  },
  DOCUMENT_FREE_LOCKED: {
    id: 'DOCUMENT_FREE_LOCKED',
    defaultMessage: 'Free of charge - Sign in to download'
  },
  DOCUMENT_AVAILABLE: {
    id: 'DOCUMENT_AVAILABLE',
    defaultMessage: 'Available by subscription or for a fee'
  },
  DOCUMENT_FREE_DOWNLOADABLE: {
    id: 'DOCUMENT_FREE_DOWNLOADABLE',
    defaultMessage: 'Download for free of charge'
  },
  DOCUMENT_DISABLED: {
    id: 'DOCUMENT_DISABLED',
    defaultMessage: 'Document Type Unavailable'
  },
  DOCUMENT_PENDING: {
    id: 'DOCUMENT_PENDING',
    defaultMessage: 'An order is pending'
  },
  EMPROVE_EMAIL_VERIFICATION_BODY_PT_1: {
    id: 'EMPROVE_EMAIL_VERIFICATION_BODY_PT_1',
    defaultMessage: 'Thank you for your interest in the Emprove® Program.'
  },
  EMPROVE_EMAIL_VERIFICATION_BODY_PT_2: {
    id: 'EMPROVE_EMAIL_VERIFICATION_BODY_PT_2',
    defaultMessage: 'Some documents from the Emprove® Program are available by subscription or for a fee. For security purposes we periodically ask that you verify your identity. Please click on the link that has been sent to your email address to keep your account active.'
  },
  EMPROVE_EMAIL_VERIFICATION_BODY_PT_3: {
    id: 'EMPROVE_EMAIL_VERIFICATION_BODY_PT_3',
    defaultMessage: 'We have sent a verification link to {userEmail}. If you did not receive the email, please check your spam folder or {sendNewLink}'
  },
  APPROVAL_DELEGATE_MSG: {
    id: 'APPROVAL_DELEGATE_MSG',
    defaultMessage: 'Your approval delegation date has been expired. Please update your settings in Profile. {manageLink}'
  },
  APPROVAL_DELEGATE_MANAGE: {
    id: 'APPROVAL_DELEGATE_MANAGE',
    defaultMessage: 'Manage'
  },
  OK: {
    id: 'OK',
    defaultMessage: 'OK'
  },
  SEND_NEW_LINK: {
    id: 'SEND_NEW_LINK',
    defaultMessage: 'send a new link.'
  },
  DOWNLOAD_EMPROVE_DOSSIER: {
    id: 'DOWNLOAD_EMPROVE_DOSSIER',
    defaultMessage: 'Download Emprove® Dossier'
  },
  I_AGREE: {
    id: 'I_AGREE',
    defaultMessage: 'I Agree'
  },
  ACCEPT_TERMS_AND_CONDITIONS: {
    id: 'ACCEPT_TERMS_AND_CONDITIONS',
    defaultMessage: 'By selecting this box, I am indicating that I have read and agree to the {emproveProgramTermsAndConditions}.'
  },
  EMPROVE_PROGRAM_TERMS_AND_CONDITIONS: {
    id: 'EMPROVE_PROGRAM_TERMS_AND_CONDITIONS',
    defaultMessage: 'Emprove® Program Terms and Conditions'
  },
  EMPROVE_SUITE_NON_SUBSCRIBER_ERR_MSG: {
    id: 'EMPROVE_SUITE_NON_SUBSCRIBER_ERR_MSG',
    defaultMessage: 'The Emprove® Suite is part of the Emprove® Program, a system providing documentation of filters, single-use components, pharma raw materials, and starting materials. It helps you meet the latest regulatory requirements for risk assessment, and speed your way through the regulatory maze.'
  },
  EMPROVE_THANK_YOU_MSG: {
    id: 'EMPROVE_THANK_YOU_MSG',
    defaultMessage: 'Thank you for your interest in the Emprove® Suite'
  },
  EMPROVE_PRODUCTS_LISTS_LINK: {
    id: 'EMPROVE_PRODUCTS_LISTS_LINK',
    defaultMessage: 'Click to see a list of Emprove® Products'
  },
  EMPROVE_PROGRAM_LINK: {
    id: 'EMPROVE_PROGRAM_LINK',
    defaultMessage: 'Click to learn more about the Emprove® Program'
  },
  CART_DELETED: {
    id: 'CART_DELETED',
    defaultMessage: 'Cart Deleted'
  },
  PAYMENT_HISTORY_TOTAL: {
    id: 'PAYMENT_HISTORY_TOTAL',
    defaultMessage: 'Total'
  },
  INVALID_MASK_FOR_CREDIT_CARD_NAME: {
    id: 'INVALID_MASK_FOR_CREDIT_CARD_NAME',
    defaultMessage: 'Invalid Mask for Cardholder Name'
  },
  PAYER_NAME: {
    id: 'PAYER_NAME',
    defaultMessage: 'Payer Name'
  },
  RETURN_TO_CART: {
    id: 'RETURN_TO_CART',
    defaultMessage: 'Return to Cart'
  },
  STAY_IN_CHECKOUT: {
    id: 'STAY_IN_CHECKOUT',
    defaultMessage: 'Stay in Checkout'
  },
  STAY_IN_QUOTE: {
    id: 'STAY_IN_QUOTE',
    defaultMessage: 'Stay in Quote'
  },
  VENDOR_NUMBER: {
    id: 'VENDOR_NUMBER',
    defaultMessage: 'Vendor Number'
  },
  THIRD_PARTY_TOS: {
    id: 'THIRD_PARTY_TOS',
    defaultMessage: 'Aldrich Partner Product Terms of Service'
  },
  CUSTOMER_PO_NUMBER: {
    id: 'CUSTOMER_PO_NUMBER',
    defaultMessage: 'Customer PO Number'
  },
  PRICE_PRESENTED_TO_CUSTOMER: {
    id: 'PRICE_PRESENTED_TO_CUSTOMER',
    defaultMessage: 'Price Presented to Customer'
  },
  PROMO_SAVE_PERCENT: {
    id: 'PROMO_SAVE_PERCENT',
    defaultMessage: 'Use promotion code {messageVariable2} in the cart to save {messageVariable1}% on this product.'
  },
  USE_PROMOTIONAL_CODE: {
    id: 'USE_PROMOTIONAL_CODE',
    defaultMessage: 'Use promo code {promotionalCode} at checkout to apply discount.'
  },
  PIPELINE_WELCOME: {
    id: 'PIPELINE_WELCOME',
    defaultMessage: 'Welcome to {companyName} Pipeline!'
  },
  PIPELINE_MESSAGE: {
    id: 'PIPELINE_MESSAGE',
    defaultMessage: 'If you are a first time user, please follow and complete registration process below.'
  },
  PIPELINE_REGISTRATION_CODE: {
    id: 'PIPELINE_REGISTRATION_CODE',
    defaultMessage: 'Registration Code'
  },
  PIPELINE_REGISTRATION_CODE_MESSAGE: {
    id: 'PIPELINE_REGISTRATION_CODE_MESSAGE',
    defaultMessage: 'Registration codes are provided by {companyName} for access to customized versions of the website and are unique to each organization.'
  },
  PIPELINE_SIGN_IN_MESSAGE: {
    id: 'PIPELINE_SIGN_IN_MESSAGE',
    defaultMessage: 'Already registered for Pipeline?'
  },
  PIPELINE_REGISTRATION_CODE_EDIT: {
    id: 'PIPELINE_REGISTRATION_CODE_EDIT',
    defaultMessage: 'If entered registration code is incorrect, click "Edit" to modify your code.'
  },
  SELECT_YOUR_DEPARTMENT: {
    id: 'SELECT_YOUR_DEPARTMENT',
    defaultMessage: 'Select your department'
  },
  DEALER_SEARCH_MODAL_DISCLAIMER: {
    id: 'DEALER_SEARCH_MODAL_DISCLAIMER',
    defaultMessage: '* Only the dealers within the same prefecture are displayed in the following list.\nIf you wish to purchase other shops, please select on search.'
  },
  DEALER_SELECT_MODAL_DISCLAIMER: {
    id: 'DEALER_SELECT_MODAL_DISCLAIMER',
    defaultMessage: 'Please search and select your preferred dealer to continue. Your selection will be saved to your profile and used for future purchases.'
  },
  DEALER_UPDATE_PRICING_DISCLAIMER: {
    id: 'DEALER_UPDATE_PRICING_DISCLAIMER',
    defaultMessage: 'To view other dealers’ pricing, please select a dealer to continue. The following dealers are in the same prefecture as your shipping address. Please use search to find dealers from other prefectures.'
  },
  PIPELINE_THANKYOU_MESSAGE: {
    id: 'PIPELINE_THANKYOU_MESSAGE',
    defaultMessage: 'Thank you for registering!'
  },
  PIPELINE_THANKYOU_BODY_TEXT: {
    id: 'PIPELINE_THANKYOU_BODY_TEXT',
    defaultMessage: 'You have been registered and your profile has been saved. You will receive email once your ordering capability is activated.'
  },
  PIPELINE_HOME_PAGE: {
    id: 'PIPELINE_HOME_PAGE',
    defaultMessage: 'Home Page'
  },
  PIPELINE_PROFILE_PAGE: {
    id: 'PIPELINE_PROFILE_PAGE',
    defaultMessage: 'Profile'
  },
  PRINT: {
    id: 'PRINT',
    defaultMessage: 'Print'
  },
  CONSOLIDATED_PAID_INVOICE: {
    id: 'CONSOLIDATED_PAID_INVOICE',
    defaultMessage: 'Consolidated Paid Invoice'
  },
  MATERIAL_NUMBER: {
    id: 'MATERIAL_NUMBER',
    defaultMessage: 'Material Number'
  },
  MATERIAL_DESC: {
    id: 'MATERIAL_DESC',
    defaultMessage: 'Material Description'
  },
  INVOICE_QTY: {
    id: 'INVOICE_QTY',
    defaultMessage: 'Invoice Qty'
  },
  UNIT_PRICE: {
    id: 'UNIT_PRICE',
    defaultMessage: 'Unit Price'
  },
  UNIT_COST: {
    id: 'UNIT_COST',
    defaultMessage: 'Unit Cost'
  },
  NET_VALUE: {
    id: 'NET_VALUE',
    defaultMessage: 'Net Value'
  },
  OTHER_COSTS: {
    id: 'OTHER_COSTS',
    defaultMessage: 'Other Costs'
  },
  PRINT_TAX: {
    id: 'PRINT_TAX',
    defaultMessage: 'Tax'
  },
  PRINT_TOTAL_PRICE: {
    id: 'PRINT_TOTAL_PRICE',
    defaultMessage: 'Total Price'
  },
  PRINT_SUBTOTAL: {
    id: 'PRINT_SUBTOTAL',
    defaultMessage: 'Sub Total'
  },
  PRINT_VAT: {
    id: 'PRINT_VAT',
    defaultMessage: 'Vat'
  },
  PRINT_TOTAL: {
    id: 'PRINT_TOTAL',
    defaultMessage: 'Total'
  },
  PIPELINE_INVALID_REGISTRATION_CODE: {
    id: 'PIPELINE_INVALID_REGISTRATION_CODE',
    defaultMessage: 'Invalid Registration Code, please check and try again. If you continue to have issues, please contact your administrator who provided you this link and code.'
  },
  PIPELINE_INVALID_DEPARTMENT: {
    id: 'PIPELINE_INVALID_DEPARTMENT',
    defaultMessage: 'Invalid Department, please check and try again. If you continue to have issues, please contact your administrator who provided you this link and code.'
  },
  PRODUCT_AVAILABILITY_NOT_CALCULATED: {
    id: 'PRODUCT_AVAILABILITY_NOT_CALCULATED',
    defaultMessage: 'Product Availability: You will receive an order acknowledgement once the order has been entered in our system.'
  },
  PROFICIENCY_TESTING_STUDY: {
    id: 'PROFICIENCY_TESTING_STUDY',
    defaultMessage: 'Indicate Proficiency Testing (PT) study type at check out, under ‘your reference’. New users visit Sigma-Aldrich.com/pt to register, enter data, download reporting packets, or access final results.'
  },
  ELITE_DIAG_MFG: {
    id: 'ELITE_DIAG_MFG',
    defaultMessage: 'Diagnostics Manufacturers should use alternative grade product: {messageVariable1}'
  },
  ZOOMAB_TARGET: {
    id: 'ZOOMAB_TARGET',
    defaultMessage: 'A recombinant, preservative-free antibody is available for your target. Try {messageVariable1}'
  },
  EURSHIP01: {
    id: 'EURSHIP01',
    defaultMessage: 'Please ask customer service about shipping options, we offer standard shipping (6-8 weeks) or 24 Hour shipping.'
  },
  F_FSCI: {
    id: 'F_FSCI',
    defaultMessage: 'To receive a 15% discount on this product use promo code SCI'
  },
  PHGKIT: {
    id: 'PHGKIT',
    defaultMessage: 'Each kit contains {messageVariable1} samples, each sample from a uniquely manufactured lot. These kits are intended only for use in qualification of material in commercial applications.'
  },
  M_OUT: {
    id: 'M_OUT',
    defaultMessage: '{messageVariable1}% discounted price for limited stock Use Code: M-OUT'
  },
  '3LOTKIT_100G': {
    id: '3LOTKIT_100G',
    defaultMessage: 'Each kit contains 3 x 100G samples, each sample from a uniquely manufactured lot.'
  },
  '3LOTKIT_100ML': {
    id: '3LOTKIT_100ML',
    defaultMessage: 'Each kit contains 3 x 100ML samples, each sample from a uniquely manufactured lot.'
  },
  GEM: {
    id: 'GEM',
    defaultMessage: 'GeM Catalogue ID: {messageVariable1}'
  },
  BIORENEW_ALT_GENERIC: {
    id: 'BIORENEW_ALT_GENERIC',
    defaultMessage: 'This SKU is also available as a BioRenewable equivalent.'
  },
  REDI_DRI_ACS: {
    id: 'REDI_DRI_ACS',
    defaultMessage: 'This SKU is also available as ACS grade in our Exclusive Redi-Dri™ free-flowing product line.'
  },
  TRUPAGE: {
    id: 'TRUPAGE',
    defaultMessage: 'Use exclusively with TruPAGE™ formulated buffers. Recipes provided in technical bulletin.'
  },
  REDI_DRI_EXC: {
    id: 'REDI_DRI_EXC',
    defaultMessage: 'This SKU is also available as equivalent grade in our Exclusive Redi-Dri™ free-flowing product line.'
  },
  REAGENTPLUS: {
    id: 'REAGENTPLUS',
    defaultMessage: 'ReagentPlus'
  },
  REDI_DRI_RP: {
    id: 'REDI_DRI_RP',
    defaultMessage: 'This SKU is also available as {brand}® grade in our Exclusive Redi-Dri™ free-flowing product line.'
  },
  EXCISE_DUTY: {
    id: 'EXCISE_DUTY',
    defaultMessage: 'This item is subject to Excise Duty. Please check the cart for more details.'
  },
  VISIT_CRISPR_ESSENTIALS_PAGE: {
    id: 'VISIT_CRISPR_ESSENTIALS_PAGE',
    defaultMessage: ' for more details about the formats and specifications offered in the white box.'
  },
  CUSTOM_CRISPR_RECOMMENDATIONS: {
    id: 'CUSTOM_CRISPR_RECOMMENDATIONS',
    defaultMessage: 'Recommendations based on application'
  },
  CUSTOM_CRISPR_SOLUTIONS: {
    id: 'CUSTOM_CRISPR_SOLUTIONS',
    defaultMessage: 'Custom CRISPR Solutions'
  },
  Atlas_01: {
    id: 'Atlas_01',
    defaultMessage: 'You have selected a custom made to order product.  The feasibility of manufacturing the product will be reviewed.'
  },
  FAPAS: {
    id: 'FAPAS',
    defaultMessage: 'Product will ship according to FAPAS study schedule - please refer to "Other notes".'
  },
  QUICK_TURN: {
    id: 'QUICK_TURN',
    defaultMessage: 'Product shipped according to study schedule - Quick turn Studies available on request - Please refer to "Other Notes"'
  },
  SHIPS_IN_1_WEEK_ROW1: {
    id: 'SHIPS_IN_1_WEEK_ROW1',
    defaultMessage: 'Usually ships in 1 week. (Orders outside of US and Europe, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_IN_1_WEEK_ROW2: {
    id: 'SHIPS_IN_1_WEEK_ROW2',
    defaultMessage: 'Usually ships in 1 week. (Orders outside of Europe & Scandinavian countries, allow an additional week for delivery'
  },
  SHIPS_IN_2_WEEKS_US: {
    id: 'SHIPS_IN_2_WEEKS_US',
    defaultMessage: 'Usually ships in 2 weeks.'
  },
  SHIPS_IN_3_WEEKS_US: {
    id: 'SHIPS_IN_3_WEEKS_US',
    defaultMessage: 'Usually ships in 3 weeks.'
  },
  SHIPS_IN_4_WEEKS_ROW: {
    id: 'SHIPS_IN_4_WEEKS_ROW',
    defaultMessage: 'Usually Ships in 4 Weeks. (Orders outside of US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_IN_4_WEEKS_US: {
    id: 'SHIPS_IN_4_WEEKS_US',
    defaultMessage: 'Usually Ships in 4 Weeks.'
  },
  SHIPS_IN_6_8_WEEKS: {
    id: 'SHIPS_IN_6_8_WEEKS',
    defaultMessage: 'Usually Ships in 6-8 Weeks. '
  },
  SHIPS_IN_8_WEEKS_US: {
    id: 'SHIPS_IN_8_WEEKS_US',
    defaultMessage: 'Usually Ships in 8 Weeks.'
  },
  SHIPS_WITHIN_11_WEEKS_ROW: {
    id: 'SHIPS_WITHIN_11_WEEKS_ROW',
    defaultMessage: 'Usually Ships in 11 Weeks. (Orders outside of US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_WITHIN_15_DAYS_ROW1_AVG: {
    id: 'SHIPS_WITHIN_15_DAYS_ROW1_AVG',
    defaultMessage: 'The average delivery time is 15 business days. (Orders outside of US and Europe, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_WITHIN_15_DAYS_US: {
    id: 'SHIPS_WITHIN_15_DAYS_US',
    defaultMessage: 'Ships within 15 business days.'
  },
  SHIPS_WITHIN_2_DAYS_ROW: {
    id: 'SHIPS_WITHIN_2_DAYS_ROW',
    defaultMessage: 'Ships within 2 business days(Orders outside of US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_WITHIN_2_WEEKS_ROW: {
    id: 'SHIPS_WITHIN_2_WEEKS_ROW',
    defaultMessage: 'Ships within 2 weeks. (Orders outside of US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_WITHIN_5_DAYS_ROW: {
    id: 'SHIPS_WITHIN_5_DAYS_ROW',
    defaultMessage: 'Ships within 5 business days. (Orders outside of US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_WITHIN_5_DAYS_US: {
    id: 'SHIPS_WITHIN_5_DAYS_US',
    defaultMessage: 'Ships within 5 business days.'
  },
  SHIPS_WITHIN_7_TO_10_DAYS_US: {
    id: 'SHIPS_WITHIN_7_TO_10_DAYS_US',
    defaultMessage: 'Ships within 7 to 10 business days.'
  },
  ATLAS_01: {
    id: 'ATLAS_01',
    defaultMessage: 'You have selected a custom made to order product. The feasibility of manufacturing the product will be reviewed.'
  },
  SHIPS_WITHIN_7_10_DAYS: {
    id: 'SHIPS_WITHIN_7_10_DAYS',
    defaultMessage: 'Ships within 7-10 business days (orders outside the US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_IN_4_6_WEEKS_ROW: {
    id: 'SHIPS_IN_4_6_WEEKS_ROW',
    defaultMessage: 'Usually ships in 4 to 6 weeks. (Outside of the US and Europe, please allow an additional 1-2 weeks for delivery'
  },
  SHIPS_IN_4_6_WEEKS_US: {
    id: 'SHIPS_IN_4_6_WEEKS_US',
    defaultMessage: 'Usually ships in 4 to 6 weeks.'
  },
  SHIPS_IN_2_DAYS_ROW: {
    id: 'SHIPS_IN_2_DAYS_ROW',
    defaultMessage: 'Usually ships in 2 business days. (Orders outside of US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_IN_3_WEEKS_ROW: {
    id: 'SHIPS_IN_3_WEEKS_ROW',
    defaultMessage: 'Ships within 3 weeks. (Orders outside of US and Europe, please allow an additional 1-2 weeks for delivery'
  },
  'SHIPS_IN_3-5_DAYS': {
    id: 'SHIPS_IN_3-5_DAYS',
    defaultMessage: 'Standard purification ships from US in 3-5 business days. Allow 2 additional business days for HPLC'
  },
  SHIPS_IN_5_DAYS: {
    id: 'SHIPS_IN_5_DAYS',
    defaultMessage: 'Ships within 5 business days'
  },
  SHIPS_IN_5_DAYS_ROW: {
    id: 'SHIPS_IN_5_DAYS_ROW',
    defaultMessage: 'Usually ships in 5 business days. (Orders outside of US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_WITHIN_10_DAYS_US: {
    id: 'SHIPS_WITHIN_10_DAYS_US',
    defaultMessage: 'Ships within 10 business days.'
  },
  SHIPS_WITHIN_10_DAYS_US1: {
    id: 'SHIPS_WITHIN_10_DAYS_US1',
    defaultMessage: 'Ships within 10 business days. upon receipt of valid TTB Documents,  30% off price for 5+ units, 15% off for 3'
  },
  SHIPS_WITHIN_11_WEEKS_US: {
    id: 'SHIPS_WITHIN_11_WEEKS_US',
    defaultMessage: 'Ships in 11 Weeks.'
  },
  SHIPS_WITHIN_2_DAYS_US: {
    id: 'SHIPS_WITHIN_2_DAYS_US',
    defaultMessage: 'Ships within 2 business days.'
  },
  SHIPS_WITHIN_2_WEEKS_ROW1: {
    id: 'SHIPS_WITHIN_2_WEEKS_ROW1',
    defaultMessage: 'Ships within 2 weeks. (Orders outside of US and Europe, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_WITHIN_2_WEEKS_US: {
    id: 'SHIPS_WITHIN_2_WEEKS_US',
    defaultMessage: 'Ships within 2 weeks.'
  },
  SHIPS_WITHIN_3_WEEKS_US: {
    id: 'SHIPS_WITHIN_3_WEEKS_US',
    defaultMessage: 'Ships within 3 weeks.'
  },
  SHIPS_WITHIN_4_DAYS_US: {
    id: 'SHIPS_WITHIN_4_DAYS_US',
    defaultMessage: 'Ships within 4 business days.'
  },
  SHIPS_WITHIN_4_WEEKS_ROW: {
    id: 'SHIPS_WITHIN_4_WEEKS_ROW',
    defaultMessage: 'Usually Ships in 4 Weeks. (Orders outside of US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_WITHIN_4_WEEKS_US: {
    id: 'SHIPS_WITHIN_4_WEEKS_US',
    defaultMessage: 'Ships in 4 Weeks.'
  },
  SHIPS_WITHIN_5_DAYS_US1: {
    id: 'SHIPS_WITHIN_5_DAYS_US1',
    defaultMessage: 'Ships within 5 business days upon receipt of valid TTB Documents,  30% off price for 5+ units, 15% off for 3'
  },
  SHIPS_WITHIN_7_10_DAYS_ROW: {
    id: 'SHIPS_WITHIN_7_10_DAYS_ROW',
    defaultMessage: 'Ships within 7-10 business days. (orders outside the US, please allow an additional 1-2 weeks for delivery)'
  },
  SHIPS_IN_3_4_6_WEEKS: {
    id: 'SHIPS_IN_3_4_6_WEEKS',
    defaultMessage: 'Usually ships in 3 weeks (4 to 6 weeks for custom orders).'
  },
  PT_TESTING_STUDY: {
    id: 'PT_TESTING_STUDY',
    defaultMessage: 'Indicate Proficiency Testing (PT) study type at check out, under ‘your reference’. New users visit {link} to register, enter data, download reporting packets, or access final results.'
  },
  PT_TESTING_STUDY_LINK: {
    id: 'PT_TESTING_STUDY_LINK',
    defaultMessage: 'supelco-pt.com'
  },
  TITLE: {
    id: 'TITLE',
    defaultMessage: 'Title'
  },
  DATE_MODIFIED: {
    id: 'DATE_MODIFIED',
    defaultMessage: 'Date Modified'
  },
  DOWNLOAD_WILL_BEGIN_SHORTLY: {
    id: 'DOWNLOAD_WILL_BEGIN_SHORTLY',
    defaultMessage: 'Download will begin shortly'
  },
  CUSTOMERS_ALSO_VIEWED: {
    id: 'CUSTOMERS_ALSO_VIEWED',
    defaultMessage: 'Customers Also Viewed'
  },
  TPP_AVAILABILITY_MESSAGE: {
    id: 'TPP_AVAILABILITY_MESSAGE',
    defaultMessage: 'Ships in {messageVariable1} days - from Aldrich Partner.\n{messageVariable2}'
  },
  OUT_OF_STOCK_KEY: {
    id: 'OUT_OF_STOCK_KEY',
    defaultMessage: 'We apologize but fulfillment and delivery of this product is delayed. We are working to minimize these delays as quickly as possible.'
  },
  ITEM_AVAILABILITY_DIRECTLY_FROM_MANUFACTURER: {
    id: 'ITEM_AVAILABILITY_DIRECTLY_FROM_MANUFACTURER',
    defaultMessage: 'This item will ship directly from the manufacturer.'
  },
  ITEM_AVAILABILITY_FROM_THIRD_PARTY_WAREHOUSE: {
    id: 'ITEM_AVAILABILITY_FROM_THIRD_PARTY_WAREHOUSE',
    defaultMessage: 'This item will ship from a third party warehouse.'
  },
  ITEM_AVAILABILITY_DIRECTLY_FROM_WOODLANDS: {
    id: 'ITEM_AVAILABILITY_DIRECTLY_FROM_WOODLANDS',
    defaultMessage: 'Ships directly from The Woodlands, Texas.'
  },
  PRODUCT_SOURCING: {
    id: 'PRODUCT_SOURCING',
    defaultMessage: 'Product Sourcing'
  },
  PRODUCT_HAS_NO_SALES_HISTORY: {
    id: 'PRODUCT_HAS_NO_SALES_HISTORY',
    defaultMessage: 'Product {productNumber} has no sales history'
  },
  PRODUCT_WILL_BE_REVIEWED_BY_OUR_COMPLIANCE_TEAM_ADDITIONAL_LEAD_TIME_IS_EXPECTED: {
    id: 'PRODUCT_WILL_BE_REVIEWED_BY_OUR_COMPLIANCE_TEAM_ADDITIONAL_LEAD_TIME_IS_EXPECTED',
    defaultMessage: 'Product {productNumber} will be reviewed by our compliance team. Additional lead time is expected'
  },
  THIS_ITEM_MAY_HAVE_LIMITED_QUANLITY_AND_SAFETY_DOCUMENTATION_ONCE_ORDERED_IT_MAY_NOT_BE_CANCELLED_OR_RETURNED: {
    id: 'THIS_ITEM_MAY_HAVE_LIMITED_QUANLITY_AND_SAFETY_DOCUMENTATION_ONCE_ORDERED_IT_MAY_NOT_BE_CANCELLED_OR_RETURNED',
    defaultMessage: 'This item may have limited quantity and safety documentation. Once ordered it may not be cancelled or returned'
  },
  DELIVERY_OF_THIS_ITEM_MAY_BE_DELAYED_DUE_TO_TRANSPORTATION_REGULATION: {
    id: 'DELIVERY_OF_THIS_ITEM_MAY_BE_DELAYED_DUE_TO_TRANSPORTATION_REGULATION',
    defaultMessage: 'Delivery of this item may be delayed due to transportation regulation'
  },
  DELIVERY_OF_THIS_ITEM_MAY_BE_DELAYED_DUE_TO_REGULATORY_OR_COMPLIANCE_RESTRICTIONS: {
    id: 'DELIVERY_OF_THIS_ITEM_MAY_BE_DELAYED_DUE_TO_REGULATORY_OR_COMPLIANCE_RESTRICTIONS',
    defaultMessage: 'Delivery of this item may be delayed due to regulatory or compliance restrictions'
  },
  FREE_FREIGHT: {
    id: 'FREE_FREIGHT',
    defaultMessage: 'Free Freight'
  },
  FREE_FREIGHT_MESSAGE: {
    id: 'FREE_FREIGHT_MESSAGE',
    defaultMessage: 'This product is eligible for free freight. Conditions apply.'
  },
  RELATED_CATEGORIES: {
    id: 'RELATED_CATEGORIES',
    defaultMessage: 'Related Categories'
  },
  CANNOT_CONTAIN_OTHER_PRODUCT_TYPE_WITH_EMPROVE_PRODUCTS: {
    id: 'CANNOT_CONTAIN_OTHER_PRODUCT_TYPE_WITH_EMPROVE_PRODUCTS',
    defaultMessage: 'Emprove® Products cannot be added to the cart with other items. There are products in your cart already, \n so we cannot proceed. Please complete your existing purchase or remove the items to continue.'
  },
  CANNOT_CONTAIN_SAME_DOSSIER_WITH_EMPROVE_PRODUCTS: {
    id: 'CANNOT_CONTAIN_SAME_DOSSIER_WITH_EMPROVE_PRODUCTS',
    defaultMessage: 'Unable to Add Duplicate, The item you are trying to buy is already in the cart. It cannot be added twice.'
  },
  SEARCH_BY_MATERIAL_NUMBER_OR_TITLE: {
    id: 'SEARCH_BY_MATERIAL_NUMBER_OR_TITLE',
    defaultMessage: 'Search by Material Number or Title'
  },
  VERIFYING_EMAIL_ADDRESS: {
    id: 'VERIFYING_EMAIL_ADDRESS',
    defaultMessage: 'Verifying your email address...'
  },
  VERIFICATION_LINK_EXPIRED_SHORT: {
    id: 'VERIFICATION_LINK_EXPIRED_SHORT',
    defaultMessage: 'The verification link has expired.'
  },
  VERIFICATION_LINK_EXPIRED_FULL: {
    id: 'VERIFICATION_LINK_EXPIRED_FULL',
    defaultMessage: 'In order to verify your email address, please login and proceed to your profile. In your profile you can re-send a verification email.'
  },
  VERIFICATION_SUCCESS_LOGGED_IN_SHORT: {
    id: 'VERIFICATION_SUCCESS_LOGGED_IN_SHORT',
    defaultMessage: 'Your email address has been verified.'
  },
  VERIFICATION_SUCCESS_LOGGED_IN_FULL: {
    id: 'VERIFICATION_SUCCESS_LOGGED_IN_FULL',
    defaultMessage: 'Thank you for successfully completing the email verification process. You can proceed with your SigmaAldrich.com shopping.'
  },
  VERIFICATION_SUCCESS_LOGGED_OUT_SHORT: {
    id: 'VERIFICATION_SUCCESS_LOGGED_OUT_SHORT',
    defaultMessage: 'Thank you. Your email {email} has been verified.'
  },
  VERIFICATION_SUCCESS_LOGGED_OUT_FULL: {
    id: 'VERIFICATION_SUCCESS_LOGGED_OUT_FULL',
    defaultMessage: 'Now that your e-mail address has been verified, you have access to Emprove Material Qualification Dossiers. They can be found on Product Detail Pages for products supported by the Emprove Program. {clickMe} for more information about the full subscription service.'
  },
  ACCESS_EMPROVE_DOCUMENTATION: {
    id: 'ACCESS_EMPROVE_DOCUMENTATION',
    defaultMessage: 'Access your Emprove® documentation in the Subscriptions section of your Account.'
  },
  FILTER_BY_TYPE: {
    id: 'FILTER_BY_TYPE',
    defaultMessage: 'Filter by Type'
  },
  FILTER_AND_SORT_HEADING: {
    id: 'FILTER_AND_SORT_HEADING',
    defaultMessage: 'Filter & Sort'
  },
  SORT_HEADING: {
    id: 'SORT_HEADING',
    defaultMessage: 'Sort'
  },
  FILTER_BY_KEYWORD: {
    id: 'FILTER_BY_KEYWORD',
    defaultMessage: 'Filter by Keyword'
  },
  DISPLAYING_RESULTS: {
    id: 'DISPLAYING_RESULTS',
    defaultMessage: 'Displaying {range} of {total} results'
  },
  AX: {
    id: 'AX',
    defaultMessage: 'American Express'
  },
  VI: {
    id: 'VI',
    defaultMessage: 'Visa'
  },
  MC: {
    id: 'MC',
    defaultMessage: 'Mastercard'
  },
  CREDIT_CARD: {
    id: 'CC_ENDING_IN',
    defaultMessage: '{type, select,\n      VI {Visa}\n      MC {MasterCard}\n      AX {American Express}\n      other {Credit Card}\n    } ending in {number}'
  },
  MATERIAL_QUALIFICATION: {
    id: 'MATERIAL_QUALIFICATION',
    defaultMessage: 'Material Qualification'
  },
  QUALITY_MANAGEMENT: {
    id: 'QUALITY_MANAGEMENT',
    defaultMessage: 'Quality Management'
  },
  OPERATIONAL_EXCELLENCE: {
    id: 'OPERATIONAL_EXCELLENCE',
    defaultMessage: 'Operational Excellence'
  },
  ELEMENTAL_IMPURITIES_INFORMATION: {
    id: 'ELEMENTAL_IMPURITIES_INFORMATION',
    defaultMessage: 'Elemental Impurities Information'
  },
  EMPROVE_API_INFORMATION_PACKAGE: {
    id: 'EMPROVE_API_INFORMATION_PACKAGE',
    defaultMessage: 'Emprove® API Information Package'
  },
  QUALITY_MANAGEMENT_DOSSIER: {
    id: 'QUALITY_MANAGEMENT_DOSSIER',
    defaultMessage: 'Quality Management Dossier (Open Access)'
  },
  SELECTION_CHIP_REMOVE_FILTER_1: {
    id: 'SELECTION_CHIP_REMOVE_FILTER_1',
    defaultMessage: 'Remove'
  },
  SELECTION_CHIP_REMOVE_FILTER_2: {
    id: 'SELECTION_CHIP_REMOVE_FILTER_2',
    defaultMessage: 'filter'
  },
  SELECTION_CHIP_REMOVE_TO_PERFORM_FACET_SEARCH: {
    id: 'SELECTION_CHIP_REMOVE_TO_PERFORM_FACET_SEARCH',
    defaultMessage: 'keyword to perform facet search'
  },
  PROTOCOLS_AND_ARTICLES: {
    id: 'PROTOCOLS_AND_ARTICLES',
    defaultMessage: 'Protocols and Articles'
  },
  PROTOCOLS: {
    id: 'PROTOCOLS',
    defaultMessage: 'Protocols'
  },
  ARTICLES: {
    id: 'ARTICLES',
    defaultMessage: 'Articles'
  },
  RELATED_CONTENT: {
    id: 'RELATED_CONTENT',
    defaultMessage: 'Related Content'
  },
  READ_MORE: {
    id: 'READ_MORE',
    defaultMessage: 'Read More'
  },
  SAVED_CART_EXTENSION: {
    id: 'SAVED_CART_EXTENSION',
    defaultMessage: 'Extension'
  },
  SAVED_CART_MOBILE: {
    id: 'SAVED_CART_MOBILE',
    defaultMessage: 'Mobile'
  },
  SAVED_CART_FAX: {
    id: 'SAVED_CART_FAX',
    defaultMessage: 'Fax'
  },
  REGULATED_PRODUCT_WARNING: {
    id: 'REGULATED_PRODUCT_WARNING',
    defaultMessage: 'Regulated Information Detail'
  },
  VIEW_REPLACEMENT_PRODUCTS: {
    id: 'VIEW_REPLACEMENT_PRODUCTS',
    defaultMessage: 'View Replacement Product(s)'
  },
  VIEW_SIMILAR_PRODUCTS: {
    id: 'VIEW_SIMILAR_PRODUCTS',
    defaultMessage: 'View Similar Product(s)'
  },
  PRODUCT_DISCONTINUED: {
    id: 'PRODUCT_DISCONTINUED',
    defaultMessage: 'Product {materialNumber} has been discontinued.'
  },
  REPLACEMENT_PRODUCTS_TITLE: {
    id: 'REPLACEMENT_PRODUCTS_TITLE',
    defaultMessage: 'View Replacement Product(s) for {materialNumber} {materialName}'
  },
  REPLACEMENT_PRODUCTS_FOR: {
    id: 'REPLACEMENT_PRODUCTS_FOR',
    defaultMessage: 'Replacement Product(s) for {materialNumber}'
  },
  COMPARE_SELECTED_WITH: {
    id: 'COMPARE_SELECTED_WITH',
    defaultMessage: 'Compare Selected Product(s) with {productNumber} (Up to 4 maximum)'
  },
  NO_SAVED_CART_FOUND: {
    id: 'NO_SAVED_CART_FOUND',
    defaultMessage: 'No Saved Carts were found.'
  },
  LOCAL_CUSTOMER_SERVICE: {
    id: 'LOCAL_CUSTOMER_SERVICE',
    defaultMessage: 'local customer service'
  },
  CONTACT_LOCAL_CUSTOMER_SERVICE_FOR_DELIVERY_ESTIMATE: {
    id: 'CONTACT_LOCAL_CUSTOMER_SERVICE_FOR_DELIVERY_ESTIMATE',
    defaultMessage: 'Product Availability: Please contact your  {link} for delivery estimate.'
  },
  PRODUCT_AVAILABILITY_LIMITED_AVAILABILITY: {
    id: 'PRODUCT_AVAILABILITY_LIMITED_AVAILABILITY',
    defaultMessage: 'Product Availability: Limited Availability'
  },
  CONTACT_FOR_AVAILABILITY: {
    id: 'CONTACT_FOR_AVAILABILITY',
    defaultMessage: 'Please contact Customer Service for Availability'
  },
  AVAILABILITY_UNKNOWN: {
    id: 'AVAILABILITY_UNKNOWN',
    defaultMessage: 'Availability Unknown'
  },
  LIMITED_AVAILABILITY: {
    id: 'LIMITED_AVAILABILITY',
    defaultMessage: 'Limited Availability'
  },
  DELIVERY_DELAY_RESTRICTIONS: {
    id: 'DELIVERY_DELAY_RESTRICTIONS',
    defaultMessage: 'Delivery of this item may be delayed due to regulatory or compliance restrictions.'
  },
  DELIVERY_DELAY_TRANSPORTATION: {
    id: 'DELIVERY_DELAY_TRANSPORTATION',
    defaultMessage: 'Delivery of this item may be delayed due to its transportation regulations.'
  },
  DELIVERY_SAFETY_DOCUMENTATION: {
    id: 'DELIVERY_SAFETY_DOCUMENTATION',
    defaultMessage: 'This item may have limited quality and safety documentation. Once ordered, it may not be canceled or returned.'
  },
  INQUIRE: {
    id: 'INQUIRE',
    defaultMessage: 'Inquire'
  },
  INQUIRE_FOR_PRICE_AND_AVAILABILITY: {
    id: 'INQUIRE_FOR_PRICE_AND_AVAILABILITY',
    defaultMessage: 'Please inquire for price and availability'
  },
  INQUIRE_FOR_PRICE_AND_AVAILABILITY_MODAL: {
    id: 'INQUIRE_FOR_PRICE_AND_AVAILABILITY_MODAL',
    defaultMessage: 'This item is not able to be purchased as a guest user. For further information, please contact us at {inquirePriceContactInfo}.'
  },
  EMAIL_USERNAME_LOGIN_REQUIRED: {
    id: 'EMAIL_USERNAME_LOGIN_REQUIRED',
    defaultMessage: 'Please enter a username or email address.'
  },
  PASSWORD_LOGIN_REQUIRED: {
    id: 'PASSWORD_LOGIN_REQUIRED',
    defaultMessage: 'Please enter a password.'
  },
  MOBILE_LOGIN_REQUIRED: {
    id: 'MOBILE_LOGIN_REQUIRED',
    defaultMessage: 'Please enter mobile number.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  MOBILE_FORMAT_INVALID: {
    id: 'MOBILE_FORMAT_INVALID',
    defaultMessage: 'Invalid mobile number.'
  },
  MOBILE_SECURITY_CODE_SENT: {
    id: 'MOBILE_SECURITY_CODE_SENT',
    defaultMessage: 'We sent a 6-digit security code to your mobile.'
  },
  MOBILE_SECURITY_CODE_REQUIRED: {
    id: 'MOBILE_SECURITY_CODE_REQUIRED',
    defaultMessage: 'Please enter 6-Digit Security Code.'
  },
  RESEND_CODE: {
    id: 'RESEND_CODE',
    defaultMessage: 'Resend Code'
  },
  VERIFY_MOBILE: {
    id: 'VERIFY_MOBILE',
    defaultMessage: 'Verify Mobile'
  },
  VERIFY: {
    id: 'VERIFY',
    defaultMessage: 'Verify'
  },
  VERIFIED: {
    id: 'VERIFIED',
    defaultMessage: 'Verified'
  },
  MOBILE_IS_NOT_VERIFIED: {
    id: 'MOBILE_IS_NOT_VERIFIED',
    defaultMessage: 'You haven’t verified your mobile yet. Please use username or email address to sign in. You may verify your mobile in your account profile.'
  },
  MOBILE_SECURITY_CODE_INCORRECT: {
    id: 'MOBILE_SECURITY_CODE_INCORRECT',
    defaultMessage: 'Sorry, 6-digit security code is incorrect.'
  },
  VERIFICATION_CODE_INVALID: {
    id: 'VERIFICATION_CODE_INVALID',
    defaultMessage: 'Sorry, 6-digit security code is expired'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  OVER_FREQUENT_ASKING_FOR_VERIFICATION_CODE: {
    id: 'OVER_FREQUENT_ASKING_FOR_VERIFICATION_CODE',
    defaultMessage: 'sorry, we detect frequent request of 6-digit security code. Please try again later'
  },
  THE_REPEAT_BIND_ACTION_IS_INVALID: {
    id: 'THE_REPEAT_BIND_ACTION_IS_INVALID',
    defaultMessage: 'This mobile is already existing.'
  },
  VIEW_ALL_RELATED_PAPERS: {
    id: 'VIEW_ALL_RELATED_PAPERS',
    defaultMessage: 'View All Related Papers'
  },
  PRODUCT_IMAGES_VIEWING: {
    id: 'PRODUCT_IMAGES_VIEWING',
    defaultMessage: '{index} of {count}'
  },
  B2B_CHECKOUT_ERROR: {
    id: 'B2B_CHECKOUT_ERROR',
    defaultMessage: 'An error occurred during checkout: {errorMessage}'
  },
  B2B_LOGOUT_ERROR: {
    id: 'B2B_LOGOUT_ERROR',
    defaultMessage: 'An error occurred during checkout: {errorMessage}'
  },
  CREDIT_CARD_CVV: {
    id: 'CREDIT_CARD_CVV',
    defaultMessage: 'CVV'
  },
  FORGOT_PASSWORD_HEAD: {
    id: 'FORGOT_PASSWORD_HEAD',
    defaultMessage: 'Forgot Password?'
  },
  AND: {
    id: 'AND',
    defaultMessage: 'and'
  },
  SUBSTANCE_VIEW_PRICING: {
    id: 'SUBSTANCE_VIEW_PRICING',
    defaultMessage: 'View Pricing'
  },
  VIEW_DETAILS: {
    id: 'VIEW_DETAILS',
    defaultMessage: 'View Details'
  },
  VIEW_PRODUCT_DETAILS: {
    id: 'VIEW_PRODUCT_DETAILS',
    defaultMessage: 'View Product Details'
  },
  B2C_NOT_AUTHORIZED_TO_ACCESS: {
    id: 'B2C_NOT_AUTHORIZED_TO_ACCESS',
    defaultMessage: 'This site is not intended for direct login.'
  },
  USER_ACCOUNT_LOCKED_90DAYS_EXPIRY: {
    id: 'USER_ACCOUNT_LOCKED_90DAYS_EXPIRY',
    defaultMessage: 'Your account has been locked due to inactivity. Please contact {email}.'
  },
  B2C_PLEASE_VISIT_MESSAGE: {
    id: 'B2C_PLEASE_VISIT_MESSAGE',
    defaultMessage: 'Please visit {url}.'
  },
  CREDIT_CARD_NUMBER_INVALID_LENGTH: {
    id: 'CREDIT_CARD_NUMBER_INVALID_LENGTH',
    defaultMessage: 'Invalid Card Number Entered'
  },
  CREDIT_CARD_NUMBER_INVALID_EXPIRY: {
    id: 'CREDIT_CARD_NUMBER_INVALID_EXPIRY',
    defaultMessage: 'Credit Card Expiration Date is in the Past'
  },
  ADDITIONAL_INFO_SPECIES: {
    id: 'ADDITIONAL_INFO_SPECIES',
    defaultMessage: 'Species:'
  },
  CONTACT_LOCAL_DEALER: {
    id: 'CONTACT_LOCAL_DEALER',
    defaultMessage: 'To order products, please {contactLink} your local dealer.'
  },
  CONTACT: {
    id: 'CONTACT',
    defaultMessage: 'contact'
  },
  CLICK_HERE: {
    id: 'CLICK_HERE',
    defaultMessage: 'Click here'
  },
  CHANGE_LANGUAGE: {
    id: 'CHANGE_LANGUAGE',
    defaultMessage: 'Change Language'
  },
  PIPELINE_PARTNERS_EMPTY: {
    id: 'PIPELINE_PARTNERS_EMPTY',
    defaultMessage: 'Partners are required for all B2B and Pipeline Users.'
  },
  PIPELINE_GENERIC_ERROR: {
    id: 'PIPELINE_GENERIC_ERROR',
    defaultMessage: 'Selected Organization requires updates or corrections to the detail record in order to be assigned to a user.'
  },
  SHOPPING_CART_SUMMARY: {
    id: 'SHOPPING_CART_SUMMARY',
    defaultMessage: 'SUMMARY'
  },
  SELECT_PASSWORD_COMPLETE_PROFILE: {
    id: 'SELECT_PASSWORD_COMPLETE_PROFILE',
    defaultMessage: 'Please select a new password to complete your profile and unlock the benefits of our site.'
  },
  COMPLETE_PROFILE_PASSWORD_INFO: {
    id: 'COMPLETE_PROFILE_PASSWORD_INFO',
    defaultMessage: "Select password that you haven't used in the last 6 months."
  },
  ENTER_PASSWORD: {
    id: 'ENTER_PASSWORD',
    defaultMessage: 'Enter Password'
  },
  RE_ENTER_PASSWORD: {
    id: 'RE_ENTER_PASSWORD',
    defaultMessage: 'Re-Enter Password'
  },
  MANUAL_ENTRY_INSTRUCTIONS_QUICK_ORDER_NOT_AVAILABLE: {
    id: 'MANUAL_ENTRY_INSTRUCTIONS_QUICK_ORDER_NOT_AVAILABLE',
    defaultMessage: 'Quick Order is not available for your profile. If you have any questions please contact the web help desk.'
  },
  SHOW_CAPTION: {
    id: 'SHOW_CAPTION',
    defaultMessage: 'Show Caption'
  },
  HIDE_CAPTION: {
    id: 'HIDE_CAPTION',
    defaultMessage: 'Hide Caption'
  },
  ORDER_TRACKING_TIPS: {
    id: 'ORDER_TRACKING_TIPS',
    defaultMessage: '温馨提示'
  },
  PERSONAL_PROTECTIVE_EQUIPMENT: {
    id: 'PERSONAL_PROTECTIVE_EQUIPMENT',
    defaultMessage: 'Personal Protective Equipment'
  },
  DUST_MASK_TYPE_N95: {
    id: 'DUST_MASK_TYPE_N95',
    defaultMessage: 'dust mask type N95 (US)'
  },
  EYESHIELDS: {
    id: 'EYESHIELDS',
    defaultMessage: 'Eyeshields'
  },
  GLOVES: {
    id: 'GLOVES',
    defaultMessage: 'Gloves'
  },
  GENE_CUSTOM_PRODUCTS: {
    id: 'GENE_CUSTOM_PRODUCTS',
    defaultMessage: 'Custom & Knockdown Gene Products'
  },
  GENE_SIRNA_LINK_TITLE: {
    id: 'GENE_SIRNA_LINK_TITLE',
    defaultMessage: 'siRNA'
  },
  GENE_SIRNA_LINK_DESCRIPTION: {
    id: 'GENE_SIRNA_LINK_DESCRIPTION',
    defaultMessage: 'Shop predesigned siRNA that are designed using the proprietary Rosetta Inpharmatics algorithm.'
  },
  GENE_SHRNA_LINK_TITLE: {
    id: 'GENE_SHRNA_LINK_TITLE',
    defaultMessage: 'shRNA'
  },
  GENE_SHRNA_LINK_DESCRIPTION: {
    id: 'GENE_SHRNA_LINK_DESCRIPTION',
    defaultMessage: 'Shop the largest, most validated shRNA collection and build your own clone sets.'
  },
  GENE_CUSTOM_LINK_TITLE: {
    id: 'GENE_CUSTOM_LINK_TITLE',
    defaultMessage: 'Custom Gene Products'
  },
  GENE_CUSTOM_LINK_DESCRIPTION: {
    id: 'GENE_CUSTOM_LINK_DESCRIPTION',
    defaultMessage: 'We offer a comprehensive portfolio of DNA oligos, RNA oligos and predesigned primers.'
  },
  PROFILE_COMPLETE_LOGIN_AGAIN: {
    id: 'PROFILE_COMPLETE_LOGIN_AGAIN',
    defaultMessage: 'Your password has been successfully updated. Please sign in to view your profile.'
  },
  SELECT_ATTRIBUTE: {
    id: 'SELECT_ATTRIBUTE',
    defaultMessage: 'Select Attribute'
  },
  SELECT_ATTRIBUTES: {
    id: 'SELECT_ATTRIBUTES',
    defaultMessage: 'Select Attributes'
  },
  SORT_BY: {
    id: 'SORT_BY',
    defaultMessage: 'Sort by'
  },
  DEFAULT: {
    id: 'DEFAULT',
    defaultMessage: 'Default'
  },
  SET_AS_DEFAULT: {
    id: 'SET_AS_DEFAULT',
    defaultMessage: "Set as \n default"
  },
  ATTRIBUTES: {
    id: 'ATTRIBUTES',
    defaultMessage: 'Attributes'
  },
  SORT_BY_RELEVANCE: {
    id: 'SORT_BY_RELEVANCE',
    defaultMessage: 'Sort by Relevance'
  },
  SORT_BY_NAME_ASCENDING: {
    id: 'SORT_BY_NAME_ASCENDING',
    defaultMessage: 'Sort by Name (Ascending)'
  },
  SORT_BY_NAME_DESCENDING: {
    id: 'SORT_BY_NAME_DESCENDING',
    defaultMessage: 'Sort by Name (Descending)'
  },
  SEARCH_TERM_REQUIRED: {
    id: 'SEARCH_TERM_REQUIRED',
    defaultMessage: 'Search Term Required'
  },
  BASIC_REGISTRATION_COMPLETE_KOREA: {
    id: 'BASIC_REGISTRATION_COMPLETE_KOREA',
    defaultMessage: 'Basic web registration has been completed. To complete full registration to make purchase order, please go to {link} (※ Customer Support Team: sakr@merckgroup.com / 02-2185-1700)'
  },
  ORDER_CANNOT_PROCESSED_KOREA: {
    id: 'ORDER_CANNOT_PROCESSED_KOREA',
    defaultMessage: 'Your order cannot be processed. To make purchase order, please complete Full Registration here: {link} (※ Customer Support Team: sakr@merckgroup.com / 02-2185-1700)'
  },
  NEW_CUSTOMER_UPDATE_KOREA_LINK: {
    id: 'NEW_CUSTOMER_UPDATE_KOREA_LINK',
    defaultMessage: 'New customer registration and information update'
  },
  RECYCLER_SKU: {
    id: 'RECYCLER_SKU',
    defaultMessage: 'This made-to-order SKU is packaged in a Returnable Container and may require a deposit. To learn more, contact ReCycler@milliporesigma.com.'
  },
  DO_NOT_HAVE_AN_ACCOUNT: {
    id: 'DO_NOT_HAVE_AN_ACCOUNT',
    defaultMessage: "Don't Have An Account?"
  },
  VIEW_CART_FOR_PRICING: {
    id: 'VIEW_CART_FOR_PRICING',
    defaultMessage: 'View Cart for Pricing'
  },
  WELCOME_DEJA_CUSTOMER: {
    id: 'WELCOME_DEJA_CUSTOMER',
    defaultMessage: 'Welcome {userName}. How can we help you?'
  },
  DEJA_HOME: {
    id: 'DEJA_HOME',
    defaultMessage: 'MilliporeSigma | {userCountry}'
  },
  SHIPPING_DATE_TO_BE_DETERMINED: {
    id: 'SHIPPING_DATE_TO_BE_DETERMINED',
    defaultMessage: 'Shipping date to be determined'
  },
  GO_TO_ORDERS: {
    id: 'GO_TO_ORDERS',
    defaultMessage: 'Go to Orders'
  },
  EXPIRED_ABBREVIATED: {
    id: 'EXPIRED_ABBREVIATED',
    defaultMessage: 'Exp. '
  },
  GO_TO_QUOTES: {
    id: 'GO_TO_QUOTES',
    defaultMessage: 'Go to Quotes'
  },
  GO_TO_LISTS: {
    id: 'GO_TO_LISTS',
    defaultMessage: 'Go to Lists'
  },
  GO_TO_LIST: {
    id: 'GO_TO_LIST',
    defaultMessage: 'Go to List'
  },
  GO_TO_SAVED_CART: {
    id: 'GO_TO_SAVED_CART',
    defaultMessage: 'Go to Saved Cart'
  },
  GO_TO_SAVED_CARTS: {
    id: 'GO_TO_SAVED_CARTS',
    defaultMessage: 'Go to Saved Carts'
  },
  ORDER_MODAL_TITLE: {
    id: 'ORDER_MODAL_TITLE',
    defaultMessage: 'Order {orderNumber} - '
  },
  QUOTE_MODAL_TITLE: {
    id: 'QUOTE_MODAL_TITLE',
    defaultMessage: 'Quote {quoteNumber} - '
  },
  QUOTES_MODAL_ALERT: {
    id: 'QUOTES_MODAL_ALERT',
    defaultMessage: 'PLEASE NOTE: To ensure that you receive your quoted prices, these items will be added directly to your Shopping Cart and you will leave Quick Order. Product SKUs you have entered into Quick Order will not be saved.'
  },
  QUOTES_REMAINING_QUANTITY: {
    id: 'QUOTES_REMAINING_QUANTITY',
    defaultMessage: '{remainingQuantity} of {quantity}'
  },
  ADD_SELECTED: {
    id: 'ADD_SELECTED',
    defaultMessage: 'Add Selected'
  },
  ADD_ALL: {
    id: 'ADD_ALL',
    defaultMessage: 'Add All'
  },
  ADD_SELECTED_GO_TO_CART: {
    id: 'ADD_SELECTED_GO_TO_CART',
    defaultMessage: 'Add Selected & Go To Cart'
  },
  ADD_ALL_GO_TO_CART: {
    id: 'ADD_ALL_GO_TO_CART',
    defaultMessage: 'Add All & Go To Cart'
  },
  SIGN_OUT: {
    id: 'SIGN_OUT',
    defaultMessage: 'Sign Out'
  },
  ONLY_FEW_LEFT_IN_STOCK: {
    id: 'ONLY_FEW_LEFT_IN_STOCK',
    defaultMessage: 'Only {qty} left in stock (more on the way)'
  },
  LISTS_QUICK_ORDER_MODAL_TITLE: {
    id: 'LISTS_QUICK_ORDER_MODAL_TITLE',
    defaultMessage: '{listName} -  Lists'
  },
  NO_LISTS_ADDED_YET: {
    id: 'NO_LISTS_ADDED_YET',
    defaultMessage: 'No Lists Added Yet'
  },
  MINIMUM_SEQUENCE_LENGTH: {
    id: 'MINIMUM_SEQUENCE_LENGTH',
    defaultMessage: ' Minimum sequence length: 13'
  },
  MAXIMUM_SEQUENCE_LENGTH: {
    id: 'MAXIMUM_SEQUENCE_LENGTH',
    defaultMessage: ' Maximum sequence length: 35'
  },
  MINIMUM_SEQUENCE_LENGTH_JA: {
    id: 'MINIMUM_SEQUENCE_LENGTH_JA',
    defaultMessage: ' 最小の配列の長さは13です'
  },
  MAXIMUM_SEQUENCE_LENGTH_JA: {
    id: 'MAXIMUM_SEQUENCE_LENGTH_JA',
    defaultMessage: ' 配列の最長の長さは35'
  },
  BROWSE_FILES: {
    id: 'BROWSE_FILES',
    defaultMessage: 'Browse Files'
  },
  SDS_NOT_FOUND: {
    id: 'SDS_NOT_FOUND',
    defaultMessage: 'SDS Not Found'
  },
  SDS_NOT_FOUND_MESSAGE: {
    id: 'SDS_NOT_FOUND_MESSAGE',
    defaultMessage: 'We were unable to locate an SDS for the selected product. Safety Data Sheets are provided according to OSHA 29 CFR 1910.1200. Some may be temporarily unavailable on this site or you may have encountered an error with the product number. Please contact Customer Service or submit a request for the SDS.'
  },
  REQUEST_SDS: {
    id: 'REQUEST_SDS',
    defaultMessage: 'Request SDS'
  },
  CONF_NGSO_ADDITIONAL_SERVICES_ANNEALING: {
    id: 'CONF_NGSO_ADDITIONAL_SERVICES_ANNEALING',
    defaultMessage: 'Annealing'
  },
  CONF_NGSO_ADDITIONAL_SERVICES_ANNEALING_POOLING: {
    id: 'CONF_NGSO_ADDITIONAL_SERVICES_ANNEALING_POOLING',
    defaultMessage: 'Annealing & Pooling'
  },
  CONF_NGSO_ADDITIONAL_SERVICES_ANNEALING_QC: {
    id: 'CONF_NGSO_ADDITIONAL_SERVICES_ANNEALING_QC',
    defaultMessage: 'Annealing QC'
  },
  FROZEN: {
    id: 'FROZEN',
    defaultMessage: 'Frozen'
  },
  SCAN_AGAIN: {
    id: 'SCAN_AGAIN',
    defaultMessage: 'Scan Again'
  },
  SCAN_NEED_MORE_INFORMATION: {
    id: 'SCAN_NEED_MORE_INFORMATION',
    defaultMessage: 'We successfully scanned your item, but need more information'
  },
  SCAN_PLEASE_USE_THE: {
    id: 'SCAN_PLEASE_USE_THE',
    defaultMessage: 'Please use the'
  },
  SCAN_ADVANCED_SEARCH: {
    id: 'SCAN_ADVANCED_SEARCH',
    defaultMessage: 'to search by material number, product number and lot number'
  },
  UNKNOWN_ERROR: {
    id: 'UNKNOWN_ERROR',
    defaultMessage: 'Unknown Error'
  },
  SCAN_UNKOWN: {
    id: 'SCAN_UNKOWN',
    defaultMessage: 'An unkown error has occurred'
  },
  SCAN_TIMEOUT: {
    id: 'SCAN_TIMEOUT',
    defaultMessage: 'Sorry we are experiencing difficulties scanning your item'
  },
  SCAN_MAKE_SURE_DATA_MATRIX: {
    id: 'SCAN_MAKE_SURE_DATA_MATRIX',
    defaultMessage: "Make sure you're scanning a data matrix"
  },
  SCAN_TRY_AGAIN: {
    id: 'SCAN_TRY_AGAIN',
    defaultMessage: 'Return to scan search to try again'
  },
  SCAN_HAVING_DIFFICULTY: {
    id: 'SCAN_HAVING_DIFFICULTY',
    defaultMessage: 'Having difficulty scanning?'
  },
  CONTACT_CUSTOMER_SERVICE: {
    id: 'CONTACT_CUSTOMER_SERVICE',
    defaultMessage: 'contact customer service'
  },
  EDIT_PLATE_NAME: {
    id: 'EDIT_PLATE_NAME',
    defaultMessage: 'Edit Plate Name'
  },
  PLATE_NAME: {
    id: 'PLATE_NAME',
    defaultMessage: 'Plate Name'
  },
  RECEIVE_MONTHLY_EMAIL_UPDATES: {
    id: 'RECEIVE_MONTHLY_EMAIL_UPDATES',
    defaultMessage: 'Receive Monthly Email Updates'
  },
  SWITCH_ACCOUNTS: {
    id: 'SWITCH_ACCOUNTS',
    defaultMessage: 'Switch Accounts'
  },
  SWITCH_ACCOUNTS_SUB: {
    id: 'SWITCH_ACCOUNTS_SUB',
    defaultMessage: 'Select an organization account for purchasing.'
  },
  TURN_ON_NOTIFICATIONS: {
    id: 'TURN_ON_NOTIFICATIONS',
    defaultMessage: 'Turn on notifications to get the latest updates on your orders'
  },
  TURN_ON: {
    id: 'TURN_ON',
    defaultMessage: 'Turn on'
  },
  TURNING_ON: {
    id: 'TURNING_ON',
    defaultMessage: 'Turning on'
  },
  NOTIFICATIONS_ENABLED: {
    id: 'NOTIFICATIONS_ENABLED',
    defaultMessage: 'Notifications have been enabled'
  },
  TRIAL_LICENSES: {
    id: 'TRIAL_LICENSES',
    defaultMessage: 'Trial Licenses'
  },
  PURCHASED_LICENSES: {
    id: 'PURCHASED_LICENSES',
    defaultMessage: 'Purchased Licenses'
  },
  NO_LICENSES_FOUND: {
    id: 'NO_LICENSES_FOUND',
    defaultMessage: 'No licenses found.'
  },
  SOFTWARE: {
    id: 'SOFTWARE',
    defaultMessage: 'Software'
  },
  INSTRUMENT_SERIAL_NUMBER: {
    id: 'INSTRUMENT_SERIAL_NUMBER',
    defaultMessage: 'Instrument Serial #'
  },
  LICENSE_KEY: {
    id: 'LICENSE_KEY',
    defaultMessage: 'License Key'
  },
  COPY: {
    id: 'COPY',
    defaultMessage: 'Copy'
  },
  SOFTWARE_LIBRARY_ORDER_NUMBER: {
    id: 'SOFTWARE_LIBRARY_ORDER_NUMBER',
    defaultMessage: 'Order #'
  },
  PURCHASED: {
    id: 'PURCHASED',
    defaultMessage: 'Purchased'
  },
  ALDRICH_MARKET_SELECT_AMS_ORDER_CENTER: {
    id: 'ALDRICH_MARKET_SELECT_AMS_ORDER_CENTER',
    defaultMessage: 'Aldrich Market Select (AMS) Order Center'
  },
  ALDRICH_MARKET_SELECT_DESCRIPTION: {
    id: 'ALDRICH_MARKET_SELECT_DESCRIPTION',
    defaultMessage: 'The Aldrich Market Select system provides customers a strategy to find and purchase screening compounds and building blocks from chemical suppliers that Sigma-Aldrich has selected as reliable business partners. Products range from drug-like compounds for testing in molecular screening to synthetic building blocks for use in chemical synthesis. This portal allows customers to utilize their B2B purchasing systems to check-out and order custom price quotations requested through the Aldrich Market Select system.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  AMS_QUOTE_NUMBER_IS_EMPTY: {
    id: 'AMS_QUOTE_NUMBER_IS_EMPTY',
    defaultMessage: 'AMS Quote Number is empty.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  AMS_QUOTE_NUMBER_FORMAT_IS_NOT_VALID: {
    id: 'AMS_QUOTE_NUMBER_FORMAT_IS_NOT_VALID',
    defaultMessage: 'AMS Quote Number format is not valid.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  AMS_QUOTE_NOT_FOUND: {
    id: 'AMS_QUOTE_NOT_FOUND',
    defaultMessage: 'AMS Quote not found.'
  },
  //Message returned as endpoint error. Do not modify or delete key.
  UNABLE_TO_GET_RESPONSE_FROM_AMS: {
    id: 'UNABLE_TO_GET_RESPONSE_FROM_AMS',
    defaultMessage: 'Unable to get response from AMS.'
  },
  CHECK_QUOTE_STATUS: {
    id: 'CHECK_QUOTE_STATUS',
    defaultMessage: 'Check quote status'
  },
  SUPPORT_INFORMATION: {
    id: 'SUPPORT_INFORMATION',
    defaultMessage: 'Support Information'
  },
  US_TOLL_FREE: {
    id: 'US_TOLL_FREE',
    defaultMessage: 'US Toll Free'
  },
  WHERE_IS_MY_QUOTE_NUMBER: {
    id: 'WHERE_IS_MY_QUOTE_NUMBER',
    defaultMessage: 'Where is my Quote Number?'
  },
  OPEN_THE_ORIGINAL_EXCEL: {
    id: 'OPEN_THE_ORIGINAL_EXCEL',
    defaultMessage: 'Open the original EXCEL quote document provided by the Aldrich Market Select customer service representative.'
  },
  ON_THE_FIRST_SHEET: {
    id: 'ON_THE_FIRST_SHEET',
    defaultMessage: "On the first sheet in the EXCEL document, copy and paste the Quote Number into the 'Enter Quote Number' form field."
  },
  QUOTE_NUMBERS_ARE_FORMATTED_AS: {
    id: 'QUOTE_NUMBERS_ARE_FORMATTED_AS',
    defaultMessage: 'Quote numbers are formatted as XXXXXX-QXXXXXX'
  },
  ENTER_QUOTE_NUMBER: {
    id: 'ENTER_QUOTE_NUMBER',
    defaultMessage: 'Enter Quote Number'
  },
  QUOTE_REFERENCE: {
    id: 'QUOTE_REFERENCE',
    defaultMessage: 'Quote Reference'
  },
  SELLER_RETURN_POLICY: {
    id: 'SELLER_RETURN_POLICY',
    defaultMessage: 'Return Policy'
  },
  SELLER_PRIVACY_POLICY: {
    id: 'SELLER_PRIVACY_POLICY',
    defaultMessage: 'Privacy Policy'
  },
  SELLER_TERMS_AND_CONDITIONS: {
    id: 'SELLER_TERMS_AND_CONDITIONS',
    defaultMessage: 'Terms and Conditions of Sale'
  },
  SELLER_CONTACT_SELLER: {
    id: 'SELLER_CONTACT_SELLER',
    defaultMessage: 'Contact Seller'
  },
  SELLER_SOLD_BY: {
    id: 'SELLER_SOLD_BY',
    defaultMessage: 'Shipped & Sold By {Brand}'
  },
  CONTACT_SELLER_HEADER: {
    id: 'CONTACT_SELLER_HEADER',
    defaultMessage: 'Contact'
  },
  CONTACT_SELLER_INSTRUCTION: {
    id: 'CONTACT_SELLER_INSTRUCTION',
    defaultMessage: 'Please complete the form below to be contacted by seller’s support specialist.'
  },
  CONTACT_SELLER_REQUIRED_TITLE: {
    id: 'CONTACT_SELLER_REQUIRED_TITLE',
    defaultMessage: 'Required Field'
  },
  CONTACT_SELLER_BUTTON_LABEL: {
    id: 'CONTACT_SELLER_BUTTON_LABEL',
    defaultMessage: 'Submit'
  },
  CONTACT_SELLER_ISSUE_QUESTION: {
    id: 'CONTACT_SELLER_ISSUE_QUESTION',
    defaultMessage: 'What is the issue/question?'
  },
  CONTACT_SELLER_ASSIST: {
    id: 'CONTACT_SELLER_ASSIST',
    defaultMessage: 'How can we assist you?'
  },
  MAX_50000_CHARACTERS: {
    id: 'MAX_50000_CHARACTERS',
    defaultMessage: 'Must be 50,000 characters max'
  },
  MIN_3_CHARACTERS: {
    id: 'MIN_3_CHARACTERS',
    defaultMessage: 'Must be 3 characters minimum'
  },
  CONTACT_SELLER_TERMS_VALIDATE: {
    id: 'CONTACT_SELLER_TERMS_VALIDATE',
    defaultMessage: 'Please agree to terms & conditions.'
  },
  CONTACT_SELLER_HAVE_QUESTION: {
    id: 'CONTACT_SELLER_HAVE_QUESTION',
    defaultMessage: 'Have a question?'
  },
  CONTACT_SELLER_SHIPPED_SOLD: {
    id: 'CONTACT_SELLER_SHIPPED_SOLD',
    defaultMessage: 'Shipped & Sold By'
  },
  CONTACT_SELLER_TRUSTED: {
    id: 'CONTACT_SELLER_TRUSTED',
    defaultMessage: 'A Trusted Marketplace Seller'
  },
  MARKETPLACE_CART: {
    id: 'MARKETPLACE_CART',
    defaultMessage: 'Marketplace Cart'
  },
  CHECKOUT_MARKETPLACE_CART: {
    id: 'CHECKOUT_MARKETPLACE_CART',
    defaultMessage: 'Checkout Marketplace Cart'
  },
  WHAT_IS_MPARTNER_MARKETPLACE: {
    id: 'WHAT IS MPARTNER MARKETPLACE?',
    defaultMessage: 'WHAT IS MPARTNER MARKETPLACE?'
  },
  WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE: {
    id: 'WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE',
    defaultMessage: 'MPartner Marketplace provides an assortment of relevant life science products from trusted third-party sellers alongside our own broad portfolio.'
  },
  WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_1: {
    id: 'WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_1',
    defaultMessage: 'TRUSTED - Sellers must pass a rigorous vetting process to ensure they meet the high reputational and service standards.'
  },
  WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_2: {
    id: 'WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_2',
    defaultMessage: 'CURATED - Experience an expertly chosen and streamlined collection of products.'
  },
  WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_3: {
    id: 'WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_3',
    defaultMessage: 'CONVENIENT - Enjoy the ease of using a single website and login for purchasing, order tracking, and quality documentation.'
  },
  DATA_PROCESSING_STATEMENT: {
    id: 'DATA_PROCESSING_STATEMENT',
    defaultMessage: 'Merck KGaA, Darmstadt, Germany will process your personal data in strict accordance with its {link}.'
  },
  CONTACT_SELLER_DONE_TITLE: {
    id: 'CONTACT_SELLER_DONE_TITLE',
    defaultMessage: 'Done! We have sent your message.'
  },
  CONTACT_SELLER_DONE_TEXT: {
    id: 'CONTACT_SELLER_DONE_TEXT',
    defaultMessage: 'Please give the seller a couple days to respond. If you haven’t heard back from the seller after 2 days, please contact us again.'
  },
  CONTACT_SELLER_GLOBAL_ERROR: {
    id: 'CONTACT_SELLER_GLOBAL_ERROR',
    defaultMessage: 'We encountered a problem trying to load this portion of the website, please view the'
  },
  CONTACT_SELLER_ADDITIONAL_OPTIONS: {
    id: 'CONTACT_SELLER_ADDITIONAL_OPTIONS',
    defaultMessage: 'page for additional options.'
  },
  CONTACT_TITLE: {
    id: 'CONTACT_TITLE',
    defaultMessage: 'Contact'
  },
  EMAIL_FROM: {
    id: 'EMAIL_FROM',
    defaultMessage: 'From'
  },
  DIGITAL_PRODUCT_SERIAL_NUMBER: {
    id: 'DIGITAL_PRODUCT_SERIAL_NUMBER',
    defaultMessage: 'Please enter your 10 digit instrument serial number:'
  },
  DIGITAL_PRODUCT_SERIAL_NUMBER_INVALID: {
    id: 'DIGITAL_PRODUCT_SERIAL_NUMBER_INVALID',
    defaultMessage: 'Please provide a valid serial number.'
  },
  DIGITAL_PRODUCT_KEY_ALREADY_GENERATED: {
    id: 'DIGITAL_PRODUCT_KEY_ALREADY_GENERATED',
    defaultMessage: 'This serial number is already in use.'
  },
  VIEW_YOUR_LICENSES: {
    id: 'VIEW_YOUR_LICENSES',
    defaultMessage: 'View your licenses'
  },
  DIGITAL_PRODUCT_CART_MIXING_ERROR: {
    id: 'DIGITAL_PRODUCT_CART_MIXING_ERROR',
    defaultMessage: 'Software items cannot be added to the cart with other items. There are products in your cart already. Please complete your existing purchase or remove the items to continue.'
  },
  CERTIFICATES: {
    id: 'CERTIFICATES',
    defaultMessage: 'Certificates'
  },
  DATA_SHEETS: {
    id: 'DATA_SHEETS',
    defaultMessage: 'Data Sheets'
  },
  INSTRUCTIONS_FOR_USE: {
    id: 'INSTRUCTIONS_FOR_USE',
    defaultMessage: 'Instructions for Use'
  },
  USER_GUIDE: {
    id: 'USER_GUIDE',
    defaultMessage: 'User Guide'
  },
  PRODUCT_INFORMATION_SHEET: {
    id: 'PRODUCT_INFORMATION_SHEET',
    defaultMessage: 'Product Information Sheet'
  },
  BROCHURES: {
    id: 'BROCHURES',
    defaultMessage: 'Brochures'
  },
  PROTOCOL: {
    id: 'PROTOCOL',
    defaultMessage: 'Protocol'
  },
  APPLICATION_NOTES: {
    id: 'APPLICATION_NOTES',
    defaultMessage: 'Application Notes'
  },
  REQUIRED_LICENSES: {
    id: 'REQUIRED_LICENSES',
    defaultMessage: 'Required Licenses'
  },
  TECHNICAL_INFORMATION: {
    id: 'TECHNICAL_INFORMATION',
    defaultMessage: 'Technical Information'
  },
  WHITE_PAPER: {
    id: 'WHITE_PAPER',
    defaultMessage: 'White Paper'
  },
  NEWSLETTER_PUBLICATION: {
    id: 'NEWSLETTER_PUBLICATION',
    defaultMessage: 'Newsletter / Publication'
  },
  POSTER: {
    id: 'POSTER',
    defaultMessage: 'Poster'
  },
  VECTOR: {
    id: 'VECTOR',
    defaultMessage: 'Vector'
  },
  CASE_STUDY: {
    id: 'CASE_STUDY',
    defaultMessage: 'Case Study'
  },
  DEMO_DOSSIER: {
    id: 'DEMO_DOSSIER',
    defaultMessage: 'Demo Dossier'
  },
  FLYER: {
    id: 'FLYER',
    defaultMessage: 'Flyer'
  },
  FORMULATION: {
    id: 'FORMULATION',
    defaultMessage: 'Formulation'
  },
  QUOTES_AND_ORDERING: {
    id: 'QUOTES_AND_ORDERING',
    defaultMessage: 'Quotes and Ordering'
  },
  MUST_ENTER_SKU: {
    id: 'MUST_ENTER_SKU',
    defaultMessage: 'Must enter a SKU.'
  },
  NEW: {
    id: 'NEW',
    defaultMessage: 'New'
  },
  ADD_TO_CART_TIP: {
    id: 'ADD_TO_CART_TIP',
    defaultMessage: 'Tip: Add products directly to your cart by simply searching for your product number or SKU (e.g. t1503 or t1503-25g).'
  },
  REQUEST_TRIAL_LICENSE: {
    id: 'REQUEST_TRIAL_LICENSE',
    defaultMessage: 'Request Trial License'
  },
  A_LICENSE_KEY_HAS_BEEN_GENERATED: {
    id: 'A_LICENSE_KEY_HAS_BEEN_GENERATED',
    defaultMessage: 'A license key has been generated.'
  },
  VIEW_MY_LICENSE: {
    id: 'VIEW_MY_LICENSE',
    defaultMessage: 'View My License'
  },
  REQUEST_LICENSE: {
    id: 'REQUEST_LICENSE',
    defaultMessage: 'Request License'
  },
  SERIAL_NUMBER: {
    id: 'SERIAL_NUMBER',
    defaultMessage: 'serial number'
  },
  QUESTIONS_ABOUT_YOUR_CART: {
    id: 'QUESTIONS_ABOUT_YOUR_CART',
    defaultMessage: 'Questions about your cart? See our {link}.'
  },
  QUESTIONS_ABOUT_YOUR_CART_NOTES_LINK: {
    id: 'QUESTIONS_ABOUT_YOUR_CART_NOTES_LINK',
    defaultMessage: 'Special Notes about ordering'
  },
  CART_SPECIAL_NOTES: {
    id: 'CART_SPECIAL_NOTES',
    defaultMessage: 'SPECIAL NOTES'
  },
  LICENSE_KEY_LOGIN_PROMPT: {
    id: 'LICENSE_KEY_LOGIN_PROMPT',
    defaultMessage: 'Please login to your account to obtain a license key.'
  },
  BLUE_PRODUCT_CERTIFICATE_NOT_FOUND: {
    id: 'BLUE_PRODUCT_CERTIFICATE_NOT_FOUND',
    defaultMessage: 'Sorry, unable to find the certificate. Please contact us to request a paper copy.'
  },
  DELIVERY_DELAY_BY_TRUCK: {
    id: 'DELIVERY_DELAY_BY_TRUCK',
    defaultMessage: 'Delivered via Merck Truck; Delivery may be delayed.'
  },
  DELIVERY_DELAY_LIMITED_QUANTITY_TRUCK: {
    id: 'DELIVERY_DELAY_LIMITED_QUANTITY',
    defaultMessage: 'This item may have limited quality and safety documentation. Once ordered, it may not be cancelled or returned. Delivered via Merck Truck; Delivery may be delayed.'
  },
  DELIVERY_DELAY_REGULATORY_OR_COMPLIANCE_RESTRICTIONS_TRUCK: {
    id: 'DELIVERY_DELAY_REGULATORY_OR_COMPLIANCE_RESTRICTIONS',
    defaultMessage: 'Delivery of this item may be delayed due to regulatory or compliance restrictions. Delivered via Merck Truck; Delivery may be delayed.'
  },
  DELIVERY_DELAY_TRANSPORTATION_REGULATIONS_TRUCK: {
    id: 'DELIVERY_DELAY_TRANSPORTATION_REGULATIONS',
    defaultMessage: ' Delivery of this item may be delayed due to its transportation regulations. Delivered via Merck Truck; Delivery may be delayed.'
  },
  ORDER_DEALER_STATUS_P: {
    id: 'Order_DEALER_STATUS_P',
    defaultMessage: 'Pending from Dealer'
  },
  ORDER_DEALER_STATUS_C: {
    id: 'Order_DEALER_STATUS_C',
    defaultMessage: 'Approved by Dealer'
  },
  ORDER_DEALER_STATUS_R: {
    id: 'Order_DEALER_STATUS_R',
    defaultMessage: 'Rejected by Dealer'
  },
  ORDER_DEALER_STATUS_L: {
    id: 'Order_DEALER_STATUS_L',
    defaultMessage: 'Fulfilled with Local Inventory by Dealer'
  },
  ORDER_DEALER_STATUS_PC: {
    id: 'Order_DEALER_STATUS_PC',
    defaultMessage: 'Partially Fulfilled by Dealer'
  },
  ORDER_DEALER_STATUS_AP: {
    id: 'ORDER_DEALER_STATUS_AP',
    defaultMessage: 'Pending from Approver'
  },
  ORDER_DEALER_STATUS_RE: {
    id: 'ORDER_DEALER_STATUS_RE',
    defaultMessage: 'Pending Review'
  },
  APO_NO_STOCK: {
    id: 'APO_NO_STOCK',
    defaultMessage: 'No Local Stock'
  },
  CHECK_AVAILABILITY: {
    id: 'CHECK_AVAILABILITY',
    defaultMessage: 'check availability'
  },
  VIEW_PRICE_AND_AVAILABILITY: {
    id: 'VIEW_PRICE_AND_AVAILABILITY',
    defaultMessage: 'View Price and Availability'
  },
  'VIEW_PRICE_&_AVAILABILITY': {
    id: 'VIEW_PRICE_&_AVAILABILITY',
    defaultMessage: 'View Price & Availability'
  },
  OLIGOS_QPCR_PROBES_PEPTIDES: {
    id: 'OLIGOS_QPCR_PROBES_PEPTIDES',
    defaultMessage: 'Oligos, qPCR Probes & Peptides'
  },
  CUSTOM_PREDESIGNED_DNA_OLIGOS_PROBES: {
    id: 'CUSTOM_PREDESIGNED_DNA_OLIGOS_PROBES',
    defaultMessage: 'Custom & Predesigned DNA Oligos & qPCR Probes'
  },
  SAFETY_REGULATORY: {
    id: 'SAFETY_REGULATORY',
    defaultMessage: 'Safety & Regulatory'
  },
  REGULATORY_INFORMATION: {
    id: 'REGULATORY_INFORMATION',
    defaultMessage: 'Regulatory Information'
  },
  ITEM_TOTAL: {
    id: 'ITEM_TOTAL',
    defaultMessage: 'Item Total'
  },
  MARKETPLACE_ALIAS: {
    id: 'MARKETPLACE_ALIAS',
    defaultMessage: 'Marketplace'
  },
  SOFTWARE_SAVED_CART_DETAILS: {
    id: 'SOFTWARE_SAVED_CART_DETAILS',
    defaultMessage: 'Software items must be purchased separately. This Saved Cart was created to enable your software purchase without affecting the items in your main shopping cart. Click Checkout to complete your transaction.'
  },
  PREVIOUSLY_PURCHASED: {
    id: 'PREVIOUSLY_PURCHASED',
    defaultMessage: 'Previously Purchased'
  },
  LAST_PURCHASED: {
    id: 'LAST_PURCHASED',
    defaultMessage: 'Last Purchased'
  },
  VIEW_ORDER: {
    id: 'VIEW_ORDER',
    defaultMessage: 'View Order'
  },
  FREQUENTLY_PURCHASED: {
    id: 'FREQUENTLY_PURCHASED_PRODUCTS',
    defaultMessage: 'Frequently Purchased Products'
  },
  VECTOR_DOCUMENT_NOT_FOUND: {
    id: 'VECTOR_DOCUMENT_NOT_FOUND',
    defaultMessage: 'Sorry, unable to find the document. Please contact us to request a paper copy.'
  },
  ORDER_ACKNOWLEDGEMENT_MARKETPLACE_BANNER_CHECKOUT: {
    id: 'ORDER_ACKNOWLEDGEMENT_MARKETPLACE_BANNER_CHECKOUT',
    defaultMessage: 'Checkout'
  },
  ADDED_TO_COMPANY_CART: {
    id: 'ADDED_TO_COMPANY_CART',
    defaultMessage: 'Added to {companyName} Cart'
  },
  VIEW_COMPANY_CART: {
    id: 'VIEW_COMPANY_CART',
    defaultMessage: 'View {companyName} Cart'
  },
  MARKETPLACE_CART_MESSAGE: {
    id: 'MARKETPLACE_CART_MESSAGE',
    defaultMessage: 'These products will be fulfilled by trusted third party sellers.'
  },
  TRANSPORTATION_SURCHARGE_TITLE: {
    id: 'TRANSPORTATION_SURCHARGE_TITLE',
    defaultMessage: 'Transportation / Handling'
  },
  TRANSPORTATION_SURCHARGE_MESSAGE_US: {
    id: 'TRANSPORTATION_SURCHARGE_MESSAGE_US',
    defaultMessage: 'Recent market conditions and price increases from our carriers have significantly impacted the costs of freight delivery to our customers; accordingly, a freight surcharge of $15 per order may have been applied and is included in the transportation / handling fee amount shown.'
  },
  TRANSPORTATION_SURCHARGE_MESSAGE: {
    id: 'TRANSPORTATION_SURCHARGE_MESSAGE',
    defaultMessage: 'Recent market conditions and price increases from our carriers have significantly impacted the costs of freight delivery to our customers; accordingly, a freight surcharge per order may have been applied and is included in the transportation / handling fee amount shown.'
  },
  REMEMBER_ME: {
    id: 'REMEMBER_ME',
    defaultMessage: 'Remember Me'
  },
  REMEMBER_ME_INFO: {
    id: 'REMEMBER_ME_INFO',
    defaultMessage: "Selecting this option will remember your username / email address for fourteen days. Do not select this option if you're using a public computer in a hotel, coffee shop or other shared location."
  },
  ORDER_TYPE_EMD_MILLIPORE: {
    id: 'ORDER_TYPE_EMD_MILLIPORE',
    defaultMessage: 'EMD Millipore'
  },
  ORDER_TYPE_MERCK_CHEMICALS: {
    id: 'ORDER_TYPE_MERCK_CHEMICALS',
    defaultMessage: 'Merck Chemicals'
  },
  EMD_MILLIPORE_CART: {
    id: 'EMD_MILLIPORE_CART',
    defaultMessage: 'EMD Millipore Cart'
  },
  MERCK_CHEMICALS_CART: {
    id: 'MERCK_CHEMICALS',
    defaultMessage: 'Merck Chemicals Cart'
  },
  ITEMS_IN_YOUR_EMD_MILLIPORE_CART: {
    id: 'ITEMS_IN_YOUR_EMD_MILLIPORE_CART',
    defaultMessage: 'Items in your EMD Millipore cart'
  },
  CANCEL_EMD_MILLIPORE_CART_MODAL_DESCRIPTION: {
    id: 'CANCEL_EMD_MILLIPORE_CART_MODAL_DESCRIPTION',
    defaultMessage: 'Are you sure you want to clear this EMD Millipore Cart and all of its items?'
  },
  CANCEL_MERCK_CHEMICALS_CART_MODAL_DESCRIPTION: {
    id: 'CANCEL_MERCK_CHEMICALS_CART_MODAL_DESCRIPTION',
    defaultMessage: 'Are you sure you want to clear this Merck Chemicals Cart and all of its items?'
  },
  KEEP_ME_SIGNED_IN: {
    id: 'KEEP_ME_SIGNED_IN',
    defaultMessage: 'Keep me signed in.'
  },
  KEEP_ME_SIGNED_IN_CHECKBOX: {
    id: 'KEEP_ME_SIGNED_IN_CHECKBOX',
    defaultMessage: 'Keep Me Signed In Checkbox'
  },
  KEEP_ME_SIGNED_IN_LINE_1: {
    id: 'KEEP_ME_SIGNED_IN_LINE_1',
    defaultMessage: "Choosing \"Keep me signed in\" reduces the number of times you're asked to sign in on this device.  For your account's security, we'll still ask for your password if you're accessing or updating sensitive information."
  },
  KEEP_ME_SIGNED_IN_LINE_2: {
    id: 'KEEP_ME_SIGNED_IN_LINE_2',
    defaultMessage: 'To keep your account secure, use this option only on your personal computer or device.'
  },
  MATERIAL_NOT_AVAILABE_IN_YOUR_SALES_AREA: {
    id: 'MATERIAL_NOT_AVAILABE_IN_YOUR_SALES_AREA',
    defaultMessage: 'Material is not available in your sales area.'
  },
  ORC_ALIAS: {
    id: 'ORC_ALIAS',
    defaultMessage: 'EMD Millipore'
  },
  EMERALD_ALIAS: {
    id: 'EMERALD_ALIAS',
    defaultMessage: 'Merck Chemicals'
  },
  REGISTER_DEALER_CONDITION: {
    id: 'REGISTER_DEALER_CONDITION',
    defaultMessage: 'Are you a dealer or direct sales customer with Merck?'
  },
  REGISTRATION_DEALER_OR_DIRECT_SALES_CUSTOMER: {
    id: 'REGISTRATION_DEALER_OR_DIRECT_SALES_CUSTOMER',
    defaultMessage: 'Yes, I am a dealer/direct sales customer'
  },
  REGISTRATION_NOT_DEALER_OR_DIRECT_SALES_CUSTOMER: {
    id: 'REGISTRATION_NOT_DEALER_OR_DIRECT_SALES_CUSTOMER',
    defaultMessage: 'No, I am not and would like to order from dealers'
  },
  PROCUT_ORDER_ON_AVANTI_SITE: {
    id: 'PROCUT_ORDER_ON_AVANTI_SITE',
    defaultMessage: 'This product is not available through {companyName} in {countryName}. Please go to {link} to order directly.'
  },
  PROCUT_ORDER_ON_ROCHE_SITE: {
    id: 'PROCUT_ORDER_ON_ROCHE_SITE',
    defaultMessage: 'This product is not available through {companyName} in {countryName}. Please go to {link} to purchase directly.'
  },
  A_MERCK_ACCOUNT_ALLOWS_YOU_TO_FIND: {
    id: 'A_MERCK_ACCOUNT_ALLOWS_YOU_TO_FIND',
    defaultMessage: 'A Merck account allows you to find products and prices that are specific to the organization you work in along with being able to access online tools, check out faster, add multiple shipping locations, view orders, and create shopping lists.'
  },
  ONE_IMPORTANT_NOTE: {
    id: 'ONE_IMPORTANT_NOTE',
    defaultMessage: 'One important note, we can only review your account information after you place an order. '
  },
  IF_YOU_HAVE_ANY_QUESTIONS: {
    id: 'IF_YOU_HAVE_ANY_QUESTIONS',
    defaultMessage: 'If you have any questions or need assistance placing an order please contact us at either (+86) 800 819 3336 - 1 or (+86) 400 620 3333 - 1.'
  },
  RECENT_PURCHASES: {
    id: 'RECENT_PURCHASES',
    defaultMessage: 'Recent Purchases'
  },
  DEALER_LABEL_AVAILABLE_PRODUCTS: {
    id: 'DEALER_LABEL_AVAILABLE_PRODUCTS',
    defaultMessage: 'Available Products'
  },
  YOU: {
    id: 'YOU',
    defaultMessage: 'You'
  },
  MEMBERS: {
    id: 'MEMBERS',
    defaultMessage: 'Members'
  },
  SELECT_LIST: {
    id: 'SELECT_LIST',
    defaultMessage: 'Select List'
  },
  NO_LISTS_YET_HEADER: {
    id: 'NO_LISTS_YET_HEADER',
    defaultMessage: "You Don't Have Any Lists Yet"
  },
  NO_LISTS_YET_DESCRIPTION: {
    id: 'NO_LISTS_YET_DESCRIPTION',
    defaultMessage: 'Lists can help you keep track of products when planning purchases and can easily be shared with multiple collaborators for group orders.'
  },
  PRICE_ESTIMATE_DISCLAIMER: {
    id: 'PRICE_ESTIMATE_DISCLAIMER',
    defaultMessage: 'Prices are estimated; actual prices will be calculated at checkout'
  },
  DISCLAIMER: {
    id: 'DISCLAIMER',
    defaultMessage: 'Disclaimer'
  },
  SHARED_LIST_PDF_DISCLAIMER: {
    id: 'SHARED_LIST_PDF_DISCLAIMER',
    defaultMessage: 'Information related to price, availability and delivery dates is subject to change'
  },
  SHARED_LIST_PDF_DISCLAIMER_UPDATED: {
    id: 'SHARED_LIST_PDF_DISCLAIMER_UPDATED',
    defaultMessage: 'This is not a quote. Information related to price, availability, and delivery dates is subject to change.'
  },
  SHARED_LIST_PDF_DISCLAIMER_2: {
    id: 'SHARED_LIST_PDF_DISCLAIMER_2',
    defaultMessage: "Subtotal doesn't include items where pricing is unavailable"
  },
  SHARED_LIST_PDF_DISCLAIMER_2_UPDATED: {
    id: 'SHARED_LIST_PDF_DISCLAIMER_2_UPDATED',
    defaultMessage: 'Subtotal & total do not include items where pricing is unavailable.'
  },
  EMAIL_INVITE: {
    id: 'EMAIL_INVITE',
    defaultMessage: 'Email Invite'
  },
  SEND_INVITE: {
    id: 'SEND_INVITE',
    defaultMessage: 'Send Invite'
  },
  ENTER_EMAILS: {
    id: 'ENTER_EMAILS',
    defaultMessage: 'Enter emails'
  },
  ALREADY_A_MEMBER_ERROR: {
    id: 'ALREADY_A_MEMBER_ERROR',
    defaultMessage: '"{email}" is already a member of this list'
  },
  REMOVE_MEMBER: {
    id: 'REMOVE_MEMBER',
    defaultMessage: 'Remove member'
  },
  INVITE_SENT: {
    id: 'INVITE_SENT',
    defaultMessage: 'Invite Sent'
  },
  SHARED_LIST_ITEM_ADDED: {
    id: 'SHARED_LIST_ITEM_ADDED',
    defaultMessage: 'Item Added to Shared List'
  },
  SHARED_LIST_ITEM_REMOVED: {
    id: 'SHARED_LIST_ITEM_REMOVED',
    defaultMessage: 'Item Removed from Shared List'
  },
  CLEAR_LIST: {
    id: 'CLEAR_LIST',
    defaultMessage: 'Clear List'
  },
  CONFIRM_CLEAR_LIST: {
    id: 'CONFIRM_CLEAR_LIST',
    defaultMessage: 'Are you sure you want to clear this list of all of its items?'
  },
  CHECKOUT_EMD_MILLIPORE_CART: {
    id: 'CHECKOUT_EMD_MILLIPORE_CART',
    defaultMessage: 'Checkout EMD Millipore Cart'
  },
  PRICE_REDUCED_FROM: {
    id: 'PRICE_REDUCED_FROM',
    defaultMessage: 'Price reduced from'
  },
  SHOW_PRICING: {
    id: 'SHOW_PRICING',
    defaultMessage: 'Show Pricing'
  },
  HIDE_PRICING: {
    id: 'HIDE_PRICING',
    defaultMessage: 'Hide Pricing'
  },
  CHROMATOGRAMS: {
    id: 'CHROMATOGRAMS',
    defaultMessage: 'Chromatograms'
  },
  ENHANCED_VALIDATION: {
    id: 'ENHANCED_VALIDATION',
    defaultMessage: 'Enhanced Validation antibodies are tested to ensure reproducibility, specificity, and performance using our enhanced validation strategies'
  },
  CONDITIONS: {
    id: 'CONDITIONS',
    defaultMessage: 'CONDITIONS'
  },
  RELATED_PRODUCT_CATEGORIES: {
    id: 'RELATED_PRODUCT_CATEGORIES',
    defaultMessage: 'Related Product Categories'
  },
  RELATED_APPLICATIONS: {
    id: 'RELATED_APPLICATIONS',
    defaultMessage: 'Related Applications'
  },
  GC_COLUMNS_AND_ACCESSORIES: {
    id: 'GC_COLUMNS_AND_ACCESSORIES',
    defaultMessage: 'GC Columns & Accessories'
  },
  HPLC_COLUMNS: {
    id: 'HPLC_COLUMNS',
    defaultMessage: 'HPLC Columns'
  },
  TLC_PLATES_AND_ADSORBENTS: {
    id: 'TLC_PLATES_AND_ABSORBENTS',
    defaultMessage: 'TLC Plates & Adsorbents'
  },
  SOLID_PHASE_MICROEXTRACTION_SPME: {
    id: 'SOLID_PHASE_MICROEXTRACTION_SPME',
    defaultMessage: 'Solid Phase Microextraction (SPME)'
  },
  SOLID_PHASE_EXTRACTION_AND_QUECHERS: {
    id: 'SOLID_PHASE_EXTRACTION_AND_QUECHERS',
    defaultMessage: 'Solid Phase Extraction & QuEChERS'
  },
  SMALL_MOLECULE_HPLC: {
    id: 'SMALL_MOLECULE_HPLC',
    defaultMessage: 'Small Molecule HPLC'
  },
  LARGE_MOLECULE_HPLC: {
    id: 'LARGE_MOLECULE_HPLC',
    defaultMessage: 'Large Molecule HPLC'
  },
  GAS_CHROMATOGRAPHY_GC: {
    id: 'GAS_CHROMATOGRAPHY_GC',
    defaultMessage: 'Gas Chromatography (GC)'
  },
  TLC: {
    id: 'TLC',
    defaultMessage: 'TLC'
  },
  SOLID_PHASE_EXTRACTION_SPE: {
    id: 'SOLID_PHASE_EXTRACTION_SPE',
    defaultMessage: 'Solid Phase Extraction (SPE)'
  },
  QUECHERS: {
    id: 'QUECHERS',
    defaultMessage: 'QuEChERS'
  },
  CART_ERR_MSG_INACTIVE_CUSTOMER: {
    id: 'CART_ERR_MSG_INACTIVE_CUSTOMER',
    defaultMessage: 'Your account is locked. Please contact {customerService}.'
  },
  CART_ERR_MSG_NEW_CUSTOMER: {
    id: 'CART_ERR_MSG_NEW_CUSTOMER',
    defaultMessage: 'Your account is currently being reviewed. Please try again later. For further questions, please contact {customerService}.'
  },
  EXPIRES: {
    id: 'EXPIRES',
    defaultMessage: 'Expires'
  },
  AVAIL_CS: {
    id: 'AVAIL_CS',
    defaultMessage: 'Please contact Customer Service {messageVariable1} for availability.'
  },
  ORDER_STATUS: {
    id: 'ORDER_STATUS',
    defaultMessage: 'Order Status'
  },
  ORDER_STATUS_PENDING: {
    id: 'ORDER_STATUS_PENDING',
    defaultMessage: 'Pending'
  },
  ORDER_STATUS_OPEN: {
    id: 'ORDER_STATUS_OPEN',
    defaultMessage: 'Open'
  },
  ORDER_STATUS_IN_PROGRESS: {
    id: 'ORDER_STATUS_IN_PROGRESS',
    defaultMessage: 'In Progress'
  },
  ORDER_STATUS_COMPLETE: {
    id: 'ORDER_STATUS_COMPLETE',
    defaultMessage: 'Completed'
  },
  ORDER_STATUS_REJECTED: {
    id: 'ORDER_STATUS_REJECTED',
    defaultMessage: 'Rejected'
  },
  ORDER_STATUS_INFO: {
    id: 'ORDER_STATUS_INFO',
    defaultMessage: 'For questions related to your order, please contact {customerService}'
  },
  DEF_ORDER_STATUS_PENDING: {
    id: 'DEF_ORDER_STATUS_PENDING',
    defaultMessage: 'Order has been received or waiting for Seller approval'
  },
  DEF_ORDER_STATUS_OPEN: {
    id: 'DEF_ORDER_STATUS_OPEN',
    defaultMessage: 'Order has transferred to our fulfillment system'
  },
  DEF_ORDER_STATUS_IN_PROGRESS: {
    id: 'DEF_ORDER_STATUS_IN_PROGRESS',
    defaultMessage: 'Order has transferred to fulfillment center and is being processed or partially fulfilled by the Seller'
  },
  DEF_ORDER_STATUS_COMPLETE: {
    id: 'DEF_ORDER_STATUS_COMPLETE',
    defaultMessage: 'Order is fully shipped and invoiced or cancelled'
  },
  DEF_ORDER_STATUS_REJECTED: {
    id: 'DEF_ORDER_STATUS_REJECTED',
    defaultMessage: 'Order will not be fulfilled'
  },
  SITE_TERMS_AND_COOKIES: {
    id: 'SITE_TERMS_AND_COOKIES',
    defaultMessage: 'I accept the {siteUseTermsLink} and I consent to the use of cookies in accordance with such policy.'
  },
  MERCKS_PRIVACY_POLICY_COOKIES: {
    id: 'MERCKS_PRIVACY_POLICY_COOKIES',
    defaultMessage: 'I acknowledge and agree that Merck Ltd., Sigma-Aldrich Japan G.K., Merck KGaA, Darmstadt, Germany and its affiliates can collect and use my personal information for the above-mentioned purposes and as specified in {mercksPrivacyPolicyLink}'
  },
  NEED_A_QUOTE: {
    id: 'NEED_A_QUOTE',
    defaultMessage: 'Need a Quote?'
  },
  TERMS_AND_CONDITIONS_MODAL_EXPLANATION: {
    id: 'TERMS_AND_CONDITIONS_MODAL_EXPLANATION',
    defaultMessage: 'Before you continue to our site, please review the terms and conditions below. \n Otherwise, you may continue as a guest.'
  },
  CONTINUE_AS_GUEST: {
    id: 'CONTINUE_AS_GUEST',
    defaultMessage: 'Continue as Guest'
  },
  RESTRICTED_ITEM: {
    id: 'RESTRICTED_ITEM',
    defaultMessage: 'Restricted Item'
  },
  RESTRICTED_ITEM_MESSAGE: {
    id: 'RESTRICTED_ITEM_MESSAGE',
    defaultMessage: 'This item cannot be ordered due to restrictions requested by your organization. If you need this item, please contact your purchasing office.'
  },
  EDIT_DETAILS: {
    id: 'EDIT_DETAILS',
    defaultMessage: 'Edit Details'
  },
  BUY_IT_NOW: {
    id: 'BUY_IT_NOW',
    defaultMessage: 'Buy It Now'
  },
  BUY_NOW: {
    id: 'BUY_NOW',
    defaultMessage: 'Buy Now'
  },
  LEAVE_BUY_NOW: {
    id: 'LEAVE_BUY_NOW',
    defaultMessage: 'Leave Buy Now'
  },
  IN_STOCK: {
    id: 'IN_STOCK',
    defaultMessage: 'In Stock'
  },
  DELAYED: {
    id: 'DELAYED',
    defaultMessage: 'Delayed Availability'
  },
  CONTACT_CUSTOMER_SVC: {
    id: 'CONTACT_CUSTOMER_SVC',
    defaultMessage: 'Contact Customer Service for Availability'
  },
  MINIMUM_ORDER_QUANTITY: {
    id: 'MINIMUM_ORDER_QUANTITY',
    defaultMessage: 'Minimum order quantity: '
  },
  COMPANYNAME_SIGMA_CHINA: {
    id: 'COMPANYNAME_SIGMA_CHINA',
    defaultMessage: 'Sigma-Aldrich (Shanghai) Trading Co., Ltd. '
  },
  COMPANYNAME_MERCK_CHEMICALS_CHINA: {
    id: 'COMPANYNAME_MERCK_CHEMICALS_CHINA',
    defaultMessage: 'Merck Chemicals (Shanghai) Co., Ltd.'
  },
  SIGN_IN_FOR_PRICING: {
    id: 'SIGN_IN_FOR_PRICING',
    defaultMessage: 'Sign In for Pricing'
  },
  UPON_ORDER_COMPLETION: {
    id: 'UPON_ORDER_COMPLETION',
    defaultMessage: 'Upon Order Completion'
  },
  CART_STATE_ZIPCODE_MISMATCH: {
    id: 'CART_STATE_ZIPCODE_MISMATCH',
    defaultMessage: 'The Postal Code entered on your selected Shipping Address does not belong to {state}. Please review your Shipping Address in your user profile to continue checking out. If you are unable to make changes to your address, please contact customer service at +1 (800) 325-3010 extension 6999-3900.'
  },
  MIRAKL_INVALID_CUSTOMER_SHIPPING_ADDRESS: {
    id: 'MIRAKL_INVALID_CUSTOMER_SHIPPING_ADDRESS',
    defaultMessage: 'There seems to be a problem with your selected Shipping Address. Please review and, if necessary, modify your shipping address within your user profile in order to continue checking out. If you are unable to make changes or feel that you have received this message in error, please contact Customer Service at +1 (800) 325-3010 ext. 6999-3900 or email us at {mailTo}.'
  },
  ADD_PO_NUMBER: {
    id: 'ADD_PO_NUMBER',
    defaultMessage: 'Add a PO Number (required)'
  },
  INCLUDES_TAXES_FEES_AND_SHIPPING: {
    id: 'INCLUDES_TAXES_FEES_AND_SHIPPING',
    defaultMessage: '(includes taxes, fees, and shipping)'
  },
  THIS_ITEM_ONLY: {
    id: 'THIS_ITEM_ONLY',
    defaultMessage: 'this item only'
  },
  TRANSFER_ITEM: {
    id: 'TRANSFER_ITEM',
    defaultMessage: 'Transfer Item'
  },
  TRANSFER_ITEM_MESSAGE: {
    id: 'TRANSFER_ITEM_MESSAGE',
    defaultMessage: 'This will transfer {thisItemOnly} to your procurement system for purchase. {br} If you would like to add more items or transfer the contents of your cart please select Add to Cart.'
  },
  PROMO_BUNDLE_TITLE: {
    id: 'PROMO_BUNDLE_TITLE',
    defaultMessage: 'Bundles Featuring {material}'
  },
  KIT_PRICE: {
    id: 'KIT_PRICE',
    defaultMessage: 'Bundle Price'
  },
  PROMO_BUNDLE_TABLE_TITLE: {
    id: 'PROMO_BUNDLE_TABLE_TITLE',
    defaultMessage: 'THIS {count}-ITEM BUNDLE INCLUDES:'
  },
  FULFILMENT_DELIVERY_DELAYED: {
    id: 'FULFILMENT_DELIVERY_DELAYED',
    defaultMessage: 'Fulfilment and delivery delayed'
  },
  CREDIT_CARD_CVV_REQUIRED: {
    id: 'CREDIT_CARD_CVV_REQUIRED',
    defaultMessage: 'Credit Card CVV Number (required)'
  },
  CREDIT_CARD_LABEL: {
    id: 'CREDIT_CARD_LABEL',
    defaultMessage: 'Credit Card'
  },
  MERCHANT_LOCATION: {
    id: 'MERCHANT_LOCATION',
    defaultMessage: 'Merchant Location'
  },
  PROMO_PRODUCT: {
    id: 'PROMO_PRODUCT',
    defaultMessage: 'Product'
  },
  PROMO_PRODUCT_NAME: {
    id: 'PROMO_PRODUCT_NAME',
    defaultMessage: 'Name'
  },
  PROMO_QUANTITY: {
    id: 'PROMO_QUANTITY',
    defaultMessage: 'Qty per Bundle'
  },
  PROMO_AVAILABILITY: {
    id: 'PROMO_AVAILABILITY',
    defaultMessage: 'Availability'
  },
  SHARE: {
    id: 'SHARE',
    defaultMessage: 'Share'
  },
  TOTAL_SUBTITLE: {
    id: 'TOTAL_SUBTITLE',
    defaultMessage: 'The final total including taxes and shipping will be calculated during checkout.'
  },
  VIEW_ORG_CONTRACT_PRICING: {
    id: 'VIEW_ORG_CONTRACT_PRICING',
    defaultMessage: 'to View Organizational & Contract Pricing'
  },
  LINK_YOUR_ACCOUNT: {
    id: 'LINK_YOUR_ACCOUNT',
    defaultMessage: 'Link Your Account'
  },
  FOR_ASSISTANCE: {
    id: 'FOR_ASSISTANCE',
    defaultMessage: 'for assistance.'
  },
  RECOMMENDED_REPLACEMENT_PRODUCTS: {
    id: 'RECOMMENDED_REPLACEMENT_PRODUCTS',
    defaultMessage: 'We recommend the replacement products listed below:'
  },
  VIEW_SIMILAR_PRODUCTS_BELOW: {
    id: 'VIEW_SIMILAR_PRODUCTS_BELOW',
    defaultMessage: 'View Similar Product(s) Below'
  },
  THIS_PRODUCT_DISCONTINUED: {
    id: 'THIS_PRODUCT_DISCONTINUED',
    defaultMessage: 'This product is discontinued. {recommendedProductMessage}'
  },
  COMPARE_WITH_DISCONTINUED_PRODUCT: {
    id: 'COMPARE_WITH_DISCONTINUED_PRODUCT',
    defaultMessage: 'Compare with discontinued product'
  },
  // do not delete, curly braces cause error when running message modifier
  QUANTITY_PER_KIT: {
    id: 'QUANTITY_PER_KIT',
    defaultMessage: '{quantity} per bundle'
  },
  // do not delete - curly braces cause error when running message modifier
  QUOTE_DISCLAIMER: {
    id: 'QUOTE_DISCLAIMER',
    defaultMessage: "Clicking \u201CSubmit Order\u201D will confirm the purchase of your quoted items. {br}\n      It will not affect any items in your Shopping Cart."
  },
  // do not delete - curly braces cause error when running message modifier
  EXCLUSIVE_SAVINGS_MESSAGE: {
    id: 'EXCLUSIVE_SAVINGS_MESSAGE',
    defaultMessage: '{placeholder} on certain products while supplies last.'
  },
  '5_ROLLS': {
    id: '5_ROLLS',
    defaultMessage: 'Maximum of 5 rolls/order'
  },
  // do not delete - dynamic message coming from endpoint
  PRODUCT_DISCONTINUED_BUT_AVAILABLE_FOR_PURCHASE: {
    id: 'PRODUCT_DISCONTINUED_BUT_AVAILABLE_FOR_PURCHASE',
    defaultMessage: 'One or more pack size(s) of this product have been discontinued but those shown are still available for purchase.'
  },
  // do not delete - dynamic message coming from endpoint
  PRODUCT_IS_NOT_SOLD_IN_YOUR_COUNTRY: {
    id: 'PRODUCT_IS_NOT_SOLD_IN_YOUR_COUNTRY',
    defaultMessage: 'Product {productNumber} is not currently sold in your country.'
  },
  // do not delete - dynamic message coming from endpoint
  PRODUCT_IS_CUSTOMER_SPECIFIC_AND_NOT_AVAILABLE_TO_BE_SOLD_ON_INTERNET: {
    id: 'PRODUCT_IS_CUSTOMER_SPECIFIC_AND_NOT_AVAILABLE_TO_BE_SOLD_ON_INTERNET',
    defaultMessage: 'Product {productNumber} is a customer specific product and is not available to be sold on the internet.'
  },
  // do not delete - dynamic message coming from endpoint
  PRODUCT_IS_ON_DEMAND_AVAILABILITY_MAY_VARY_BASED_ON_CAPACITY: {
    id: 'PRODUCT_IS_ON_DEMAND_AVAILABILITY_MAY_VARY_BASED_ON_CAPACITY',
    defaultMessage: 'Product {productNumber} is an on demand item. Availability times will vary based on capacity.'
  },
  // do not delete - dynamic message coming from endpoint
  PRODUCT_CANNOT_BE_ORDERED: {
    id: 'PRODUCT_CANNOT_BE_ORDERED',
    defaultMessage: 'Product {productNumber} cannot be ordered.'
  },
  // do not delete - dynamic message coming from endpoint
  TO_INQUIRE_ABOUT_THIS_PRODUCT_PLEASE_CONTACT_YOUR_LOCAL_MERCK_OFFICE_OR_DEALER: {
    id: 'TO_INQUIRE_ABOUT_THIS_PRODUCT_PLEASE_CONTACT_YOUR_LOCAL_MERCK_OFFICE_OR_DEALER',
    defaultMessage: ' To inquire about this product {productNumber}, please contact your local Merck office or dealer.'
  },
  // do not delete - dynamic message coming from endpoint
  TO_INQUIRE_ABOUT_THIS_PRODUCT_PLEASE_CONTACT_YOUR_LOCAL_MILLIPORE_SIGMA_OFFICE_OR_DEALER: {
    id: 'TO_INQUIRE_ABOUT_THIS_PRODUCT_PLEASE_CONTACT_YOUR_LOCAL_MILLIPORE_SIGMA_OFFICE_OR_DEALER',
    defaultMessage: 'To inquire about this product {productNumber}, please contact your local MilliporeSigma office or dealer.'
  },
  // do not delete - dynamic message coming from endpoint
  PRODUCT_REQUIRES_SPECIAL_PROCESSING: {
    id: 'PRODUCT_REQUIRES_SPECIAL_PROCESSING',
    defaultMessage: 'Product {productNumber} requires special processing and cannot be ordered through the web site. Please contact customer service to order this item.'
  },
  // do not delete - dynamic message coming from endpoint
  EMD_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE: {
    id: 'EMD_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE',
    defaultMessage: 'Pricing and Inventory is not available. Please check the {link} website for more information.'
  },
  // do not delete - dynamic message coming from endpoint
  MERCK_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE: {
    id: 'MERCK_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE',
    defaultMessage: 'Pricing and Inventory is not available. Please check the {link} website for more information.'
  },
  CONFIG_AVAILABILITY: {
    id: 'CONFIG_AVAILABILITY',
    defaultMessage: 'Availability of configured product will be confirmed as soon as the order has been entered into our system.'
  },
  REGISTRATION_INFO_KOREA_USERINFO_COLLECTION_CONSENT: {
    id: 'REGISTRATION_INFO_KOREA_USERINFO_COLLECTION_CONSENT',
    defaultMessage: '* I hereby give my consent to Merck Ltd. and Sigma-Aldrich Korea Ltd.’s collection and use of my personal information as shown in {consentLink}'
  },
  REGISTRATION_INFO_KOREA_COLLECTION_PAGE: {
    id: 'REGISTRATION_INFO_KOREA_COLLECTION_PAGE',
    defaultMessage: 'Collection and Use of My Personal Information.'
  },
  REGISTRATION_INFO_KOREA_ACCEPT_PROMOS: {
    id: 'REGISTRATION_INFO_KOREA_ACCEPT_PROMOS',
    defaultMessage: ' I hereby agree that Merck Ltd. and Sigma-Aldrich Korea Ltd. can contact me to provide marketing information about their products, services, and other offers.'
  },
  MUST_ACCEPT_KOREA_COLLECTION: {
    id: 'MUST_ACCEPT_KOREA_COLLECTION',
    defaultMessage: ' Must accept Collection and Use of My Personal Information'
  },
  REGISTRATION_INFO_PRIVACY_KOREA: {
    id: 'REGISTRATION_INFO_PRIVACY_KOREA',
    defaultMessage: 'You can withdraw your consent anytime by clicking the unsubscribe link contained in every email or by sending an email to {mailTo}. Detailed information is available in our {privacyStatement}.'
  },
  '3COLKIT_3SORBLOTS': {
    id: '3COLKIT_3SORBLOTS',
    defaultMessage: 'Validation Kit with 3 columns out of 3 different sorbent lots'
  },
  CANCEL_ORDER_ERROR_MESSAGE: {
    id: 'CANCEL_ORDER_ERROR_MESSAGE',
    defaultMessage: 'Due to technical reasons, we are unable to cancel this item at this time. Please contact customer support at {phoneNumber}'
  },
  INVITE_COWORKER_SUCCEED_MESSAGE: {
    id: 'INVITE_COWORKER_SUCCEED_MESSAGE',
    defaultMessage: 'Thank you for inviting your coworker! They will receive an email to confirm the invitation.'
  }
});
export default messages;